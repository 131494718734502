import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Base = styled.div`
  margin-bottom: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: ${props =>
    props.direction === 'right' ? 'row' : 'row-reverse'};
`;

const Info = styled.div`
  flex: 0.8;
`;
const Action = styled.div`
  flex: 0.2;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${props => props.color};
`;

const Meta = styled.div`
  color: rgba(0, 0, 0, 0.4);
`;

const RouteCard = ({
  onClick,
  driver,
  duration,
  distance,
  arrival,
  finish,
  direction = 'right',
  color
}) => {
  return (
    <Base onClick={onClick} direction={direction}>
      <Info>
        <Title color={color}>{driver.name}</Title>
        <Meta>
          <Icon name="time" /> {arrival} - {finish}
        </Meta>
        <Meta>
          <Icon name="map" /> {distance} miles / {duration}
        </Meta>
      </Info>
      <Action>
        <Icon name={`chevron ${direction}`} size="large" color="grey" />
      </Action>
    </Base>
  );
};

export default RouteCard;
