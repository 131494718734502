import React from 'react';
import { Popup } from 'semantic-ui-react';

const PopupComponent = ({ basic = true, on = 'click', ...props }) => (
  <Popup
    on={on}
    basic={basic}
    style={{
      margin: '1rem',
      boxShadow:
        '0px 1px 3px rgba(63, 63, 68, 0.25), 0px 0px 0px rgba(63, 63, 68, 0.05)',
      border: 'none',
      maxHeight: '400px',
      overflowY: 'auto'
    }}
    {...props}
  />
);

export default PopupComponent;
