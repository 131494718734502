import React from 'react';
import styled from 'styled-components';

import { colors } from 'utils';

const Base = styled.div`
  height: 90px;
  display: flex;
  padding: 0 1rem;
`;

const Colored = styled.div`
  font-size: 12px;
  color: ${props => colors[props.color]};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
`;

const Info = styled.span`
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
`;

const InfoStrong = styled.span`
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 3px;
`;

const Meta = styled.div`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
`;

const Timeline = styled.div`
  border-left: 1px rgba(0, 0, 0, 0.2) solid;
  margin-right: 2.5rem;

  &::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    margin-left: -0.5rem;
    background-color: ${props => colors[props.color]};
  }
`;

const Data = styled.div``;

const Waypoint = ({
  status = 'pending',
  jobId,
  type,
  postcode,
  arrival,
  finish,
  color
}) => {
  const finalColor =
    status === 'finished'
      ? color
      : status !== 'pending'
      ? 'yellow'
      : 'secondary';

  return (
    <Base>
      <Timeline color={finalColor} />
      <Data>
        <Colored color={finalColor}>{status}</Colored>
        <Info>
          {jobId} - {type}
        </Info>
        <InfoStrong>/ {postcode}</InfoStrong>
        <Meta>
          {arrival} - {finish}
        </Meta>
      </Data>
    </Base>
  );
};

export default Waypoint;
