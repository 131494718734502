import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import logger from 'utils/logger';

const log = logger({ module: 'api' });
const getView = () => {
  try {
    return window.location.pathname.match(/\/app\/(\w+)\/?/)[1];
  } catch (error) {
    return '';
  }
};

const url = process.env.REACT_APP_API_URL;
const socket = io(url, { transports: ['websocket'] });

const client = feathers()
  .configure(feathers.socketio(socket, { timeout: 30000 }))
  .configure(
    feathers.authentication({
      storage: window.localStorage
    })
  )
  .hooks({
    after(ctx) {
      log.info(
        `[${getView()}] ${ctx.method} /${ctx.path}${ctx.id ? '/' + ctx.id : ''}`
      );
      return ctx;
    },
    error(ctx) {
      log.error({
        req: `[${getView()}] ${ctx.method} /${ctx.path}${
          ctx.id ? '/' + ctx.id : ''
        }`,
        error: ctx.error
      });
      return ctx;
    }
  });

export default client;
