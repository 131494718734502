import React, { useState } from 'react';
import styled from 'styled-components';

import { client } from 'api';

import { Menu, Icon, Divider, Input } from 'semantic-ui-react';
import { Popup } from 'components/v2';
import { MENU_HEIGHT } from 'utils/constants';

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  border: 0;
  height: ${MENU_HEIGHT};
  width: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
`;

const Troubleshoot = () => {
  const [jobId, setJobId] = useState('');
  const handleReassign = () => {
    setJobId('');
    client.service('/operators/osl').create({ jobId });
  };

  return (
    <Popup
      trigger={
        <Item>
          <Icon name="bug" />
        </Item>
      }
      content={
        <Menu vertical secondary>
          <Menu.Item header>Troubleshoot</Menu.Item>
          <Divider />
          <Menu.Item>
            <Input
              value={jobId}
              onChange={(_, { value }) => setJobId(value)}
              icon={<Icon name="chevron right" link onClick={handleReassign} />}
              placeholder="Reassign Job Id"
            />
          </Menu.Item>
        </Menu>
      }
    />
  );
};

export default Troubleshoot;
