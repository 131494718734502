import React from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

const Container = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const LabelContainer = styled.div`
  padding: 0.25rem;
`;

const AttributeLabels = ({ data = {} }) => {
  const { intents = [], entities = [], newJd = {}, flags = [] } = data;
  const confColor = c =>
    c >= 0.9
      ? 'green'
      : c >= 0.8
      ? 'olive'
      : c >= 0.7
      ? 'yellow'
      : c >= 0.6
      ? 'orange'
      : 'red';

  const jdKeys = Object.keys(newJd);

  return (
    <Container>
      {flags.map((x, i) => (
        <LabelContainer key={`i${i}`}>
          <Label
            icon="warning"
            content={x.label}
            detail={x.value || x.type}
            color="red"
          />
        </LabelContainer>
      ))}

      {jdKeys.map((x, i) => (
        <LabelContainer key={`jd${i}`}>
          <Label
            icon="suitcase"
            content={x}
            detail={String(newJd[x])}
            color="blue"
          />
        </LabelContainer>
      ))}

      {intents.map((x, i) => (
        <LabelContainer key={`i${i}`}>
          <Label
            icon="hashtag"
            content={x.intent}
            detail={x.src}
            color={confColor(x.confidence)}
          />
        </LabelContainer>
      ))}

      {entities.map((x, i) => (
        <LabelContainer key={`i${i}`}>
          <Label
            icon="at"
            content={x.entity}
            detail={x.src}
            color={confColor(x.confidence)}
          />
        </LabelContainer>
      ))}
    </Container>
  );
};

export default AttributeLabels;
