import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

const content = {
  create:
    'Prepared Routes will be available on Routes > Tomorrow view in a few moments for Previewing and Dispatching',
  dispatch: 'Prepared Routes will be sent to Drivers on their Apps',
  discard:
    'Prepared Routes will be discarded from the System and can be created again'
};

const capitalize = s => s.charAt(0).toUpperCase() + s.substring(1);

const ActionModal = ({ onCancel, onContinue, action = 'create' }) => (
  <Modal open basic size="small">
    <Header icon="map" content={`${capitalize(action)} Routes`} />
    <Modal.Content>
      <p>{content[action]}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button inverted color="green" onClick={onContinue}>
        Continue
      </Button>
      <Button inverted color="red" onClick={onCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ActionModal;
