import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, Divider, Input, Loader } from 'semantic-ui-react';

import { AppContext } from 'store/context';
import { Popup, FilterBuilder, filterApply } from 'components/v2';
import { wds, JobRow } from './index';
import * as H from './helpers';

const Base = styled.div`
  width: 40%;
  height: 100%;
  /* overflow-x: none; */
  /* overflow-y: auto; */
`;

const JobsList = styled.div`
  height: calc(100% - 66px);
  overflow-y: scroll;
  padding: 1rem;
`;

const ControlsBase = styled.div`
  margin: 1rem 0.5rem;
  display: flex;
  height: 36px;
`;

const StyledButton = styled(Button)`
  &.ui.basic.button {
    background-color: #fff !important;
  }
  margin-left: 0.5rem !important;
`;

const StyledInput = styled(Input)`
  margin: 0 0.5rem !important;
  width: 100%;
`;

const StyledLoader = styled(Loader)`
  margin-top: 3rem !important;
`;

const HeaderRow = styled.div`
  display: flex;
  padding: 0rem 1.5rem;
  justify-content: space-evenly;
`;

const Flex = styled.div`
  flex: ${props => props.flex};
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
`;

const JobList = ({ jobs = [], activeJobId, loading }) => {
  const history = useHistory();
  const { vendors } = useContext(AppContext);
  const { filterFields, filterPresets } = H.makeFilters({ vendors });

  const [filters, setFilters] = useState(filterPresets[0].filters);

  const onRowClick = jobId => {
    history.push(`/app/jobs/#${jobId}`);
  };

  const filterSortedJobs = filterApply({ data: jobs, filters }).sort(
    H.sortFfDate
  );

  return (
    <Base>
      <ControlsBase>
        <Popup
          trigger={<Button basic icon="filter" />}
          content={
            <FilterBuilder
              fields={filterFields}
              presets={filterPresets}
              filters={filters}
              setFilters={setFilters}
            />
          }
        />
        <StyledButton disabled basic icon="sort amount down" />

        <StyledInput
          disabled
          fluid
          placeholder="Search"
          icon="search"
          iconPosition="left"
        />
      </ControlsBase>

      <Divider fitted />

      <JobsList>
        <HeaderRow>
          <Flex flex={wds[0]}>
            <Title>Job ID</Title>
          </Flex>
          <Flex flex={wds[1]}>
            <Title>Status</Title>
          </Flex>
          <Flex flex={wds[2]}>
            <Title>Fulfilment</Title>
          </Flex>
          <Flex flex={wds[3]}>
            <Title>Pickup</Title>
          </Flex>
          <Flex flex={wds[4]}>
            <Title>Delivery</Title>
          </Flex>
        </HeaderRow>

        {loading ? (
          <StyledLoader active inline="centered" content="Fetching Jobs..." />
        ) : (
          filterSortedJobs.map(j => (
            <JobRow
              key={j.jobId}
              selected={activeJobId === j.jobId}
              onClick={() => onRowClick(j.jobId)}
              {...j}
            />
          ))
        )}
      </JobsList>
    </Base>
  );
};

export default JobList;
