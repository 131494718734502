import { utc as moment } from 'moment';

import { Input, Dropdown, TextArea } from 'semantic-ui-react';

export const formatters = {
  date: str => moment(str, 'YYYY-MM-DD').format('ll'),
  sensitive: (str = '') => `******${str.slice(-3)}`
};

export const compMap = {
  text: {
    control: Input
  },
  longtext: {
    control: TextArea
  },
  number: {
    control: Input,
    defaultProps: {
      type: 'number'
    }
  },
  select: {
    control: Dropdown,
    defaultProps: {
      placeholder: 'Select...',
      selection: true,
      fluid: true,
      search: true,
      lazyLoad: true
    },
    normalizer: props => {
      const rawOptions = [
        { value: null, text: 'Empty' },
        ...(props.options || [])
      ];
      return {
        ...props,
        options: rawOptions.map(({ value, text }) => ({
          value,
          text: text ? text : value
        }))
      };
    }
  },
  date: {
    control: Input,
    defaultProps: {
      type: 'date'
    }
  }
};
