import { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { updatedDiff, addedDiff } from 'deep-object-diff';
import { utc as moment } from 'moment';
export { default as logger } from './logger';

export const poller = ({
  promise,
  interval = 5000,
  onSuccess = () => {},
  onError = () => {}
}) => {
  const poll = async () => {
    try {
      const res = await promise();
      onSuccess(res);
      setTimeout(poll, interval);
    } catch (error) {
      onError(error);
    }
  };

  return poll;
};

export const usePolling = pollOpts => {
  useEffect(() => {
    const poll = poller(pollOpts);
    poll();
    return () => clearTimeout(poll);
  }, [pollOpts]);
};

export const normalizeAT = R.pipe(
  R.path(['value', 'records']),
  R.map(R.prop('fields'))
);

export const colors = {
  red: '#DB2828',
  orange: '#F2711C',
  yellow: '#FBBD08',
  olive: '#B5CC18',
  green: '#21BA45',
  teal: '#00B5AD',
  blue: '#2185D0',
  violet: '#6435C9',
  purple: '#A333C8',
  pink: '#E03997',
  brown: '#A5673F',
  grey: '#767676',
  black: '#1B1C1D',
  lightGrey: '#F3F4F5',
  secondary: '#767676'
};

export const colorMap = [
  'red',
  'green',
  'brown',
  'orange',
  'blue',
  'purple',
  'violet',
  'pink',
  'teal',
  'olive',
  'grey',
  'black',
  'yellow'
];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const formatTime = timestamp => {
  const date = new Date(timestamp);

  const dt = date.getDate();
  const mth = months[date.getMonth()];

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  const strTime = `${dt} ${mth} ${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const diffObj = (a, b) => ({ ...updatedDiff(a, b), ...addedDiff(a, b) });

export const DBReducer = (db, action) => {
  const { type, _id, altId = {}, data, patchStyle = 'overwrite' } = action;
  switch (type) {
    case 'created':
      if (Array.isArray(data)) {
        db.push(...data);
      } else {
        db.push(data);
      }

      return db;
    case 'patched':
      const pIdx = altId.key
        ? db.findIndex(x => x[altId.key] === altId.value)
        : db.findIndex(x => x._id === _id);

      if (pIdx >= 0) {
        db[pIdx] = patchStyle === 'merge' ? { ...db[pIdx], ...data } : data;
      }

      return db;
    case 'removed':
      const rIdx = db.findIndex(x => x._id === _id);

      if (rIdx >= 0) {
        db.splice(rIdx);
      }

      return db;
    case 'overwrite':
      return data;
    default:
      return;
  }
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const jobLabels = (job = {}) => {
  const bStatus = job.bookingStatus;
  const ffDate = job.fulfilmentDate;
  const d2ff = job.daysToFulfilment;

  const status =
    bStatus === 'confirmed'
      ? {
          content: 'Confirmed',
          color: 'green'
        }
      : bStatus === 'tentative'
      ? {
          content: 'Not Confi..',
          color: 'blue'
        }
      : bStatus === 'quote_seeker'
      ? {
          content: 'Send Quote',
          color: 'teal'
        }
      : bStatus === 'conditional'
      ? {
          content: 'Conditional',
          color: 'olive'
        }
      : bStatus === 'dropped'
      ? {
          content: 'No Contact',
          color: 'grey'
        }
      : {
          content: bStatus || 'Unavailable'
        };

  const fulfilment =
    d2ff === 0
      ? {
          content: 'Today',
          color: 'red'
        }
      : d2ff === 1
      ? {
          content: 'Tomorrow',
          color: 'yellow'
        }
      : d2ff > 1
      ? {
          content: moment(ffDate).format('DD MMM')
        }
      : d2ff === -1
      ? {
          content: 'Yesterday'
        }
      : d2ff < 0
      ? {
          content: `${d2ff * -1} Days Ago`
        }
      : {
          content: 'Not Available'
        };

  return {
    status,
    fulfilment
  };
};
