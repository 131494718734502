import outdent from 'outdent';

const kmToMi = x => Math.round(x * 0.621371);

export const fmtTime = x =>
  x ? `${Math.floor(x / 60)} hour ${Math.round(x % 60)} min` : '';
export const fmtDist = x => (x ? `${kmToMi(x)} mi` : '');
export const fmtPrice = x => (x ? `£${x}` : '');

const fmtTimeSpecial = x => {
  if (x <= 120) {
    return `1 - 1.5`;
  } else {
    return `${Math.floor(x / 2 / 60)} - ${Math.ceil(x / 2 / 60)}`;
  }
};

const hubLink = job => outdent`
  In case you would like to contact us, you can chat on this link ${job.hubLink} or email us on contact@thevanandman.co.uk
`;

const duration = job => outdent`
  We estimate the total job to take about ${fmtTimeSpecial(
    job.jobTimeMin
  )} hours.
`;

const mileage = job => outdent`
  In addition, there will be a mileage charge of £${kmToMi(
    job.distance
  )} (£1/mile) please.
`;

const price = men => job => outdent`
  The quote for *${
    men === 1 ? 'one man' : 'two men'
  }* is:<br>*Flexible Time Price* If time of pickup is flexible, the price would be *£${
  men === 1 ? job.flexiPriceOne : job.flexiPriceTwo
}${
  job.hourly
    ? `/hr (${job.flexiMinHours}hr min${
        job.distance > 16.1 ? ' + £1/mile' : ''
      })`
    : ''
}*

  We will come between 8AM - 6PM and will call you at least 2 hours before reaching the pickup location.<br>*Specific Time Price* If you would like the pickup at a specific time, the price would be *£${
    men === 1 ? job.specificPriceOne : job.specificPriceTwo
  }${
  job.hourly
    ? `/hr (${job.specificMinHours}hr min${
        job.distance > 16.1 ? ' + £1/mile' : ''
      })`
    : ''
}*

  We will come within one hour of your requested time. Eg, if you request to come at 9 AM, we will come between 9-10 AM<br>Which option would you like to book - *flexible time* OR *specific time*?

  _[No booking or cancellation fees. Price inclusive of VAT]_
`;

const bankDetails = job => outdent`
  Our bank details are:

  Great Journey Ltd
  Barclays Bank
  Account: 13458431
  Sort code: 203152

  Kindly mention the Job ID: ${job.jobId} in the bank transfer reference.
`;

export const messages = {
  hubLink,
  duration,
  mileage,
  priceOne: price(1),
  priceTwo: price(2),
  bankDetails
};
