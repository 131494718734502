import { css } from 'glamor';

export default css({
  '& .field .readonly': {
    padding: '0.5rem'
  },
  '& .field .slider.checkbox': {
    padding: '0.5rem'
  },
  '& .field .message': {
    margin: 0,
    padding: '0.65rem 1rem',
    display: 'block !important'
  },
  '& .section.segment': {
    '& .accordion .title': {
      padding: 0
    },
    '&.non-collapsible .section-label': {
      marginBottom: '1rem'
    }
  }
});
