import Rollbar from 'rollbar';

import getStreamName from './getStreamName';

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: [
    // network flakiness
    'socket hang up',
    'Client network socket disconnected before secure TLS connection was established',
    'Request failed with status code 500',
    'read ECONNRESET',
    'Timeout of 30000ms exceeded',
    'transport close',
    'disconnected ping timeout',
    'needs refresh',
    // other misc
    'No auth token',
    "Cannot read property '_id' of null",
    'transition is invalid while previous transition is still in progress'
  ],
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: process.env.REACT_APP_VERSION
      }
    },
    person: {
      id: getStreamName()
    }
  }
});

export default rollbar;
