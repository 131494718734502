import mitt from 'mitt';
import * as R from 'ramda';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useImmerReducer } from 'use-immer';
import logger from 'utils/logger';

import { client } from 'api';
import imgSrc from 'assets/images/electric_van.png';
import { Preloader } from 'components/v1';
import { DefaultBase, EmptyState, Navbar, Text } from 'components/v2';
import { AppContext } from 'store/context';
import { DBReducer, usePrevious } from 'utils';
import { MENU_HEIGHT } from 'utils/constants';
import { JobPane, TaskPane } from './components';

const events = mitt();
const log = logger({ module: 'tasks' });

const getChannelNames = R.pipe(
  R.pick(['email', 'sms', 'webchat', 'whatsapp']),
  R.values,
  R.map(x => `/chat/${x}`)
);

const Body = styled.div`
  height: calc(100% - ${MENU_HEIGHT});
  display: flex;
`;

const EmptyBase = styled.div`
  width: 60%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
`;

const Tasks = () => {
  const { handleEvents } = useContext(AppContext);

  const [tasks, dispatchTasks] = useImmerReducer(DBReducer, []);
  const [messages, dispatchMessages] = useImmerReducer(DBReducer, []);
  const [jobs, dispatchJobs] = useImmerReducer(DBReducer, []);

  const [tasksLoading, setTasksLoading] = useState(true);
  const [initializedJobs, setInitializedJobs] = useState({});
  const [updating, _setUpdating] = useState(false);

  const tasksService = client.service('/tasks');
  const jobsService = client.service('/jobs');
  const messagesService = client.service('/db/messages');

  const history = useHistory();
  const location = useLocation();
  const activeJobId = location.hash
    ? Number(location.hash.replace('#', ''))
    : null;

  const activeJob = jobs.find(x => x.jobId === activeJobId);

  const previousJobId = usePrevious(activeJobId);

  const setInitializedJobData = jobId => data => {
    handleEvents({
      action: 'join',
      channels: getChannelNames(data.channels)
    });

    setInitializedJobs({
      ...initializedJobs,
      [jobId]: data
    });

    log.info(`✅ fms chat updates [${jobId}]`);
  };

  const initialQuery = async () => {
    setTasksLoading(true);
    const { tasks = [], jobs = [] } = await tasksService.find({
      query: {
        status: {
          $nin: ['done', 'ignored']
        },
        'entities.job': {
          $exists: true
        },
        $sort: {
          createdAt: -1
        }
      }
    });

    dispatchTasks({ type: 'created', data: tasks });
    dispatchJobs({ type: 'created', data: jobs });

    activeJobId &&
      jobsService
        .get(activeJobId)
        .then(job =>
          dispatchJobs({ type: 'created', _id: job._id, data: job })
        );

    setTasksLoading(false);
  };

  const onJobUpdate = jobId => async data => {
    // setUpdating(true);
    dispatchJobs({
      type: 'patched',
      altId: { key: 'jobId', value: jobId },
      data,
      patchStyle: 'merge'
    });
    await jobsService.patch(jobId, data);
    // setUpdating(false);
    return;
  };

  const onMessageSend =
    sessionId =>
    ({ body, channel, ...attrs }) => {
      client.service('/customers/chat/messages').create({
        body,
        from: 'operator',
        sessionId,
        ...attrs
      });
    };

  const onTaskUpdate =
    jobId =>
    ({ _id, ...patch }, data) => {
      tasksService.patch(_id, patch);

      if (patch.status) {
        if (patch.status === 'done') {
          if (data.channels) {
            data.channels.forEach(channel => {
              onMessageSend(jobId)({ channel, body: data.value });
            });
          }

          if (data.field) {
            onJobUpdate(jobId)({ [data.field]: data.value });
          }
        }
      }
    };

  const onTabClose = () => {
    history.push(`/app/tasks`);
  };

  const subscribe = useCallback(() => {
    // join relevant channel
    handleEvents({
      action: 'join',
      channels: ['/tasks/job']
    });

    tasksService.on('created', res => {
      const { _id } = res;
      const jobId = R.path(['entities', 'job'])(res);

      if (jobId) {
        dispatchTasks({ type: 'created', _id, data: res });
        jobsService
          .get(jobId)
          .then(job =>
            dispatchJobs({ type: 'created', _id: job._id, data: job })
          );
      }
    });
    tasksService.on('patched', res => {
      const { _id } = res;
      dispatchTasks({ type: 'patched', _id, data: res });
    });
    tasksService.on('removed', res => {
      const { _id } = res;
      dispatchTasks({ type: 'removed', _id });
    });
    log.info('✅ tasks updates');

    messagesService.on('created', res => {
      const { _id } = res;
      dispatchMessages({ type: 'created', _id, data: res });
    });
    messagesService.on('patched', res => {
      const { _id } = res;
      dispatchMessages({ type: 'patched', _id, data: res });
    });
    messagesService.on('removed', res => {
      const { _id } = res;
      dispatchMessages({ type: 'removed', _id });
    });
    log.info('✅ messages updates');

    jobsService.on('patched', res => {
      const { _id } = res.job;
      dispatchJobs({ type: 'patched', _id, data: res.job });
    });
    log.info('✅ jobs updates');
  }, []);

  const unsubscribe = useCallback(() => {
    // leave relevant channel
    handleEvents({
      action: 'leave',
      channels: ['/tasks/job']
    });

    tasksService.off('created');
    tasksService.off('patched');
    tasksService.off('removed');
    log.info('⛔ tasks updates');

    // leave relevant channels
    handleEvents({
      action: 'leave',
      channels: R.pipe(
        R.map(R.prop('channels')),
        R.values,
        R.map(getChannelNames),
        R.flatten
      )(initializedJobs)
    });
    messagesService.off('created');
    messagesService.off('patched');
    messagesService.off('removed');
    log.info('⛔ messages updates');

    jobsService.off('patched');
    log.info('⛔ jobs updates');

    events.off('ensureJobExists');
  }, []);

  useEffect(() => {
    if (activeJobId && !activeJob) {
      jobsService
        .get(activeJobId)
        .then(job =>
          dispatchJobs({ type: 'created', _id: job._id, data: job })
        );
    }
  }, [activeJobId, activeJob]);

  useEffect(() => {
    if (!activeJobId) {
      if (previousJobId) {
        jobsService.patch(previousJobId, { activeTab: false });
      }
    } else if (!previousJobId) {
      jobsService.patch(activeJobId, { activeTab: true });
    } else if (previousJobId !== activeJobId) {
      jobsService.patch(previousJobId, { activeTab: false });
      jobsService.patch(activeJobId, { activeTab: true });
    }
  }, [activeJobId, previousJobId]);

  useEffect(() => {
    initialQuery();
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe]);

  return (
    <DefaultBase>
      <Navbar>
        <Text fontSize="14px">
          FMS <strong>/ Tasks</strong>
        </Text>
      </Navbar>
      {updating && <Preloader inverted content="Updating ..." />}
      <Body>
        <TaskPane
          tasks={tasks}
          jobs={jobs}
          loading={tasksLoading}
          onTaskUpdate={onTaskUpdate}
        />
        {activeJob ? (
          <JobPane
            jobId={activeJobId}
            job={activeJob}
            messages={messages}
            initializedData={initializedJobs[activeJobId]}
            setInitialized={setInitializedJobData(activeJobId)}
            dispatchMessages={dispatchMessages}
            onJobUpdate={onJobUpdate(activeJobId)}
            onMessageSend={onMessageSend}
            onTabClose={onTabClose}
          />
        ) : (
          <EmptyBase>
            <EmptyState
              title="All Active Tasks"
              subtitle="Click on a Task Group to open the details for a given Job"
              imgSrc={imgSrc}
              imgSize="400px"
            />
          </EmptyBase>
        )}
      </Body>
    </DefaultBase>
  );
};

export default Tasks;
