export const MENU_HEIGHT = '50px';
export const MENU_WIDTH = '60px';
export const FOOTER_HEIGHT = '60px';
export const TAB_HEIGHT = '40px';
export const CHAT_INPUT_HEIGHT = '8rem';
export const BORDER = '1px solid #d4d4d5';
export const LONDON = { lat: 51.515419, lng: -0.141099 };
export const ACTION_HEADER_HEIGHT = '4.7rem';
export const SIDEBAR_WIDTH = '275px';
export const TH_WIDTH = '120px';
