import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Divider, Accordion, Label } from 'semantic-ui-react';

const optionConvertor = ({ value, text }) => ({ value, text: text || value });

const nullOption = { value: null, text: 'Empty' };

const timeOptions = [
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM'
];

const booleanOptions = [
  nullOption,
  { value: true, text: 'Present' },
  { value: false, text: 'Absent' }
];

const config = {
  pickup: {
    label: 'Pickup'
  },
  delivery: {
    label: 'Delivery'
  },
  inventory: {
    label: 'Inventory'
  },
  fulfilmentDate: {
    label: 'Date',
    type: 'date'
  },
  pickupTimeFrom: {
    label: 'Time From',
    options: [
      nullOption,
      ...timeOptions.map(value => optionConvertor({ value }))
    ],
    placeholder: 'Select...',
    selection: true,
    fluid: true
    // search: true
  },
  pickupTimeTo: {
    label: 'Time To',
    options: [
      nullOption,
      ...timeOptions.map(value => optionConvertor({ value }))
    ],
    placeholder: 'Select...',
    selection: true,
    fluid: true
    // search: true
  },
  quoteOption: {
    label: 'Men',
    placeholder: 'Select...',
    selection: true,
    fluid: true,
    options: [
      { value: 'one_with_help', text: 'One Man' },
      { value: 'two_without_help', text: 'Two Men' },
      { value: 'self_loading', text: 'Self Loading' }
    ]
  },
  hourly: {
    label: 'Hourly',
    placeholder: 'Select...',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  pickupFloors: {
    label: 'Pickup Floors',
    type: 'number',
    posthook: x => Number(x)
  },
  pickupLift: {
    label: 'Lift',
    placeholder: 'Select...',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  deliveryFloors: {
    label: 'Delivery Floors',
    type: 'number',
    posthook: x => Number(x)
  },
  deliveryLift: {
    label: 'Lift',
    placeholder: 'Select...',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  priceAcceptance: {
    label: 'Price Acceptance',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'accepted', text: 'Accepted' },
      { value: 'notAccepted', text: 'Not Accepted' }
    ]
  },
  name: {
    label: 'Name'
  },
  email: {
    label: 'Email',
    type: 'email'
  },
  number: {
    label: 'Number'
  }
};

const Base = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const StyledAccordion = styled(Accordion)`
  & > .title {
    padding: 0 !important;
  }
`;

const FormSegmentBase = styled.div`
  padding: 1rem 1rem;
`;

const FormSegment = ({ title, children }) => (
  <FormSegmentBase>
    <StyledAccordion
      panels={[
        {
          key: title,
          title: { content: <Label content={title} /> },
          content: { content: children }
        }
      ]}
    />
  </FormSegmentBase>
);

const FormComponent = ({ jobId, job, loading, onUpdate }) => {
  const [patch, setPatch] = useState({});

  const addProps = field => {
    const fieldConfig = config[field] || {};
    const onChange = fieldConfig.posthook
      ? (_, { value }) => setPatch({ [field]: fieldConfig.posthook(value) })
      : (_, { value }) => setPatch({ [field]: value });

    const value =
      patch[field] !== undefined
        ? patch[field]
        : job[field] !== undefined
        ? job[field]
        : '';

    const onBlur = () => {
      const key = Object.keys(patch)[0];
      if (key) {
        const notEmpty = patch[key] !== undefined;
        const notUnchanged = patch[key] !== job[key];
        if (notEmpty && notUnchanged) {
          onUpdate({ jobId, data: patch });
          setPatch({});
        }
      }
    };

    delete fieldConfig.posthook;

    return {
      value,
      onChange,
      onBlur,
      disabled: loading,
      ...fieldConfig
    };
  };

  return (
    <Base>
      <Form>
        <FormSegment title="Postcodes & Inventory">
          <Form.Input {...addProps('pickup')} />
          <Form.Input {...addProps('delivery')} />
          <Form.TextArea {...addProps('inventory')} />
        </FormSegment>
        <Divider fitted />
        <FormSegment title="Fulfilment Date & Time">
          <Form.Input {...addProps('fulfilmentDate')} />
          <Form.Dropdown {...addProps('pickupTimeFrom')} />
          <Form.Dropdown {...addProps('pickupTimeTo')} />
        </FormSegment>
        <Divider fitted />
        <FormSegment title="Men & Hourly">
          <Form.Dropdown {...addProps('quoteOption')} />
          <Form.Dropdown {...addProps('hourly')} />
        </FormSegment>
        <Divider fitted />
        <FormSegment title="Floors & Lifts">
          <Form.Input {...addProps('pickupFloors')} />
          <Form.Dropdown {...addProps('pickupLift')} />
          <Form.Input {...addProps('deliveryFloors')} />
          <Form.Dropdown {...addProps('deliveryLift')} />
        </FormSegment>
        <Divider fitted />
        <FormSegment title="Price Acceptance & Contact">
          <Form.Dropdown {...addProps('priceAcceptance')} />
          <Form.Input {...addProps('name')} />
          <Form.Input {...addProps('email')} />
          <Form.Input {...addProps('number')} />
        </FormSegment>
      </Form>
    </Base>
  );
};

export default FormComponent;
