import * as R from 'ramda';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar';
import styled from 'styled-components';
import logger from 'utils/logger';

import { client } from 'api';
import * as C from 'containers';
import { services } from 'store';
import { AppContext } from 'store/context';

const log = logger({ module: 'app' });

const Base = styled.div`
  background-color: #fefefe;
`;

const AppBody = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
`;

export const navConfig = [
  {
    key: '/app/home',
    title: 'Home',
    icon: 'home'
  },
  {
    key: '/app/booking',
    title: 'Booking',
    icon: 'comments'
  },
  {
    key: '/app/tasks',
    title: 'Tasks',
    icon: 'clipboard list'
  },
  {
    key: '/app/jobs',
    title: 'Jobs',
    icon: 'suitcase'
  },
  // {
  //   key: '/app/vendors',
  //   title: 'Vendors',
  //   icon: 'shipping'
  // },
  {
    key: '/app/routes',
    title: 'Routes',
    icon: 'map marker alternate'
  }
];

const checkNavConfig = (auth, location) => {
  const current = R.find(n => location.pathname.includes(n.key), navConfig) || {
    title: 'Waaaaa..?'
  };

  const userPermissions = R.path(['user', 'role', 'permissions'], auth) || [];

  const checkNav = nav =>
    nav.permissions
      ? R.any(p => R.contains(p, userPermissions), nav.permissions)
      : true;

  const permitted = checkNav(current);
  const allPermitted = R.filter(checkNav, navConfig);

  return {
    current,
    permitted,
    allPermitted
  };
};

class App extends Component {
  handleLogout = async () => {
    await client
      .service('/operators')
      .patch(this.state.user._id, { status: 'offline' });
    await services.auth.logout();
    window.location.reload();
  };

  handleEvents = ({ action, channels }) => {
    if (!channels.length) {
      return;
    }

    let newChannels;

    if (action === 'join') {
      newChannels = R.uniq([...this.state.eventChannels, ...channels]);
    }

    if (action === 'leave') {
      newChannels = this.state.eventChannels.filter(c => !channels.includes(c));
    }

    this.setState({ eventChannels: newChannels });

    return client.service('/eventChannels').create({
      action,
      channels
    });
  };

  state = {
    handleLogout: this.handleLogout,
    user: this.props.auth.user,
    navigation: checkNavConfig(this.props.auth, this.props.location),
    vendors: [],
    network: {
      connectionStatus: 'connected',
      description: '',
      eventStatus: 'connected'
    },
    eventChannels: [],
    handleEvents: this.handleEvents
  };

  userSubscribe = () => {
    log.info('✅ user updates');
    client.service('/operators').on('patched', data => {
      this.setState({ user: R.mergeDeepRight(this.state.user, data) });
    });
  };

  userUnsubscribe = () => {
    log.info('⛔ user updates');
    client.service('/operators').off('patched');
  };

  networkHandler = () => {
    client.io.on('connect', (...args) => {
      let tries = 0;
      let opts = {
        connectionStatus: 'connected',
        description: 'Connected 🚀',
        eventStatus: 'connected'
      };

      const timer = setInterval(async () => {
        tries++;

        if (this.state.network.eventStatus === 'connected') {
          clearInterval(timer);
        }

        try {
          if (
            client.get('accessToken') &&
            this.state.network.eventStatus !== 'connected'
          ) {
            await this.handleEvents({
              action: 'join',
              channels: this.state.eventChannels
            });
            clearInterval(timer);
            log.info(`${opts.connectionStatus} ${args[0] || ''}`);
            this.props.openSnackbar(opts.description, 2000);
            this.setState({ network: opts });
          }
        } catch (error) {
          if (tries === 5) {
            clearInterval(timer);
            opts = {
              connectionStatus: 'needs-refresh',
              description: 'Page Needs Refresh 💥',
              eventStatus: 'needs-refresh'
            };
            log.error(`${opts.connectionStatus} ${args[0] || ''}`);
            this.props.openSnackbar(opts.description, 5000);
            this.setState({ network: opts });
          }
        }
      }, 2500);
    });

    client.io.on('disconnect', (...args) => {
      const opts = {
        connectionStatus: 'disconnected',
        description: `Disconnected: ${args[0]}`,
        eventStatus: 'disconnected'
      };
      log.error(`${opts.connectionStatus} ${args[0] || ''}`);

      this.props.openSnackbar(opts.description, 5000);

      this.setState({ network: opts });
    });
  };

  async componentDidMount() {
    this.networkHandler();
    this.userSubscribe();
    client
      .service('/operators')
      .patch(this.state.user._id, { status: 'online' });

    client
      .service('/vendors')
      .find({
        query: {
          active: true,
          $sort: {
            'profileData.city': 1
          },
          $limit: 100
        }
      })
      .then(res => {
        this.setState({ vendors: res.data });
      });
  }

  async componentWillUnmount() {
    this.userUnsubscribe();
  }

  render() {
    const { auth, location } = this.props;
    const { navigation } = this.state;

    return !auth.isSignedIn ? (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    ) : !navigation.permitted ? (
      <Redirect
        to={{
          pathname: '/app/404',
          state: { from: location }
        }}
      />
    ) : (
      <Base>
        <AppBody>
          <Helmet>
            <meta charSet="utf-8" />
            {/* TODO make it dynamic */}
            <title>Greenvan Dashboard</title>
          </Helmet>
          <AppContext.Provider value={this.state}>
            <Switch>
              <Route exact path="/app/home" component={C.Home} />
              <Route exact path="/app/booking" component={C.Booking} />
              <Route exact path="/app/tasks/" component={C.Tasks} />
              <Route exact path="/app/jobs/" component={C.Jobs} />
              <Route exact path="/app/vendors/" component={C.Vendors} />
              <Route exact path="/app/routes/" component={C.Routes} />
              <Route component={C.FourOFour} />
            </Switch>
          </AppContext.Provider>
        </AppBody>
      </Base>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });
export default withRouter(withSnackbar(connect(mapStateToProps)(App)));
