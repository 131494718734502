import { css } from 'glamor';

export default css({
  '& .ui.progress': {
    margin: '0 0 1em 0'
  },
  '& .play-stats': {
    marginLeft: '1em'
  }
});
