import React from 'react';
import PropTypes from 'prop-types';
import { Comment, Icon, Popup, Dimmer, Loader, Label } from 'semantic-ui-react';
import { AudioPlayer } from 'components/v1';

import { utc as moment } from 'moment';
import * as R from 'ramda';

import styles from './styles';
const getAvatar = from =>
  require(`assets/images/avatar_${from || 'rob'}.svg`).default;

const CallAttachment = props => (
  // TODO add more details of the call
  <AudioPlayer url={props.voicemail || props.recording} />
);

const Message = ({
  _id,
  body,
  from,
  createdAt,
  status,
  channel,
  _meta = {}
}) => {
  const call = R.prop('call', _meta);
  const error = R.prop('error', _meta);

  return (
    <Comment key={_id}>
      {error ? (
        <Label size="mini" corner="left" color="red" icon="warning" />
      ) : null}
      <Comment.Avatar src={getAvatar(from)} />
      <Comment.Content>
        <Comment.Author as="span">{from}</Comment.Author>
        <Comment.Metadata>
          <span>{moment(createdAt).format('D MMM YY, hh:mm A')}</span>
        </Comment.Metadata>
        <Comment.Text>{body}</Comment.Text>
        {call && (
          <Comment.Text>
            <CallAttachment {...call} />
          </Comment.Text>
        )}
        <Comment.Actions>
          <Popup
            basic
            inverted
            hoverable
            on="click"
            trigger={<Icon link name="ellipsis vertical" size="large" />}
            content={
              !error ? (
                <span>
                  {`Channel: ${channel}`}
                  <br />
                  {`Status: ${status}`}
                </span>
              ) : (
                <span>
                  {`Channel: ${channel}`}
                  <br />
                  {`Status: ${status}`}
                  <br />
                  {`Error: ${error.message}`}
                </span>
              )
            }
          />
        </Comment.Actions>
      </Comment.Content>
    </Comment>
  );
};

const MessageGroup = ({ messages = [], loading }) => (
  <div>
    <Dimmer active={loading} inverted>
      <Loader content="Loading Messages..." />
    </Dimmer>
    <Comment.Group minimal {...styles}>
      {messages.map(Message)}
    </Comment.Group>
  </div>
);

CallAttachment.propTypes = {
  voicemail: PropTypes.string,
  recording: PropTypes.string
};

Message.propTypes = {
  _id: PropTypes.string,
  body: PropTypes.string,
  from: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  status: PropTypes.string,
  channel: PropTypes.string,
  _meta: PropTypes.object
};

MessageGroup.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

export default MessageGroup;
