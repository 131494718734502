export const sortFfDate = (a, b) =>
  new Date(a.fulfilmentDate) - new Date(b.fulfilmentDate);

export const makeFilters = ({ vendors }) => {
  const filterFields = [
    {
      key: 'bookingStatus',
      type: 'multiselect',
      props: {
        label: 'Booking Status',
        options: [
          { value: 'confirmed', text: 'Confirmed' },
          { value: 'tentative', text: 'Discount Quote' },
          { value: 'quote_seeker', text: 'Send Quote' },
          { value: 'dropped', text: 'No Contact Info' },
          { value: 'conditional', text: 'Conditional' }
        ]
      }
    },
    {
      key: 'advanceReceived',
      type: 'boolean',
      props: {
        label: 'Advance Received'
      }
    },
    {
      key: 'driverId',
      type: 'multiselect',
      props: {
        label: 'Driver Accepted',
        options: vendors.map(v => ({
          text: `${v.profileData.city} - ${v.name}`,
          value: v._id
        }))
      }
    },
    {
      key: 'fulfilmentStatus',
      type: 'multiselect',
      props: {
        label: 'Fulfilment Status',
        options: [
          { value: 'fulfilled', text: 'Fulfilled' },
          { value: 'cancelled', text: 'Customer Cancelled' },
          { value: 'cantFulfil', text: "Can't Fulfil" },
          { value: 'dropped', text: 'Dropped' },
          { value: 'disputed', text: 'Disputed' },
          { value: 'refunded', text: 'Refunded' }
        ]
      }
    },
    {
      key: 'pickupAddress',
      type: 'boolean',
      props: {
        label: 'Pickup Address Present'
      }
    },
    {
      key: 'deliveryAddress',
      type: 'boolean',
      props: {
        label: 'Delivery Address Present'
      }
    },
    {
      key: 'isTestJob',
      type: 'boolean',
      props: {
        label: 'Is Test Job'
      }
    }
  ];

  const baseFilters = {
    bookingStatus: ['confirmed', 'conditional'],
    fulfilmentStatus: ['none'],
    isTestJob: false
  };

  const filterPresets = [
    {
      key: '1) Base [C&C + No FF + No Test]',
      filters: baseFilters
    },
    {
      key: '2) Base + Advance Paid + No Driver',
      filters: {
        ...baseFilters,
        advanceReceived: true,
        driverId: ['none']
      }
    },
    {
      key: '3) Base + No Advance + No Driver',
      filters: {
        ...baseFilters,
        advanceReceived: false,
        driverId: ['none']
      }
    }
  ];

  return {
    filterFields,
    filterPresets
  };
};
