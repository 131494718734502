import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Button, Progress } from 'semantic-ui-react';

import styles from './styles';

const fmtMSS = x => {
  let s = Math.round(x);
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};

class AudioPlayer extends Component {
  state = {
    playing: this.props.playing || false,
    volume: 0.8,
    duration: 0,
    played: 0,
    muted: false,
    loop: false
  };

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  onPlay = () => {
    this.setState({ playing: true });
  };

  onPause = () => {
    this.setState({ playing: false });
  };

  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  onProgress = state => {
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  ref = player => {
    this.player = player;
  };

  render() {
    const { playing, volume, muted, played, duration, loop } = this.state;
    const { url } = this.props;

    return (
      <div {...styles}>
        {url && (
          <ReactPlayer
            ref={this.ref}
            className="react-player"
            width={0}
            height={0}
            url={url}
            playing={playing}
            volume={volume}
            muted={muted}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onEnded={() => this.setState({ playing: loop })}
            onError={e => console.log('onError', e)}
            onProgress={this.onProgress}
            onDuration={duration => this.setState({ duration })}
          />
        )}
        <Progress
          disabled={!url}
          percent={played * 100}
          size="tiny"
          indicating={playing}
        />
        <Button
          disabled={!url}
          icon={playing ? 'pause' : 'play'}
          onClick={this.playPause}
        />
        <span className="play-stats">
          {!url
            ? 'No Recording Available'
            : `${fmtMSS(played * duration)} / ${fmtMSS(duration)}`}
        </span>
      </div>
    );
  }
}
AudioPlayer.propTypes = {
  url: PropTypes.string,
  playing: PropTypes.bool
};

export default AudioPlayer;
