import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import SnackbarProvider from 'react-simple-snackbar';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import store, { services } from 'store';
import { App, Signin, FourOFour } from 'containers';
import { Preloader } from 'components/v1';
import rollbar from 'utils/rollbar';

class Root extends Component {
  state = { rehydrated: false };
  async UNSAFE_componentWillMount() {
    persistStore(store, { whitelist: ['auth'] }, async () => {
      try {
        await services.auth.authenticate();
      } catch (e) {
        console.log(`[${e.name}] ${e.message}`);
      }
      this.setState({ rehydrated: true });
    });
  }
  render() {
    if (!this.state.rehydrated) {
      return <Preloader content="Resuming session..." />;
    }
    return (
      <RollbarProvider instance={rollbar}>
        <ErrorBoundary>
          <ReduxProvider store={store}>
            <SnackbarProvider>
              <Router>
                <Switch>
                  <Route exact path="/" component={Signin} />
                  <Route path="/app" component={App} />
                  <Route component={FourOFour} />
                </Switch>
              </Router>
            </SnackbarProvider>
          </ReduxProvider>
        </ErrorBoundary>
      </RollbarProvider>
    );
  }
}
export default Root;
