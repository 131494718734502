import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Segment, Divider, Form, Image } from 'semantic-ui-react';

import { services } from 'store/index';

import bgImg from 'assets/images/bg_move.jpg';
import logoImg from 'assets/images/logo.png';

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${bgImg});
  background-size: cover;
  background-position: center;
  height: 100vh;
`;

const StyledSegment = styled(Segment)`
  width: 320px;
  padding: 3rem 2.5rem !important;
`;

const Header = styled.div`
  margin-top: 1.5rem;
  font-weight: 900;
  font-size: 24px;
`;

const Subheader = styled.div`
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
`;

class Signin extends Component {
  state = {
    email: '',
    password: '',
    header: {
      content: 'Welcome Back!',
      subheader: 'Enter details to get started'
    },
    error: false
  };

  authenticate = async () => {
    const { email, password } = this.state;
    await services.auth.authenticate({
      strategy: 'local',
      email,
      password
    });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { auth } = nextProps;

    if (auth.isError) {
      const errMessage = auth.isError.message;
      if (
        errMessage !==
        'Could not find stored JWT and no authentication strategy was given'
      ) {
        this.setState(prev => ({
          ...prev,
          error: true,
          header: {
            content: 'Error Signing In',
            subheader: 'Please enter correct details'
          }
        }));
      }
    }
  };

  render() {
    const { auth, location } = this.props;
    const { header, error } = this.state;

    return auth.isSignedIn ? (
      <Redirect
        to={{
          pathname: '/app/home',
          state: { from: location }
        }}
      />
    ) : (
      <Base>
        <StyledSegment loading={auth.isLoading} raised>
          <Image src={logoImg} size="tiny" />
          <Header>
            {header.content}
            <Subheader>{header.subheader}</Subheader>
          </Header>
          <Divider hidden />
          <Form>
            <Form.Input
              onChange={(_, { value }) =>
                this.setState(prev => ({
                  ...prev,
                  email: value
                }))
              }
              type="email"
              icon="user"
              iconPosition="left"
              placeholder="Email"
              error={error}
            />
            <Form.Input
              onChange={(_, { value }) =>
                this.setState(prev => ({
                  ...prev,
                  password: value
                }))
              }
              type="password"
              icon="key"
              iconPosition="left"
              placeholder="Password"
              error={error}
            />
            <Form.Button
              content="Sign In"
              onClick={this.authenticate}
              size="large"
              color="green"
              fluid
            />
          </Form>
          <Divider hidden />
        </StyledSegment>
      </Base>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });
export default withRouter(connect(mapStateToProps)(Signin));
