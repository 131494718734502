import { utc as moment } from 'moment';
import * as R from 'ramda';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useImmerReducer } from 'use-immer';
import logger from 'utils/logger';

import { client } from 'api';
import vanImg from 'assets/images/van.png';
import { DefaultBase, EmptyState, Navbar, Text } from 'components/v2';
import { AppContext } from 'store/context';
import { DBReducer, usePrevious } from 'utils';
import { MENU_HEIGHT } from 'utils/constants';
import { JobList, JobPane } from './components';

const log = logger({ module: 'jobs' });

const getChannelNames = R.pipe(
  R.pick(['email', 'sms', 'webchat', 'whatsapp']),
  R.values,
  R.map(x => `/chat/${x}`)
);

const Body = styled.div`
  height: calc(100% - ${MENU_HEIGHT});
  display: flex;
`;

const EmptyBase = styled.div`
  width: 60%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
`;

const Jobs = () => {
  const { handleEvents } = useContext(AppContext);

  const [jobsLoading, setJobsLoading] = useState(true);
  const [initialized, setInitialized] = useState({});
  const [jobs, dispatchJobs] = useImmerReducer(DBReducer, []);
  const [messages, dispatchMessages] = useImmerReducer(DBReducer, []);
  const history = useHistory();

  const jobsService = client.service('/jobs');
  const messagesService = client.service('/db/messages');

  const location = useLocation();
  const activeJobId = location.hash
    ? Number(location.hash.replace('#', ''))
    : null;

  const activeJob = jobs.find(x => x.jobId === activeJobId);

  const previousJobId = usePrevious(activeJobId);

  const setInitializedWrapper = jobId => data => {
    handleEvents({
      action: 'join',
      channels: getChannelNames(data.channels)
    });

    setInitialized({
      ...initialized,
      [jobId]: data
    });

    log.info(`✅ fms chat updates [${jobId}]`);
  };

  const initialQuery = async () => {
    setJobsLoading(true);
    const { data: jobs } = await jobsService.find({
      query: {
        fulfilmentDateStart: moment().subtract(1, 'd').format('YYYY-MM-DD'),
        fulfilmentDateEnd: moment().add(30, 'd').format('YYYY-MM-DD')
      }
    });

    dispatchJobs({ type: 'created', data: jobs });
    setJobsLoading(false);
  };

  const onJobUpdate = jobId => async data => {
    dispatchJobs({
      type: 'patched',
      altId: { key: 'jobId', value: jobId },
      data,
      patchStyle: 'merge'
    });
    await jobsService.patch(jobId, data);
    return;
  };

  const onMessageSend =
    sessionId =>
    ({ body, channel, ...attrs }) => {
      client.service('/customers/chat/messages').create({
        body,
        from: 'operator',
        sessionId,
        ...attrs
      });
    };

  const onTabClose = () => {
    history.push(`/app/jobs`);
  };

  const subscribe = useCallback(() => {
    jobsService.on('created', res => {
      const { _id } = res;
      dispatchJobs({ type: 'created', _id, data: res }); // test this
    });

    jobsService.on('patched', res => {
      const { _id } = res.job;
      dispatchJobs({ type: 'patched', _id, data: res.job });
    });
    log.info('✅ jobs updates');

    messagesService.on('created', res => {
      const { _id } = res;
      dispatchMessages({ type: 'created', _id, data: res });
    });
    messagesService.on('patched', res => {
      const { _id } = res;
      dispatchMessages({ type: 'patched', _id, data: res });
    });
    messagesService.on('removed', res => {
      const { _id } = res;
      dispatchMessages({ type: 'removed', _id });
    });
    log.info('✅ messages updates');
  }, []);

  const unsubscribe = useCallback(() => {
    jobsService.off('created');
    jobsService.off('patched');
    log.info('⛔ jobs updates');

    // leave relevant channels
    handleEvents({
      action: 'leave',
      channels: R.pipe(
        R.map(R.prop('channels')),
        R.values,
        R.map(getChannelNames),
        R.flatten
      )(initialized)
    });
    messagesService.off('created');
    messagesService.off('patched');
    messagesService.off('removed');
    log.info('⛔ messages updates');
  }, []);

  useEffect(() => {
    if (!activeJobId) {
      if (previousJobId) {
        jobsService.patch(previousJobId, { activeTab: false });
      }
    } else if (!previousJobId) {
      jobsService.patch(activeJobId, { activeTab: true });
    } else if (previousJobId !== activeJobId) {
      jobsService.patch(previousJobId, { activeTab: false });
      jobsService.patch(activeJobId, { activeTab: true });
    }
  }, [activeJobId, previousJobId]);

  useEffect(() => {
    initialQuery();
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe]);

  return (
    <DefaultBase>
      <Navbar
        children={
          <Text fontSize="14px">
            FMS <strong>/ Jobs</strong>
          </Text>
        }
      ></Navbar>
      <Body>
        <JobList activeJobId={activeJobId} jobs={jobs} loading={jobsLoading} />
        {activeJob ? (
          <JobPane
            key={activeJobId}
            job={activeJob}
            messages={messages}
            initializedData={initialized[activeJobId]}
            setInitialized={setInitializedWrapper(activeJobId)}
            dispatchMessages={dispatchMessages}
            onMessageSend={onMessageSend}
            onJobUpdate={onJobUpdate(activeJobId)}
            onTabClose={onTabClose}
          />
        ) : (
          <EmptyBase>
            <EmptyState
              title="All Active Jobs"
              subtitle="Select a Job to open chat and form"
              imgSrc={vanImg}
              imgSize="400px"
            />
          </EmptyBase>
        )}
      </Body>
    </DefaultBase>
  );
};

export default Jobs;
