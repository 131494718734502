import { utc as moment } from 'moment';
import * as R from 'ramda';
import { DateInput } from 'semantic-ui-calendar-react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { fmtTime } from './helpers';

const optionConvertor = ({ value, text }) => ({ value, text: text || value });
const numberPostHook = x => (x ? Number(x) : null);
const nullOption = { value: null, text: '' };

const timeOptions = [
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM'
];

const booleanOptions = [
  nullOption,
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
];

const dateFmts = {
  std: 'YYYY-MM-DD',
  pretty: 'ddd, DD MMM YY'
};

const ReadOnlyBase = styled.div`
  padding: 0 0.5rem;
  & div {
    height: 25px;
    padding: 0.4rem;
  }
`;

const ReadOnly = ({ label, value }) => (
  <ReadOnlyBase className="field">
    <label>{label}</label>
    <div>{value}</div>
  </ReadOnlyBase>
);

const formCompsConfig = {
  pickup: Form.Input,
  delivery: Form.Input,
  via: Form.Input,
  distance: Form.Input,
  travelTime: Form.Input,
  inventory: Form.TextArea,
  fulfilmentDate: DateInput,
  pickupTimeFrom: Form.Dropdown,
  pickupTimeTo: Form.Dropdown,
  quoteOption: Form.Dropdown,
  hourly: Form.Dropdown,
  pickupFloors: Form.Input,
  pickupLift: Form.Dropdown,
  deliveryFloors: Form.Input,
  deliveryLift: Form.Dropdown,
  priceAcceptance: Form.Dropdown,
  name: Form.Input,
  number: Form.Input,
  email: Form.Input,
  bookingStatus: Form.Dropdown,
  bookingIntention: Form.Dropdown,
  flexiPriceOption: Form.Dropdown,
  priceFragment: ReadOnly,
  driverNotes: Form.TextArea,
  internalNotes: Form.TextArea,
  pickupAddress: Form.TextArea,
  deliveryAddress: Form.TextArea,
  viaAddress: Form.TextArea,
  driverId: Form.Dropdown,
  assignedDriverId: Form.Dropdown,
  advanceAmount: ReadOnly,
  advanceManualAdjustment: Form.Input,
  advanceReceived: Form.Input,
  balanceReceived: Form.Input,
  fulfilmentStatus: Form.Dropdown,
  feedback: Form.Input,
  feedbackMessage: Form.TextArea,
  vendorShare: ReadOnly,
  ourCommission: ReadOnly,
  commissionOverride: Form.Input,
  isTestJob: Form.Dropdown,
  typeSpecialNeeds: Form.Dropdown,
  manualAdjustment: Form.Input,
  balancePaymentMode: Form.Dropdown,
  balancePaymentAcceptOnline: Form.Dropdown,
  totalJobValue: ReadOnly,
  nonCommissionableAmount: Form.Input,
  noOfHours: ReadOnly,
  totalOurCommission: ReadOnly,
  totalVendorShare: ReadOnly,
  vendorSettlementAmount: ReadOnly,
  vendorSettlementStatus: Form.Dropdown,
  vendorSettlementNotes: Form.TextArea,
  jobSummaryOnAcceptance: Form.TextArea,
  vendorShareOnAcceptance: ReadOnly,
  stage: Form.Dropdown,
  moveType: Form.Dropdown,
  propertyTypeAtPickup: Form.Dropdown,
  propertyTypeAtDelivery: Form.Dropdown,
  propertyTypeHouseAtPickup: Form.Dropdown,
  propertyTypeHouseAtDelivery: Form.Dropdown,
  numberOfBedrooms: Form.Input,
  shopBrand: Form.Input,
  officeArea: Form.Dropdown,
  packingService: Form.Dropdown,
  dismantlingService: Form.Dropdown,
  inventoryList: Form.Dropdown
};

const formConfig = {
  pickup: {
    label: 'Pickup',
    placeholder: 'n1',
    required: true
  },
  delivery: {
    label: 'Delivery',
    placeholder: 'n2',
    required: true
  },
  via: {
    label: 'Via',
    placeholder: 'n3'
  },
  distance: {
    label: 'Distance (miles)',
    placeholder: '5',
    type: 'number',
    pre: x => (x ? Math.round(x * 0.621371) : null),
    post: x => (x ? Math.round(x / 0.621371) : null)
  },
  travelTime: {
    label: 'Travel Time (mins)',
    placeholder: '5',
    type: 'number',
    post: numberPostHook
  },
  inventory: {
    label: 'Inventory',
    placeholder: '10 large boxes',
    required: true
  },
  fulfilmentDate: {
    label: 'Fulfilment Date',
    icon: 'calendar alternate',
    required: true,
    iconPosition: 'right',
    dateFormat: dateFmts.pretty,
    placeholder: moment().format(dateFmts.pretty),
    pre: x => (x ? moment(x, dateFmts.std).format(dateFmts.pretty) : ''),
    post: x => moment(x, dateFmts.pretty).format(dateFmts.std)
  },
  pickupTimeFrom: {
    label: 'Time From',
    options: [
      nullOption,
      ...timeOptions.map(value => optionConvertor({ value }))
    ],
    placeholder: '10:00 AM',
    selection: true,
    fluid: true
    // search: true
  },
  pickupTimeTo: {
    label: 'Time To',
    options: [
      nullOption,
      ...timeOptions.map(value => optionConvertor({ value }))
    ],
    placeholder: '04:00 PM',
    selection: true,
    fluid: true
    // search: true
  },
  quoteOption: {
    label: 'Men',
    placeholder: 'One Man',
    required: true,
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'one_with_help', text: 'One Man' },
      { value: 'two_without_help', text: 'Two Men' },
      { value: 'self_loading', text: 'Self Loading' }
    ]
  },
  hourly: {
    label: 'Hourly',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  pickupFloors: {
    label: 'Pickup Floors',
    placeholder: '3',
    required: true,
    type: 'number',
    post: numberPostHook
  },
  pickupLift: {
    label: 'Lift',
    placeholder: 'Yes',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  deliveryFloors: {
    label: 'Delivery Floors',
    required: true,
    placeholder: '2',
    type: 'number',
    post: numberPostHook
  },
  deliveryLift: {
    label: 'Lift',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  priceAcceptance: {
    label: 'Price Accepted?',
    placeholder: 'Yes',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'accepted', text: 'Yes' },
      { value: 'notAccepted', text: 'No' }
    ]
  },
  name: {
    label: 'Name',
    placeholder: 'Sam Smith'
  },
  email: {
    label: 'Email',
    type: 'email',
    placeholder: 'sam.smith@email.com'
  },
  number: {
    label: 'Number',
    placeholder: '+449827648200',
    required: true
  },
  flexiPriceOption: {
    label: 'Price Option Chosen',
    placeholder: 'Flexi/Specific',
    required: true,
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'flexi', text: 'Flexible' },
      { value: 'specific', text: 'Specific' }
    ]
  },
  bookingIntention: {
    label: 'Wants to book?',
    placeholder: 'Yes',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  bookingStatus: {
    label: 'Booking Status',
    placeholder: 'Confirmed',
    required: true,
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'confirmed', text: 'Confirmed' },
      { value: 'tentative', text: 'Discount Quote' },
      { value: 'quote_seeker', text: 'Send Quote' },
      { value: 'dropped', text: 'No Contact Info' },
      { value: 'conditional', text: 'Conditional' }
    ]
  },
  priceFragment: {
    label: 'Current Price',
    placeholder: '40'
  },
  internalNotes: {
    label: 'Internal Notes',
    placeholder: 'For internal usage only...'
  },
  driverNotes: {
    label: 'Job Notes',
    placeholder: 'Visible customer and driver...'
  },
  pickupAddress: {
    label: 'Pickup Address',
    placeholder: '221b baker st'
  },
  deliveryAddress: {
    label: 'Delivery Address',
    placeholder: '221b baker st'
  },
  viaAddress: {
    label: 'Via Address',
    placeholder: '221b baker st'
  },
  driverId: {
    label: 'Accepted Driver',
    placeholder: 'Bill Bailey',
    selection: true,
    search: true,
    fluid: true
  },
  assignedDriverId: {
    label: 'Assigned Driver',
    placeholder: 'James Acaster',
    selection: true,
    search: true,
    fluid: true
  },
  advanceAmount: {
    label: 'Advance Amount',
    pre: x => `£${x}`
  },
  advanceReceived: {
    label: 'Advance Received',
    placeholder: '7',
    type: 'number',
    post: numberPostHook
  },
  advanceManualAdjustment: {
    label: 'Advance Adjustment',
    placeholder: '2',
    type: 'number',
    post: numberPostHook
  },
  fulfilmentStatus: {
    label: 'Fulfilment Status',
    placeholder: 'Fulfilled',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'fulfilled', text: 'Fulfilled' },
      { value: 'cancelled', text: 'Customer Cancelled' },
      { value: 'cantFulfil', text: "Can't Fulfil" },
      { value: 'dropped', text: 'Dropped' },
      { value: 'disputed', text: 'Disputed' },
      { value: 'refunded', text: 'Refunded' }
    ]
  },
  feedback: {
    label: 'Feedback Number',
    placeholder: '10',
    type: 'number',
    post: numberPostHook
  },
  feedbackMessage: {
    label: 'Feedback Message',
    placeholder: 'Enter message here...'
  },
  vendorShare: {
    label: 'New Vendor Share'
  },
  ourCommission: {
    label: 'Our Commission'
  },
  commissionOverride: {
    label: 'Commission Override',
    placeholder: '10',
    type: 'number',
    post: numberPostHook
  },
  isTestJob: {
    label: 'Is Test Job',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  typeSpecialNeeds: {
    label: 'Special Needs',
    placeholder: 'Select options',
    selection: true,
    fluid: true,
    multiple: true,
    search: true,
    options: [
      { value: 'dismantle-reassemble', text: 'Dismantle Reassemble' },
      { value: 'dumping-ground', text: 'Dumping Ground' },
      { value: 'fragile-item', text: 'Fragile Item' },
      { value: 'piano-job', text: 'Piano Job' },
      { value: 'building-material', text: 'Building Material' },
      { value: 'vehicle-job', text: 'Vehicle Job' },
      { value: 'house-move', text: 'House Move' }
    ]
  },
  manualAdjustment: {
    label: 'Manual Price Adjustment',
    placeholder: '0',
    type: 'number',
    post: numberPostHook
  },
  balanceReceived: {
    label: 'Onsite Amount Received',
    placeholder: '7',
    type: 'number',
    post: numberPostHook
  },
  balancePaymentMode: {
    label: 'Onsite Amount Mode',
    placeholder: 'Select options',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'stripe', text: 'Stripe' },
      { value: 'bankTransfer', text: 'Bank Transfer' },
      { value: 'directlyToVendor', text: 'Directly To Vendor' }
    ]
  },
  balancePaymentAcceptOnline: {
    label: 'Accept Onsite Amount Online',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  totalJobValue: {
    label: 'Total Job Value'
  },
  jobSummaryOnAcceptance: {
    label: 'Job Summary On Acceptance',
    readOnly: true,
    placeholder: 'Read only job summary'
  },
  nonCommissionableAmount: {
    label: 'Non Commissionable Amount',
    placeholder: '0',
    type: 'number',
    post: numberPostHook
  },
  noOfHours: {
    label: 'No Of Hours',
    pre: x => fmtTime(x * 60)
  },
  totalOurCommission: {
    label: 'Total Our Commission'
  },
  totalVendorShare: {
    label: 'Total Vendor Share'
  },
  vendorSettlementAmount: {
    label: 'Vendor Settlement Amount'
  },
  vendorSettlementStatus: {
    label: 'Vendor Settlement Status',
    placeholder: 'Select options',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'pending', text: 'Pending' },
      { value: 'paid', text: 'Paid' }
    ]
  },
  vendorSettlementNotes: {
    label: 'Vendor Settlement Notes',
    placeholder: 'Enter details here...'
  },
  vendorShareOnAcceptance: {
    label: 'Original Vendor Share'
  },
  stage: {
    label: 'Job Stage',
    placeholder: 'Booking/FMS',
    required: false,
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'booking', text: 'Booking' },
      { value: 'fms', text: 'FMS' }
    ]
  },
  moveType: {
    label: 'Move Type',
    placeholder: 'Item Move',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'itemMove', text: 'Item Move' },
      { value: 'houseMove', text: 'House Move' },
      { value: 'officeMove', text: 'Office Move' },
      { value: 'shopMove', text: 'Shop Move' },
      { value: 'pianoMove', text: 'Piano Move' }
    ]
  },
  propertyTypeAtPickup: {
    label: 'Property at Pickup',
    placeholder: 'House',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'storage', text: 'Storage' },
      { value: 'apartment', text: 'Apartment' },
      { value: 'house', text: 'House' },
      { value: 'office', text: 'Office' }
    ]
  },
  propertyTypeAtDelivery: {
    label: 'Property at Delivery',
    placeholder: 'Apartment',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: 'storage', text: 'Storage' },
      { value: 'apartment', text: 'Apartment' },
      { value: 'house', text: 'House' },
      { value: 'office', text: 'Office' }
    ]
  },
  propertyTypeHouseAtPickup: {
    label: 'House at Pickup',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  propertyTypeHouseAtDelivery: {
    label: 'House at Delivery',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  numberOfBedrooms: {
    label: 'No. of bedrooms',
    placeholder: '2',
    type: 'number',
    post: numberPostHook
  },
  officeArea: {
    label: 'Office Area',
    placeholder: '100 - 500 sq ft',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: '100 - 500 square feet', text: '100 - 500 sq ft' },
      { value: '500 - 1,000 square feet', text: '500 - 1,000 sq ft' },
      { value: '1,000 - 2,000 square feet', text: '1,000 - 2,000 sq ft' }
    ]
  },
  shopBrand: {
    label: 'Shop Brand',
    placeholder: 'Ikea'
  },
  inventoryList: {
    label: 'Inventory List',
    placeholder: '2 bedroom',
    selection: true,
    fluid: true,
    options: [
      nullOption,
      { value: '1 bedroom', text: '1 bedroom' },
      { value: '2 bedroom', text: '2 bedroom' },
      { value: '3 bedroom', text: '3 bedroom' },
      { value: '4+ bedroom', text: '4+ bedroom' },
      { value: '100 - 500 square feet', text: '100 - 500 sq ft' },
      { value: '500 - 1,000 square feet', text: '500 - 1,000 sq ft' },
      { value: '1,000 - 2,000 square feet', text: '1,000 - 2,000 sq ft' }
    ]
  },
  packingService: {
    label: 'Packing Service',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  dismantlingService: {
    label: 'Dismantling Service',
    placeholder: 'No',
    selection: true,
    fluid: true,
    options: booleanOptions
  }
};

const jobFormFormatter = ({ label, value }) => {
  const fieldConfig = { ...formConfig[label] } || {};

  return {
    label: fieldConfig.label,
    value: fieldConfig.options
      ? (
          fieldConfig.options.find(x => x.value === value) || {
            text: 'Non Standard Value!'
          }
        ).text
      : value
  };
};

const JobFormComponent = ({
  field,
  value,
  unsaved,
  onChange,
  // onBlur,
  noLabel,
  additionalConfig = {}
}) => {
  if (!formConfig[field]) return null;

  const fieldConfig = { ...formConfig[field], ...additionalConfig } || {};
  const Base = formCompsConfig[field];

  const formattedValue = fieldConfig.pre ? fieldConfig.pre(value) : value;

  const wrappedOnChange = (_, { value }) =>
    fieldConfig.post ? onChange(fieldConfig.post(value)) : onChange(value);

  const label = noLabel ? null : fieldConfig.label;

  return (
    <Base
      key={field}
      value={formattedValue}
      error={unsaved}
      onChange={wrappedOnChange}
      // onBlur={onBlur}
      label={label}
      {...R.omit(['pre', 'post'])(fieldConfig)}
    />
  );
};

JobFormComponent.formatter = jobFormFormatter;
export default JobFormComponent;
