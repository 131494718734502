import { css } from 'glamor';

export const inputStyles = css({
  '& .input-box': {
    display: 'flex',
    height: '75px',
    margin: '-1px'
  },
  '& textarea': {
    width: '100%',
    marginLeft: '-1px',
    border: '1px solid rgba(34,36,38,.15)',
    color: 'rgba(0, 0, 0, 0.87)',
    outline: '0',
    padding: '.78571429em 1em',
    transition: 'color .1s ease,border-color .1s ease',
    '&:focus': {
      borderColor: '#85B7D9'
    }
  },
  '& .ui.toggle.button': {
    height: '100%',
    margin: 0,
    borderRadius: 0,
    '& .icon': {
      transition: 'transform .3s ease'
    }
  },
  '& .ui.send.button': {
    height: '100%',
    margin: 0,
    borderRadius: 0,
    '& .icon': {
      opacity: 1
    }
  },
  '&.expanded': {
    '& .ui.toggle.button': {
      borderRadius: 0,
      '& .icon': {
        transform: 'rotate(45deg)'
      }
    },
    '& .ui.send.button': {
      borderRadius: 0
    }
  }
});

export const assistBoxStyles = css({
  '& .assist-title.ui.segment': {
    borderRadius: 0,
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    '& .ui.header': {
      margin: 0
    },
    '& .ui.dropdown': {
      marginLeft: '1rem'
    }
  },
  '& .assist-body': {
    borderBottom: 'none',
    padding: '0.5rem',
    paddingBottom: '0',
    overflow: 'none',
    height: '135px'
  }
});
