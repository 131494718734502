import React from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import styled from 'styled-components';

import { TAB_HEIGHT, CHAT_INPUT_HEIGHT } from 'utils/constants';

import Message from './Message';
import Input from './Input';
import TypingIndicator from './TypingIndicator';

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - ${CHAT_INPUT_HEIGHT} - ${TAB_HEIGHT} - 1rem);
`;

const StyledScrollableFeed = styled(ScrollableFeed)`
  height: 100% !important;
  padding: 1rem 1rem 0rem 1rem;
`;

const BookingChat = ({
  messages = [],
  onSend,
  body,
  setBody,
  isTyping,
  setActive,
  inputRef,
  copyToInput
}) => {
  return (
    <Base>
      <Body>
        <StyledScrollableFeed forceScroll>
          {messages.map((msg, i) => (
            <Message key={i} {...msg} copyToInput={copyToInput} />
          ))}
        </StyledScrollableFeed>
        {isTyping && <TypingIndicator />}
      </Body>
      <Input
        onSend={onSend}
        value={body}
        setValue={setBody}
        inputRef={inputRef}
        onClick={setActive}
      />
    </Base>
  );
};

export default BookingChat;
