import * as R from 'ramda';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import useDynamicRef from 'use-dynamic-refs';
import { useImmerReducer } from 'use-immer';
import logger from 'utils/logger';
// import { Button, Icon } from 'semantic-ui-react';

import { client } from 'api';
import imgSrc from 'assets/images/billboard.png';
import { DefaultBase, EmptyState, Navbar, Text } from 'components/v2';
import { AppContext } from 'store/context';
import sounds from 'utils/sounds';
import { CloseModal, JobLabel, JobTab, Troubleshoot } from './components';
import { chatReducer, jobReducer } from './reducers';

const log = logger({ module: 'booking' });
const hLog = logger({ module: 'hotkey' });

const Booking = () => {
  const { user, handleEvents } = useContext(AppContext);
  const [jobs, jobDispatch] = useImmerReducer(jobReducer, {});
  const [chats, chatDispatch] = useImmerReducer(chatReducer, {});
  const [activeJobId, setActiveJobIdBase] = useState(null);
  const [updating, _setUpdating] = useState(false);
  const [getRef, setRef] = useDynamicRef();
  const [closeJobId, setCloseJobId] = useState(null);

  const operatorType = user.role.name;

  const setActiveJobId = (jobId, channelId) => {
    setActiveJobIdBase(jobId);
    if (channelId) {
      chatDispatch({ type: 'read', channelId });
    }
  };

  const handleSetBody = channelId => body => {
    chatDispatch({ type: 'setBody', channelId, data: body });
  };

  const handleSend =
    sessionId =>
    (body, attrs = {}) => {
      client.service('/customers/chat/messages').create({
        from: 'operator',
        body,
        sessionId,
        ...attrs
      });
    };

  const handleJobUpdate = (jobId, sessionId) => async data => {
    // setUpdating(true);
    jobDispatch({ type: 'patch', jobId, data: { data }, patchStyle: 'merge' });

    const job = jobs[jobId];
    const keys = Object.keys(data);
    const key = keys[0];
    const dataExists = job.data[key] !== undefined && job.data[key] !== null;
    const statelessEdit =
      key === 'internalNotes' || key === 'monitorMode' || key === 'activeTab';
    const canTrigger = !dataExists && !statelessEdit;

    await client.service('/jobs').patch(jobId, data);

    // send data via tree trigger so typeD can run on it, etc
    if (canTrigger) {
      await handleTriggerTree({ sessionId })({ newJd: data });
    }
    // setUpdating(false);
    return;
  };

  const handleTriggerTree =
    ({ sessionId, channelId, channel }) =>
    async ({ from = 'externalTrigger', trigger = 'dashboard', newJd }) => {
      await client.service('/customers/chat/messages').create({
        from,
        trigger,
        sessionId,
        channelId,
        channel,
        attributes: {
          toSend: {
            newJd
          }
        }
      });
      return;
    };

  const handleContextUpdate = sessionId => async chatContext => {
    await client.service('/customers/updateSessions').create({
      sessionId,
      chatContext
    });
    return;
  };

  const handleCloseJob = async jobId => {
    await client.service('/jobs').patch(jobId, { activeTab: false });

    jobDispatch({ type: 'delete', jobId });
    chatDispatch({ type: 'delete', jobId });

    if (jobId === activeJobId) {
      setActiveJobId(null);
    }

    setCloseJobId(null);
  };

  const handleOsl = jobId => async oslType => {
    jobDispatch({ type: 'delete', jobId });
    chatDispatch({ type: 'delete', jobId });

    if (jobId === activeJobId) {
      setActiveJobId(null);
    }

    await client.service('/operators/osl').create({ jobId, flag: oslType });
  };

  const handleDeleteFlag = channelId => async flagId => {
    chatDispatch({ type: 'deleteFlag', channelId, flagId });
    await client.service('/db/messages').patch(flagId, { flagged: false });
  };

  const handleSetActiveFlag = channelId => flagId => {
    chatDispatch({ type: 'setActiveFlag', channelId, flagId });
  };

  const handleSetActive = index => {
    return () => {
      const targetJob = jobs[index];
      if (targetJob) {
        setActiveJobId(targetJob.jobId);
      }
    };
  };

  const handleFocusInput = () => {
    const ref = getRef(activeJobId);
    ref.current.focus();
  };

  const copyToInput = channelId => body => {
    chatDispatch({ type: 'setBody', channelId, data: body });
    handleFocusInput();
  };

  const hotKeyBundle = {
    SET_ACTIVE_1: { sequence: 'alt+1', handler: handleSetActive(0) },
    SET_ACTIVE_2: { sequence: 'alt+2', handler: handleSetActive(1) },
    SET_ACTIVE_3: { sequence: 'alt+3', handler: handleSetActive(2) },
    SET_ACTIVE_4: { sequence: 'alt+4', handler: handleSetActive(3) },
    SET_ACTIVE_5: { sequence: 'alt+5', handler: handleSetActive(4) },
    FOCUS_INPUT: { sequence: 'alt+enter', handler: handleFocusInput }
  };

  const hotKeyHelper = obj => {
    const wrapper = cmd => e => {
      hLog.info(cmd.sequence);
      return cmd.handler(e);
    };
    const keyMap = R.map(R.prop('sequence'))(obj);
    const handlers = R.map(wrapper)(obj);

    return {
      keyMap,
      handlers
    };
  };

  const hK = hotKeyHelper(hotKeyBundle);

  const subscribe = useCallback(async () => {
    client
      .service('/operators/osl')
      .on('created', async ({ jobId, channelId, channel }) => {
        log.info(`${jobId} assigned`);

        const [job, messages] = await Promise.all([
          client.service('/jobs').get(jobId),
          client
            .service('/db/messages')
            .find({ query: { channelId } })
            .then(res => res.data)
        ]);

        handleEvents({
          action: 'join',
          channels: [`/chat/${channelId}`]
        });

        jobDispatch({
          type: 'create',
          jobId,
          data: {
            data: job,
            channelId
          }
        });

        chatDispatch({
          type: 'create',
          channelId,
          data: {
            channelId,
            jobId,
            sessionId: job.sessionId,
            channel,
            messages: [],
            body: '',
            flags: [],
            activeFlagId: null,
            potentialUnread: false
          }
        });

        chatDispatch({
          type: 'messageHistory',
          channelId,
          data: messages
        });

        log.info(`${jobId} added`);

        await client.service('/jobs').patch(jobId, { activeTab: true });
        try {
          sounds.newJob.play();
        } catch (error) {
          log.error(error.message);
        }
      });

    client.service('/jobs').on('patched', ({ job }) => {
      const { jobId } = job;
      jobDispatch({
        type: 'patch',
        jobId,
        data: { data: job },
        patchStyle: 'merge'
      });
      log.info(`${jobId} patched`);
    });

    client.service('/db/messages').on('created', res => {
      const { channelId } = res;

      chatDispatch({
        type: 'newMessage',
        channelId,
        data: res
      });

      if (res.flagged === true) {
        try {
          sounds.newFlag.play();
        } catch (error) {
          log.error(error.message);
        }
      }

      if (res.author === 'customer' || res.author === 'meta') {
        chatDispatch({ type: 'unread', channelId });
      }

      if (res.author === 'customer') {
        chatDispatch({
          type: 'muteTimer',
          channelId,
          data: {
            callback: () => {
              handleTriggerTree({ channelId, channel: res.channel })({
                trigger: 'customerMute'
              });
            }
          }
        });
      }
    });

    client.service('/db/messages').on('patched', res => {
      const { _id, channelId } = res;
      chatDispatch({ type: 'patch', _id, data: res, channelId });
    });

    log.info('✅ booking updates');
  }, []);

  const unsubscribe = () => {
    log.info('⛔ booking updates');
    handleEvents({
      action: 'leave',
      channels: R.pipe(
        R.keys,
        R.map(x => `/chat/${x}`)
      )(chats)
    });
    client.service('/operators/osl').off('created');
    client.service('/jobs').off('patched');
    client.service('/db/messages').off('created');
  };

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  return (
    <GlobalHotKeys
      style={{ width: '100vw' }}
      allowChanges
      keyMap={hK.keyMap}
      handlers={hK.handlers}
    >
      <DefaultBase>
        <Navbar
          children={
            <>
              <Text fontSize="14px">
                <strong>Bookings</strong>
              </Text>
              {Object.keys(jobs).map(jobId => {
                const job = jobs[jobId] || {};
                const chat = chats[job.channelId] || {};
                return (
                  <JobLabel
                    key={jobId}
                    jobId={jobId}
                    active={activeJobId === jobId}
                    flags={chat.flags}
                    channel={chat.channel}
                    potentialUnread={chat.potentialUnread}
                    isTestJob={job.data.isTestJob}
                    onActive={() => setActiveJobId(jobId, chat.channelId)}
                    onClose={() => setCloseJobId(jobId, chat.channelId)}
                  />
                );
              })}
            </>
          }
          rightChildren={<Troubleshoot />}
        />
        {Object.keys(jobs).map(jobId => {
          const job = jobs[jobId] || {};
          const channelId = job.channelId;
          const sessionId = job.data.sessionId;
          const chat = chats[channelId] || {};
          const activeFlag =
            chat.activeFlagId &&
            R.pipe(
              R.find(m => m._id === chat.activeFlagId),
              R.path(['attributes', 'treeMetadata', 'flags'])
            )(chat.messages || []);

          return (
            <JobTab
              key={jobId}
              active={activeJobId === jobId}
              jobId={jobId}
              job={job.data}
              messages={chat.messages}
              body={chat.body}
              setBody={handleSetBody(channelId)}
              copyToInput={copyToInput(channelId)}
              onSend={handleSend(sessionId)}
              inputRef={setRef(jobId)}
              onUpdate={handleJobUpdate(jobId, sessionId)}
              handleTriggerTree={handleTriggerTree({ sessionId })}
              handleContextUpdate={handleContextUpdate(sessionId)}
              updating={updating}
              operatorType={operatorType}
              handleOsl={handleOsl(jobId)}
              activeFlag={activeFlag}
              handleSetActiveFlag={handleSetActiveFlag(channelId)}
              handleDeleteFlag={() =>
                handleDeleteFlag(channelId)(chat.activeFlagId)
              }
            />
          );
        })}
        {!activeJobId && (
          <EmptyState
            title="All Jobs Booked"
            subtitle="Please open an assigned job or remain active until one is assigned to you"
            imgSrc={imgSrc}
            imgSize="250px"
          />
        )}
      </DefaultBase>
      <CloseModal
        jobId={closeJobId}
        open={!!closeJobId}
        handleCancel={() => setCloseJobId(null)}
        handleContinue={() => handleCloseJob(closeJobId)}
      />
    </GlobalHotKeys>
  );
};

export default Booking;
