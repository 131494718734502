import React from 'react';
import * as R from 'ramda';

import styled from 'styled-components';
import { Form, Divider, Dropdown } from 'semantic-ui-react';

const optionConvertor = ({ value, text }) => ({
  key: text || value,
  text: text || value,
  value
});

const booleanOptions = [
  { value: undefined, text: 'Empty' },
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
];

const anyAllOptions = [
  { value: 'any', text: 'Any ✨' },
  { value: 'none', text: 'None 💥' }
];

const comps = {
  multiselect: ({ options, ...props }) => (
    <Form.Dropdown
      search
      selection
      multiple
      placeholder="Select Options"
      options={[...anyAllOptions, ...options].map(optionConvertor)}
      {...props}
    />
  ),
  boolean: props => (
    <Form.Dropdown
      selection
      placeholder="Yes / No"
      options={booleanOptions.map(optionConvertor)}
      {...props}
    />
  )
};

export const filterApply = ({ data, filters }) => {
  const pred = {};
  Object.keys(filters).forEach(key => {
    const val = filters[key];

    switch (R.type(val)) {
      case 'Boolean':
        pred[key] = x => (val ? !!x : !x);
        break;
      case 'Array':
        pred[key] = x => {
          if (val.includes('any')) {
            return !!x;
          } else if (val.includes('none')) {
            return !x;
          }
          return val.length ? val.includes(x) : true;
        };
        break;
      default:
        break;
    }
  });

  return R.filter(R.where(pred))(data);
};

const PresetItem = styled(Dropdown.Item)`
  font-size: 12px !important;
  font-weight: 700 !important;
`;

const Filter = ({ fields, presets, filters, setFilters }) => {
  return (
    <div>
      <Dropdown fluid text="Presets">
        <Dropdown.Menu>
          {presets.map(p => (
            <PresetItem
              key={p.key}
              content={p.key}
              onClick={() => setFilters(p.filters)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Divider />
      <Form size="tiny">
        {fields.map(f => {
          const Comp = comps[f.type];
          const onChange = (_, { value }) =>
            setFilters(prev => ({ ...prev, [f.key]: value }));
          return (
            <Comp
              key={f.key}
              onChange={onChange}
              {...f.props}
              value={filters[f.key]}
            />
          );
        })}
      </Form>
    </div>
  );
};

export default Filter;
