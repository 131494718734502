import React, { useState } from 'react';
import styled from 'styled-components';
import { utc as moment } from 'moment';

import { Button, Icon, Label } from 'semantic-ui-react';
import { Preloader } from 'components/v1';
import { Table } from 'components/v2';
import ActionModal from './ActionModal';
import client from 'api/client';

const fieldsData = [
  { label: 'Job Id', target: 'jobId' },
  {
    label: 'Ready For Chaining',
    target: 'readyForChaining',
    hook: x => (x ? '✅' : '⛔')
  },
  {
    label: 'Booking Status',
    target: 'bookingStatus',
    hook: x => (x === 'confirmed' ? '' : x)
  },
  {
    label: 'Chaining Status',
    target: 'chainingStatus',
    hook: x => (x !== 'solo' ? '' : x)
  },
  {
    label: 'Fulfilment Status',
    target: 'fulfilmentStatus'
  },
  {
    label: 'Missing JD',
    target: 'missingJd'
  }
];

const normalize = x => {
  const jdFlags = [];

  if (!x.postcodesInfo) jdFlags.push('postcodes');
  if (!x.inventoryInfo) jdFlags.push('inventory');
  if (!x.menInfo) jdFlags.push('men');
  if (!x.dateInfo) jdFlags.push('date');
  if (!x.floorsLiftsInfo) jdFlags.push('floors/lifts');

  return {
    jobId: x.jobId,
    error: !x.readyForChaining,
    readyForChaining: x.readyForChaining,
    bookingStatus: x.bookingStatus,
    chainingStatus: x.chainingStatus,
    fulfilmentStatus: x.fulfilmentStatus,
    missingJd: jdFlags.join(', ')
  };
};

const Base = styled.div`
  padding: 1rem 0;
  height: 100%;
`;

const Top = styled.div`
  height: 5rem;
`;

const Bottom = styled.div`
  height: calc(100% - 5rem);
`;

const Right = styled.span`
  float: right;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem !important;

  & i {
    opacity: 1 !important;
  }
`;

const StyledLabel = styled(Label)`
  margin-right: 1rem !important;
`;

const Creation = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const daysToFf = 1;

  const ff = moment().add(daysToFf, 'd');
  const date = ff.format('YYYY-MM-DD');
  const dateAlt = ff.format('DD MMM');

  const handlePreview = async () => {
    setLoading(true);
    const res = await client
      .service('/routes/preview')
      .find({
        query: {
          fulfilmentDateStart: date,
          fulfilmentDateEnd: date
        }
      })
      .then(res => res.data);

    const jobs = res.map(normalize);
    setJobs(jobs);
    setLoading(false);
  };

  const handleCreation = () => {
    setModal(false);
    client.service('/routes/create').create({
      daysToFf
    });
  };

  const totalJobs = jobs.length;
  const ready = jobs.filter(x => x.readyForChaining).length;
  const notReady = totalJobs - ready;

  return (
    <Base>
      {loading && <Preloader inverted content="Loading data..." />}
      <Top>
        <StyledButton color="green" onClick={handlePreview}>
          <Icon name="eye" />
          Preview
        </StyledButton>

        <StyledButton color="green" onClick={() => setModal(true)}>
          <Icon name="plus" />
          Create
        </StyledButton>

        <Right>
          <StyledLabel basic size="large" content="Date" detail={dateAlt} />
          <StyledLabel
            basic
            size="large"
            content="Total Jobs"
            detail={totalJobs}
          />
          <StyledLabel basic size="large" content="Ready" detail={ready} />
          <StyledLabel
            basic
            size="large"
            content="Not Ready"
            detail={notReady}
          />
        </Right>
      </Top>

      <Bottom>
        <Table tableData={jobs} fieldsData={fieldsData} rowIdentifier="jobId" />
      </Bottom>

      {modal && (
        <ActionModal
          action="create"
          onCancel={() => setModal(false)}
          onContinue={() => handleCreation()}
        />
      )}
    </Base>
  );
};

export default Creation;
