import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

import { MENU_WIDTH } from 'utils/constants';
import JobLabel from './JobLabel';
import CloseModal from './CloseModal';
import Chat from '../BookingChat';
import Form from './Form';

const border = '1px solid #828282';

const Base = styled.div`
  width: calc((100% - ${MENU_WIDTH} + 1rem) * 0.33);
  display: ${props => (props.minimized ? 'none' : '')};
`;

const HeaderBase = styled.div`
  background: #e0e0e0;
  border-bottom: ${border};
  border-right: ${border};
  height: 50px;
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;
`;

const BodyBase = styled.div`
  border-right: ${border};
  background: #fff;
  height: calc(100% - 50px);
`;

const BodyInner = styled.div`
  height: 100%;
  display: ${props => (props.visible ? '' : 'none')};
`;

const WindowButtons = styled.span`
  position: absolute;
  right: 1rem;
`;

const TabButtons = styled(Button.Group)`
  margin-left: 1rem !important;
  & > .button {
    padding: 0.4rem 0.7rem;
    height: 1.8rem;
  }
`;

const TreeButtons = styled.span`
  margin-left: 1rem !important;
  & > .button .corner.icon {
    text-shadow: -1px -1px 0 #e0e0e0 !important;
  }
`;

const BookingJobTab = ({
  jobId,
  unread,
  active,
  setActive,
  pane,
  setActivePane,
  minimized,
  handleMinimize,
  handleClose,
  updating,
  handleJobUpdate,
  job,
  messages,
  onSend,
  body,
  setBody,
  isTyping,
  inputRef,
  copyToInput,
  handleTreeStatus
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleContinue = () => {
    setModalOpen(false);
    handleClose();
  };

  return (
    <Base minimized={minimized}>
      <HeaderBase>
        <JobLabel
          jobId={jobId}
          loading={updating}
          unread={unread}
          active={active}
        />
        <TabButtons basic size="small">
          <Button
            active={pane === 'chat'}
            onClick={() => setActivePane({ jobId, pane: 'chat' })}
          >
            Chat
          </Button>
          <Button
            active={pane === 'job'}
            onClick={() => setActivePane({ jobId, pane: 'job' })}
          >
            Job
          </Button>
        </TabButtons>
        <TreeButtons>
          <Button icon onClick={() => handleTreeStatus(false)}>
            <Icon.Group>
              <Icon name="tree" />
              <Icon corner name="stop" />
            </Icon.Group>
          </Button>
          <Button icon onClick={() => handleTreeStatus(true)}>
            <Icon.Group>
              <Icon name="tree" />
              <Icon corner name="play" />
            </Icon.Group>
          </Button>
        </TreeButtons>
        <WindowButtons>
          <Icon link name="minus" onClick={handleMinimize} />
          <Icon link name="times" onClick={() => setModalOpen(true)} />
        </WindowButtons>
      </HeaderBase>
      <BodyBase>
        <BodyInner visible={pane === 'chat'}>
          <Chat
            messages={messages}
            onSend={onSend}
            isTyping={isTyping}
            setActive={setActive}
            inputRef={inputRef}
            copyToInput={copyToInput}
            body={body}
            setBody={setBody}
          />
        </BodyInner>
        <BodyInner visible={pane === 'job'}>
          <Form
            jobId={jobId}
            job={job}
            onUpdate={handleJobUpdate}
            loading={updating}
          />
        </BodyInner>
        <CloseModal
          jobId={jobId}
          open={modalOpen}
          handleCancel={handleCancel}
          handleContinue={handleContinue}
        />
      </BodyBase>
    </Base>
  );
};

export default BookingJobTab;
