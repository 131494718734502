import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import { Button } from 'semantic-ui-react';
import { EmptyState } from 'components/v2';
import RouteCard from './RouteCard';
import Waypoint from './Waypoint';
import Map from './Map';
import ActionModal from './ActionModal';

import { normalizeRoutes } from '../helpers';
import client from 'api/client';
import imgSrc from 'assets/images/outdoor_billboard.jpg';

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Left = styled.div`
  height: 100%;
  width: calc(100% - 350px);
`;

const Right = styled.div`
  padding: 2rem;
  height: 100%;
  width: 350px;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  z-index: 999;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Controls = styled.div`
  margin-bottom: 2rem;
`;

const RouteView = ({ waypoints, drivers, jobs }) => {
  const [active, setActive] = useState(null);
  const [action, setAction] = useState(null);

  const driverIds = Object.keys(waypoints);
  const routes = useMemo(
    () => driverIds.length && normalizeRoutes({ waypoints, drivers, jobs })
  );

  if (!driverIds.length) {
    return (
      <EmptyState
        title="Create New Route"
        subtitle="Create lowest emission green route"
        imgSrc={imgSrc}
        imgSize="300px"
      />
    );
  }
  const route = routes.find(x => x._id === active);
  const notDispatched = !waypoints[driverIds[0]][0].taskId;

  const handleAction = () => {
    setAction(null);
    const routeId = waypoints[driverIds[0]][0].routeId;
    if (action === 'discard') {
      client.service('/routes/waypoints/').remove(routeId);
    } else if (action === 'dispatch') {
      driverIds.map(driverId => {
        client.service('/routes/dispatch').create({
          routeId,
          driverId
        });
      });
    }
  };

  return (
    <Base>
      <Left>
        <Map routes={active ? [route] : routes} uniform={!active} />
      </Left>
      <Right>
        {action && (
          <ActionModal
            open
            action={action}
            onCancel={() => setAction(null)}
            onContinue={handleAction}
          />
        )}
        {!active ? (
          <>
            {notDispatched && (
              <Controls>
                <Button
                  icon="send"
                  content="Dispatch"
                  onClick={() => setAction('dispatch')}
                />
                <Button
                  icon="delete"
                  content="Discard"
                  onClick={() => setAction('discard')}
                />
              </Controls>
            )}
            {routes.map(x => (
              <RouteCard key={x._id} onClick={() => setActive(x._id)} {...x} />
            ))}
          </>
        ) : (
          <>
            <RouteCard
              onClick={() => setActive(null)}
              {...route}
              direction="left"
            />
            <br />
            {route.waypoints.map(x => (
              <Waypoint key={x._id} {...x} color={route.color} />
            ))}
          </>
        )}
      </Right>
    </Base>
  );
};

export default RouteView;
