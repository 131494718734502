import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, Icon, Popup, Divider } from 'semantic-ui-react';

import { client } from 'api';
import { AppContext } from 'store/context';
import { MENU_WIDTH } from 'utils/constants';

const Base = styled.div`
  & > .nav-menu.menu {
    width: ${MENU_WIDTH};
    border-radius: 0;
    margin: 0;
    height: 100vh;
  }
`;

const Item = styled(Menu.Item)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed !important;
  bottom: ${props => props.bottom};
  width: ${MENU_WIDTH};

  & > i.icon {
    margin: 0 !important;
    float: none !important;
  }
`;

const External = styled.div`
  padding-top: 4rem;
`;

const NavMenu = ({ children }) => {
  const { handleLogout, user = {}, navigation = {} } = useContext(AppContext);

  const handleStatus = () => {
    const status = user.status === 'online' ? 'away' : 'online';
    client.service('/operators').patch(user._id, { status });
  };

  return (
    <Base>
      <Menu inverted vertical color="blue" className="nav-menu">
        <Popup
          basic
          on="click"
          style={{ left: '20px' }}
          trigger={
            <Item>
              <Icon name="sidebar" />
            </Item>
          }
          content={
            <Menu vertical secondary>
              {(navigation.allPermitted || []).map(({ key, title, icon }) => (
                <Menu.Item as={NavLink} key={key} to={key}>
                  {title}
                  <Icon name={icon} />
                </Menu.Item>
              ))}
            </Menu>
          }
        />
        <External>{children}</External>
        <Popup
          basic
          on="click"
          style={{ left: '20px' }}
          trigger={
            <Item bottom={0}>
              <Icon.Group>
                <Icon name="user" />
                <Icon
                  corner
                  name="circle"
                  color={user.status === 'online' ? 'green' : 'yellow'}
                />
              </Icon.Group>
            </Item>
          }
          content={
            <Menu vertical secondary>
              <Menu.Item header>{user.email}</Menu.Item>
              <Divider />
              <Menu.Item onClick={handleStatus}>
                Set as {user.status === 'online' ? 'Away' : 'Active'}
                <Icon name={user.status === 'online' ? 'bell slash' : 'bell'} />
              </Menu.Item>
              <Menu.Item onClick={handleLogout}>
                Logout
                <Icon name="power" />
              </Menu.Item>
            </Menu>
          }
        />
      </Menu>
    </Base>
  );
};

export default NavMenu;
