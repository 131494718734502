import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { flexbox } from 'styled-system';

import { Label, List, Dropdown, Divider, Form, Icon } from 'semantic-ui-react';
import { JobFormComponent } from 'components/v2';
import { wds, statusOptions, iconNameMap, formatArtifacts } from './helpers';
import { formatTime } from 'utils';

const TaskGroupBase = styled.div`
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  min-height: 44px;

  ${props =>
    props.active &&
    css`
      border-top: 3px solid #21ba45;
    `};
  filter: grayscale(${props => (props.isTestJob ? '100%' : '0%')});
`;

const TaskGroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
`;

const Flex = styled.div`
  display: flex;
  ${flexbox}
`;

const TaskGroupExtention = styled.div``;

const StyledListItem = styled(List.Item)`
  display: flex !important;
  &:not(:first-of-type) {
    padding-top: 1rem !important;
  }

  & .header {
    text-transform: capitalize;
  }
`;

const IconContainer = styled.span`
  & i {
    margin-right: ${props => props.marginRight} !important;
  }
`;

const StyledForm = styled(Form)`
  padding: 0.5rem 1rem 0.5rem 0rem !important;
`;

const BottomRightContent = styled(List.Content)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 0.5rem;
`;

const Meta = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: normal;
  margin-left: 0.5rem;
`;

const TaskRow = ({ task, onTaskUpdate }) => {
  const [state, setState] = useState(null);

  const { status, createdAt } = task;
  const { flag = {}, content } = task.attributes;
  let label, value, type;

  if (flag.type === 'entity') {
    const formatted = JobFormComponent.formatter({
      label: flag.label,
      value: flag.value
    });
    label = formatted.label;
    value = formatted.value;
    type = flag.type;
  } else {
    label = formatArtifacts(flag.label || task.type || '');
    value = flag.value || flag.message || content;
    type = flag.type || 'general';
  }

  value = state || value;

  const onUpdate = (_, x) => {
    let flagAction = {};
    if (type === 'general') {
      // do nothing
    } else if (type === 'entity') {
      flagAction = { field: flag.label, value };
    } else {
      flagAction = { channels: flag.channels, value };
    }
    onTaskUpdate(x.value, flagAction);
  };

  return (
    <StyledListItem>
      <Icon name={iconNameMap[type]} color="grey" />
      <List.Content>
        <List.Header>
          {label}
          <Meta>{formatTime(createdAt)}</Meta>
        </List.Header>
        <StyledForm>
          {type === 'general' ? (
            <List.Description>{value}</List.Description>
          ) : type === 'entity' ? (
            <JobFormComponent
              field={label}
              label={label}
              value={value}
              onChange={setState}
              noLabel
            />
          ) : (
            <Form.TextArea
              value={value}
              onChange={(_, x) => setState(x.value)}
            />
          )}
        </StyledForm>
      </List.Content>
      <BottomRightContent>
        <Dropdown
          selection
          compact
          size="small"
          options={statusOptions}
          value={status}
          onChange={onUpdate}
        />
      </BottomRightContent>
    </StyledListItem>
  );
};

const TaskGroup = ({
  jobId,
  labels,
  tasks,
  expanded,
  setActive,
  toggleLocalExpanded,
  active,
  onTaskUpdate,
  isTestJob
}) => {
  return (
    <TaskGroupBase active={active} isTestJob={isTestJob}>
      <TaskGroupHeader>
        <Flex flex={wds[0]}>
          {isTestJob ? (
            // <Label size="small" color="grey" basic>
            //   <Icon name="bug" />#{jobId}
            // </Label>
            <span>#{jobId}</span>
          ) : (
            <strong>#{jobId} 🚨</strong>
          )}
        </Flex>
        <Flex flex={wds[1]}>
          <Label {...labels.status} size="small" />
        </Flex>
        <Flex flex={wds[2]}>
          <Label {...labels.fulfilment} size="small" />
        </Flex>
        <Flex flex={wds[3]}>
          <Label {...labels.tasks} size="small" />
        </Flex>
        <Flex flex={wds[4]} justifyContent="flex-end">
          <IconContainer marginRight="1rem">
            <Icon color="grey" link name="external" onClick={setActive} />
          </IconContainer>
          <Icon
            link
            color="grey"
            name={expanded ? 'chevron down' : 'chevron right'}
            onClick={toggleLocalExpanded}
          />
        </Flex>
      </TaskGroupHeader>

      {expanded && (
        <TaskGroupExtention>
          <Divider />
          <List divided relaxed>
            {tasks.map(task => (
              <TaskRow
                key={task._id}
                task={task}
                onTaskUpdate={(status, data) =>
                  onTaskUpdate({ _id: task._id, status }, data)
                }
              />
            ))}
          </List>
        </TaskGroupExtention>
      )}
    </TaskGroupBase>
  );
};

export default TaskGroup;
