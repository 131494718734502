import { useState } from 'react';
import { Button, Progress } from 'semantic-ui-react';
import styled from 'styled-components';

import imgSrc from 'assets/images/van.png';
import { EmptyState, SearchBox } from 'components/v2';
import { ACTION_HEADER_HEIGHT } from 'utils/constants';
import { CloseModal, DecisionBox } from './index';

const Base = styled.div`
  height: 100%;
`;

const HeaderBase = styled.div`
  display: flex;
  padding: 1rem;
  height: ${ACTION_HEADER_HEIGHT};
  border-bottom: 1px solid #e9e9ea;
`;

const ProgressBase = styled.div`
  flex: 1;
`;

const ButtonBase = styled.div`
  & > .button {
    margin-left: 1rem;
    margin-right: 0;
  }
`;

const LabelBase = styled.div`
  display: flow-root;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.8);
`;

const LeftFloat = styled.span`
  float: left;
`;

const RightFloat = styled.div`
  float: right;
  & > .secondary {
    color: rgba(0, 0, 0, 0.6);
  }
`;

const treeStage = j => {
  let stage = 'Pre Inventory';
  let progress = 0;

  const has = x => x !== undefined;

  if (
    has(j.inventory) &&
    !(has(j.quoteOption) && has(j.pickupFloors) && has(j.deliveryFloors))
  ) {
    stage = 'Pre Price';
    progress = 33;
  }

  if (
    has(j.quoteOption) &&
    has(j.pickupFloors) &&
    has(j.deliveryFloors) &&
    !has(j.priceAcceptance)
  ) {
    stage = 'Pre Acceptance';
    progress = 50;
  }

  if (has(j.priceAcceptance) && !(has(j.number) || has(j.email))) {
    stage = 'Pre Contact';
    progress = 75;
  }

  if (has(j.priceAcceptance) && !has(j.pickupTimeFrom)) {
    stage = 'Pickup Time';
    progress = 90;
  }

  if (
    has(j.priceAcceptance) &&
    has(j.pickupTimeFrom) &&
    (has(j.number) || has(j.email))
  ) {
    stage = 'Finished';
    progress = 100;
  }

  return {
    stage,
    progress
  };
};

const ActionCenter = ({
  job,
  onSend,
  onUpdate,
  handleTriggerTree,
  handleContextUpdate,
  operatorType,
  handleOsl,
  copyToInput,
  activeFlag,
  handleDeleteFlag
}) => {
  const [oslModal, setOslModal] = useState(false);
  const { stage, progress } = treeStage(job);

  const { monitorMode } = job;

  const setTreeStatus = status => {
    onUpdate({
      monitorMode: status
    });
  };

  const oslType = operatorType === 'manager' ? 'resolve' : 'escalate';

  return (
    <Base>
      <HeaderBase>
        <ProgressBase>
          <LabelBase>
            <LeftFloat>Current Stage:</LeftFloat>
            <RightFloat>
              <span>{stage} </span>
              <span className="secondary">/ {progress}%</span>
            </RightFloat>
          </LabelBase>
          <Progress percent={progress} size="tiny" color="green" />
        </ProgressBase>
        <ButtonBase>
          <Button
            basic={monitorMode ? false : true}
            color={monitorMode ? 'red' : null}
            icon="shield"
            onClick={() => setTreeStatus(monitorMode ? false : true)}
          />
          <Button basic icon="lightning" onClick={handleTriggerTree} />
          {operatorType === 'manager' ? (
            <Button basic icon="check" onClick={() => setOslModal(true)} />
          ) : (
            <Button basic icon="warning" onClick={() => setOslModal(true)} />
          )}
        </ButtonBase>
      </HeaderBase>
      {activeFlag ? (
        <DecisionBox
          data={activeFlag}
          copyToInput={copyToInput}
          onSend={onSend}
          handleContextUpdate={handleContextUpdate}
          onUpdate={onUpdate}
          handleDeleteFlag={handleDeleteFlag}
        />
      ) : (
        <EmptyState
          title="All Tasks Done"
          subtitle="New tasks will appear here as they get generated"
          imgSrc={imgSrc}
          imgSize="300px"
          action={<SearchBox onSelect={copyToInput} />}
        />
      )}
      <CloseModal
        action={oslType}
        jobId={job.jobId}
        open={oslModal}
        handleCancel={() => setOslModal(false)}
        handleContinue={() => handleOsl(oslType)}
      />
    </Base>
  );
};

export default ActionCenter;
