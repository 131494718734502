import React, { Component } from 'react';
import { Header, Dropdown } from 'semantic-ui-react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import styles from './styles';

const combineData = (hub, config) =>
  R.map(channel =>
    R.assoc('contact', R.path(['contact', channel.handle], hub), channel)
  )(config);

const getDefaultChannel = config =>
  R.find(R.propEq('default', true), config) || R.head(config);

class CommHubHeader extends Component {
  state = { channel: {}, config: [] };

  updateState = props => {
    const { hub, channelConfig = [] } = props;
    const config = combineData(hub, channelConfig);
    const channel = getDefaultChannel(config);
    this.setChannel({ config, channel });
  };

  UNSAFE_componentWillMount = () => this.updateState(this.props);

  setChannel = ({ channel, config }) => {
    this.props.updateChannel(channel);
    config ? this.setState({ channel, config }) : this.setState({ channel });
  };

  render() {
    const { channel, config } = this.state;

    return (
      <div {...styles}>
        <Dropdown scrolling floating icon="ellipsis vertical">
          <Dropdown.Menu>
            <Dropdown.Header icon="comments" content="Available Channels" />
            {config.map(channel => (
              <Dropdown.Item
                key={channel.id}
                text={channel.title}
                description={channel.contact}
                disabled={channel.disabled}
                onClick={() => this.setChannel({ channel })}
              />
            ))}
            <Dropdown.Divider />
            <Dropdown.Item icon="edit" text="Edit Hub" disabled />
          </Dropdown.Menu>
        </Dropdown>
        <Header as="span" className="channel" content={channel.title} />
        <span className="contact">{channel.contact}</span>
      </div>
    );
  }
}

CommHubHeader.propTypes = {
  hub: PropTypes.object.isRequired,
  channelConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateChannel: PropTypes.func.isRequired
};

export default CommHubHeader;
