import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const Preloader = ({ content, inverted }) => (
  <Dimmer active inverted={inverted}>
    <Loader size="large" content={content} />
  </Dimmer>
);

export default Preloader;
