import React from 'react';
import { render } from 'react-dom';
import { unregister } from 'registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import 'react-table/react-table.css';
import './global.css';
import { Root } from 'containers';

render(<Root />, document.getElementById('root'));
unregister();
