import { combineReducers } from 'redux';
import reduxifyServices from 'feathers-redux';
import reduxifyAuth from 'feathers-reduxify-authentication';
import * as R from 'ramda';
import client from 'api/client';

const serviceMap = {
  tasks: 'tasks',
  jobs: 'jobs',
  vendors: 'vendors',
  'db/users': 'users',
  'db/config': 'config',
  'comm/messages': 'messages',
  'comm/hub': 'commHub',
  '/airtable/:base/:table': 'airtable',
  'trees/compile': 'tree',
  'fmsChat/:jobId': 'fmsChatChannels',
  'fmsChat/:jobId/messages': 'fmsChatMessages'
};

const auth = reduxifyAuth(client, {
  token: 'accessToken'
});

export const rawServices = {
  ...reduxifyServices(client, serviceMap),
  auth
};

const feathersServices = R.map(x => x.reducer, rawServices);

export default combineReducers({
  ...feathersServices
});
