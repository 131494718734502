import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { useSnackbar } from 'react-simple-snackbar';
import { Form, Button } from 'semantic-ui-react';

const nullOption = { value: null, text: '' };

const booleanOptions = [
  nullOption,
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
];

const fields = {
  name: {
    path: 'name',
    label: 'Name',
    placeholder: 'James Acaster'
  },
  city: {
    path: 'profileData.city',
    label: 'City',
    placeholder: 'London'
  },
  number: {
    path: 'number',
    label: 'Number',
    placeholder: '+449876543210'
  },
  email: {
    path: 'email',
    label: 'Email',
    placeholder: 'name@gmail.com'
  },
  slackChannelId: {
    path: 'profileData.slack.channelId',
    label: 'Slack Channel ID',
    placeholder: 'C018RSJ6BL6'
  },
  slackUserId: {
    path: 'profileData.slack.userId',
    label: 'Slack User ID'
  },
  shareFlatOne: {
    path: 'profileData.share.flat.one',
    label: 'Flat - 1 Man',
    placeholder: '30'
  },
  shareFlatTwo: {
    path: 'profileData.share.flat.two',
    label: 'Flat - 2 Men',
    placeholder: '40'
  },
  shareHourlyOne: {
    path: 'profileData.share.hourly.one',
    label: 'Hourly - 1 Man (/hr)',
    placeholder: '27'
  },
  shareHourlyTwo: {
    path: 'profileData.share.hourly.two',
    label: 'Hourly - 2 Men (/hr)',
    placeholder: '40'
  },
  ourCommission: {
    path: 'profileData.ourComission',
    label: 'Our Comission (%)',
    placeholder: '20'
  },
  onsiteBankTransfer: {
    path: 'profileData.onsiteBankTransfer',
    label: 'Onsite Bank Transfer',
    placeholder: 'yes',
    selection: true,
    fluid: true,
    options: booleanOptions
  },
  active: {
    path: 'active',
    label: 'Active',
    placeholder: 'yes',
    selection: true,
    fluid: true,
    options: booleanOptions
  }
};

const Base = styled.div`
  height: 100%;
`;

const Body = styled.div`
  height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 0.5rem 1rem;

  & > form.ui.small.form .fields .field {
    width: 50% !important;
  }
`;

const Footer = styled.div`
  height: 50px;
  border-top: 1px solid #e9e9ea;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const VendorProfile = ({ vendor = {}, onUpdate }) => {
  const [patch, setPatch] = useState({});
  const [loading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar({
    style: {
      minWidth: '200px'
    }
  });

  const noUnsavedChanges = R.isEmpty(patch);

  const getProps = key => {
    const field = fields[key];
    let { path, ...cfg } = field;

    path = field.path.split('.');
    const onChange = (_, { value }) => {
      setPatch(R.assocPath(path, value, patch));
    };

    const value =
      R.path(path, patch) !== undefined
        ? R.path(path, patch)
        : R.path(path, vendor) !== undefined
        ? R.path(path, vendor)
        : undefined;

    const unsaved =
      R.path(path, patch) !== undefined && R.path(path, patch) !== null;

    return {
      ...cfg,
      value,
      onChange,
      error: unsaved
    };
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await onUpdate(patch);
      openSnackbar('Saved', 2000);
    } catch (error) {
      openSnackbar('Error in saving the Vendor!', 3000);
    }
    setPatch({});
    setLoading(false);
  };

  useEffect(() => {
    setPatch({});
  }, [vendor._id]);

  return (
    <Base>
      <Body>
        <Form size="small">
          <Form.Group widths="equal">
            <Form.Input {...getProps('name')} />
            <Form.Input {...getProps('city')} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input {...getProps('number')} />
            <Form.Input {...getProps('email')} />
          </Form.Group>
          {/* <Form.Group widths="equal">
          <Form.Input {...getProps('shiftStart')} />
          <Form.Input {...getProps('shiftEnd')} />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input {...getProps('latitude')} />
          <Form.Input {...getProps('longitude')}/>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input {...getProps('vanSpeed')} />
          <Form.Input {...getProps('vanCapacity')} />
        </Form.Group> */}
          <Form.Group widths="equal">
            {/* <Form.Input {...getProps('ourCommission')} /> */}
            <Form.Dropdown {...getProps('active')} />
            <Form.Dropdown {...getProps('onsiteBankTransfer')} />
          </Form.Group>
          <Form.Input {...getProps('slackChannelId')} />
          {/* <Divider horizontal content="Base Share Values (£)" />
          <Form.Group widths="equal">
            <Form.Input {...getProps('shareFlatOne')} />
            <Form.Input {...getProps('shareFlatTwo')} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input {...getProps('shareHourlyOne')} />
            <Form.Input {...getProps('shareHourlyTwo')} />
          </Form.Group> */}
        </Form>
      </Body>
      <Footer>
        <Button
          disabled={noUnsavedChanges}
          loading={loading}
          color={noUnsavedChanges ? null : 'green'}
          content="Save"
          icon="save"
          labelPosition="right"
          onClick={handleSave}
        />
      </Footer>
    </Base>
  );
};

export default VendorProfile;
