import React from 'react';
import styled, { css } from 'styled-components';

import { Label, Icon } from 'semantic-ui-react';
import { wds } from './index';
import { jobLabels } from 'utils';

const Base = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  min-height: 44px;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      border-top: 3px solid #21ba45;
    `};
`;

const Flex = styled.div`
  flex: ${props => props.flex};
`;

const JobRow = ({ onClick, selected, ...job }) => {
  const labels = jobLabels(job);

  return (
    <Base selected={selected} onClick={onClick}>
      <Flex flex={wds[0]}>
        {job.isTestJob ? (
          <Label size="small" color="grey" basic>
            <Icon name="bug" />#{job.jobId}
          </Label>
        ) : (
          <strong>#{job.jobId}</strong>
        )}
      </Flex>
      <Flex flex={wds[1]}>
        <Label {...labels.status} size="small" />
      </Flex>
      <Flex flex={wds[2]}>
        <Label {...labels.fulfilment} size="small" />
      </Flex>
      <Flex flex={wds[3]}>
        <Label content={job.pickup} size="small" />
      </Flex>
      <Flex flex={wds[4]}>
        <Label content={job.delivery} size="small" />
      </Flex>
    </Base>
  );
};

export default JobRow;
