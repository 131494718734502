import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from 'store/context';
import { client } from 'api';

import { Menu, Icon, Divider, Label, List } from 'semantic-ui-react';
import { Card, Popup, Badge } from './index';
import { MENU_HEIGHT } from 'utils/constants';

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  border: 0;
  height: ${MENU_HEIGHT};
  width: 60px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
`;

const Right = styled.div`
  position: absolute;
  right: 0;
  display: flex;
`;

const Navbar = ({ children, rightChildren }) => {
  const {
    handleLogout,
    user = {},
    navigation = {},
    network = {},
    eventChannels
  } = useContext(AppContext);

  const { connectionStatus } = network;

  const toggleActive = () => {
    client.service('/operators').patch(user._id, { active: !user.active });
  };

  return (
    <Card width="100%" height={MENU_HEIGHT} z={999} flex>
      <Popup
        trigger={
          <Item>
            <Icon name="sidebar" />
          </Item>
        }
        content={
          <Menu vertical secondary>
            {navigation.allPermitted.map(({ key, title, icon }) => (
              <Menu.Item as={NavLink} key={key} to={key}>
                {title}
                <Icon name={icon} />
              </Menu.Item>
            ))}
          </Menu>
        }
      />
      {children}
      <Right>
        {rightChildren}
        <Popup
          trigger={
            <Item>
              {connectionStatus === 'connected' ? (
                <Label
                  basic
                  icon="sync alternate"
                  content={eventChannels.length}
                />
              ) : connectionStatus === 'needs-refresh' ? (
                <Label basic icon="sync alternate" content="💥" />
              ) : (
                <Label
                  basic
                  icon={<Icon loading name="sync alternate" />}
                  content="..."
                />
              )}
            </Item>
          }
          content={
            connectionStatus === 'connected' ? (
              <List bulleted>
                {eventChannels.map(ec => (
                  <List.Item key={ec} content={ec} />
                ))}
              </List>
            ) : (
              ''
            )
          }
        />
        <Popup
          trigger={
            <Item>
              <Icon name="user circle outline" />
              <Badge
                corner="bottom"
                color={
                  connectionStatus === 'connected'
                    ? user.active
                      ? 'green'
                      : 'yellow'
                    : 'grey'
                }
              />
            </Item>
          }
          content={
            <Menu vertical secondary>
              <Menu.Item header>
                {user.email} ({user.role.name})
              </Menu.Item>
              <Divider />
              <Menu.Item
                onClick={() =>
                  navigator.clipboard.writeText(process.env.REACT_APP_VERSION)
                }
              >
                {process.env.REACT_APP_VERSION}
                <Icon name="hashtag" />
              </Menu.Item>
              <Menu.Item onClick={toggleActive}>
                Set as {user.active ? 'Inactive' : 'Active'}
                <Icon name={user.active ? 'bell slash' : 'bell'} />
              </Menu.Item>
              <Menu.Item onClick={handleLogout}>
                Logout
                <Icon name="power" />
              </Menu.Item>
            </Menu>
          }
        />
      </Right>
    </Card>
  );
};

export default Navbar;
