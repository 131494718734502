import React from 'react';
import { Label, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const HoverLabel = ({ button, onClick }) => (
  <Popup
    key={button.id}
    basic
    inverted
    trigger={
      <Label
        as="a"
        basic
        content={button.label}
        onClick={() => onClick(button)}
      />
    }
    content={button.content}
  />
);

HoverLabel.propTypes = {
  button: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default HoverLabel;
