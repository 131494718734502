import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'semantic-ui-react';

const colorMap = {
  green: {
    background: '#21BA45',
    border: '#D3F1DA'
  },
  yellow: {
    background: '#FBBD08',
    border: '#FEF2CE'
  },
  red: {
    background: '#DB2828',
    border: '#FBEAE5'
  },
  grey: {
    background: '#C0C0C0',
    border: '#E8E8E8'
  }
};

const Base = styled.span`
  display: flex;
  justify-content: center;
  width: ${props => props.size};
  height: ${props => props.size};
  background-color: ${props => colorMap[props.color].background};
  transition: background-color 0.25s ease-in-out;
  border-radius: ${props => props.size};
  border: ${props => '3px solid ' + colorMap[props.color].border};
  margin-left: ${props => props.ml};

  ${props =>
    props.corner === 'bottom' &&
    css`
      position: relative;
      right: 12px;
      top: 8px;
    `};

  ${props =>
    props.corner === 'top' &&
    css`
      position: relative;
      right: 12px;
      bottom: 8px;
    `};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const StyledIcon = styled(Icon)`
  color: #fff;
  font-size: 9px !important;
  margin: 0 !important;
`;

const Badge = ({ color, corner, ml, icon, text, onClick }) => {
  return (
    <Base
      color={color}
      size={icon ? '24px' : '16px'}
      corner={corner}
      ml={ml}
      onClick={onClick}
    >
      {icon && <StyledIcon name={icon} />}
      {text}
    </Base>
  );
};

export default Badge;
