import React from 'react';
import styled from 'styled-components';

import { Menu, Icon, Divider, Button } from 'semantic-ui-react';
import { Popup } from 'components/v2';
import { MENU_HEIGHT } from 'utils/constants';

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  border: 0;
  height: ${MENU_HEIGHT};
  width: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
`;

const Troubleshoot = ({ onVendorCreate }) => {
  return (
    <Popup
      trigger={
        <Item>
          <Icon name="bug" />
        </Item>
      }
      content={
        <Menu vertical secondary>
          <Menu.Item header>Troubleshoot</Menu.Item>
          <Divider />
          <Menu.Item>
            <Button
              content="Create New"
              icon="plus"
              labelPosition="right"
              onClick={onVendorCreate}
            />
          </Menu.Item>
        </Menu>
      }
    />
  );
};

export default Troubleshoot;
