import dingWav from 'assets/audio/ding.wav';
import flagWav from 'assets/audio/flag.wav';

const makeAudio = url => {
  let player = {
    play: () => {}
  };

  if (typeof window !== 'undefined') {
    player = new Audio(url);
  }

  return player;
};

export default { newJob: makeAudio(dingWav), newFlag: makeAudio(flagWav) };
