export const jobReducer = (jobs, action) => {
  const { type, jobId, data, patchStyle = 'overwrite' } = action;

  switch (type) {
    case 'create':
      jobs[jobId] = data;
      return;
    case 'delete':
      if (jobs[jobId]) {
        delete jobs[jobId];
      }
      return;
    case 'patch':
      if (jobs[jobId]) {
        if (patchStyle === 'merge') {
          jobs[jobId].data = { ...jobs[jobId].data, ...data.data };
        } else {
          jobs[jobId] = { ...jobs[jobId], ...data };
        }
      }
      return;
    default:
      return;
  }
};

export const chatReducer = (chats, action) => {
  const { channelId, data, flagId } = action;

  switch (action.type) {
    case 'create':
      chats[channelId] = data;
      return;
    case 'delete':
      delete chats[channelId];
      return;
    case 'patch':
      chats[channelId].messages = chats[channelId].messages.map(msg => {
        if (msg._id === data._id) {
          return data;
        }
        return msg;
      });
      return;
    case 'messageHistory':
      chats[channelId].messages = data;
      return;
    case 'newMessage':
      if (chats[channelId]) {
        chats[channelId].messages.push(data);
      }
      return;
    case 'setBody':
      chats[channelId].body = data;
      return;
    case 'deleteFlag':
      if (chats[channelId]) {
        chats[channelId].activeFlagId = null;
      }
      return;
    case 'setActiveFlag':
      if (chats[channelId]) {
        chats[channelId].activeFlagId = flagId;
      }
    case 'unread':
      if (chats[channelId]) {
        chats[channelId].potentialUnread = true;
      }
      return;
    case 'read':
      if (chats[channelId]) {
        chats[channelId].potentialUnread = false;
      }
      return;
    case 'muteTimer':
      if (chats[channelId]) {
        if (chats[channelId].muteTimer) {
          console.log('💦');
          clearTimeout(chats[channelId].muteTimer);
        }
        chats[channelId].muteTimer = setTimeout(() => {
          console.log('💥');
          data.callback();
        }, 2 * 60 * 1000);
      }
      return;
    default:
      return;
  }
};
