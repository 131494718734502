import styled from 'styled-components';

import { Popup, SearchBox } from 'components/v2';
import { Icon, Menu } from 'semantic-ui-react';

const options = [
  {
    id: 'whatsapp',
    label: 'Whatsapp',
    icon: 'whatsapp'
  },
  {
    id: 'webchat',
    label: 'Web Chat',
    icon: 'comments'
  },
  {
    id: 'sms',
    label: 'SMS',
    icon: 'comment alternate'
  },
  {
    id: 'email',
    label: 'Email',
    icon: 'envelope'
  },
  {
    id: 'voice',
    label: 'Phone',
    icon: 'phone'
  },
  {
    id: 'slack',
    label: 'Slack',
    icon: 'slack'
  }
];

const Base = styled.div`
  height: 50px;
  background: #f9fafb;
  border-bottom: 1px solid #e9e9ea;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

const Title = styled.span`
  font-weight: 800;
  color: rgba(0, 0, 0, 0.8);
`;

const Subtitle = styled.span`
  color: rgba(0, 0, 0, 0.8);
  /* margin-left: 0.5rem; */
`;

const ChannelLabel = styled.span`
  margin-left: 0.5rem;
  font-size: 16px;
  cursor: pointer;
`;

const Right = styled.div`
  /* position: absolute; */
  /* right: 1rem; */
`;

const ChannelSelection = ({
  active,
  onUpdate,
  copyToInput,
  allowed,
  label,
  withFaq
}) => {
  // const activeOption = options.find(x => x.id === active) || {};
  return (
    <Base>
      <Popup
        trigger={
          <ChannelLabel>
            {/* <Title>
              <Icon name={activeOption.icon} />
            </Title> */}
            <Subtitle>
              {/* {activeOption.label} */}
              {label && <strong> {label}</strong>}
            </Subtitle>
          </ChannelLabel>
        }
        content={
          <Menu vertical secondary>
            {options.map(x => (
              <Menu.Item
                key={x.id}
                onClick={() => onUpdate(x.id)}
                disabled={!allowed.includes(x.id)}
              >
                {x.label}
                <Icon name={x.icon} />
              </Menu.Item>
            ))}
          </Menu>
        }
      />
      {withFaq && (
        <Right>
          <SearchBox basic onSelect={copyToInput} />
        </Right>
      )}
    </Base>
  );
};

export default ChannelSelection;
