import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { MENU_HEIGHT } from 'utils/constants';
import { Badge } from 'components/v2';

const Base = styled.div`
  height: ${MENU_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  width: 100px;
  margin: 0 8px;
  border-bottom: 3px solid #fff;
  padding-top: 3px;
  transition: font-weight 0.25s ease-in-out, color 0.25s ease-in-out,
    border-color 0.25s ease-in-out;

  ${props =>
    props.unread &&
    css`
      font-weight: bold;
      color: #f2711c;
    `}

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid #21ba45;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.8);
    `}

  filter: grayscale(${props => (props.isTestJob ? '100%' : '0%')});
`;

const StyledIcon = styled(Icon)`
  font-size: 12px !important;
  /* color: rgba(0, 0, 0, 0.4); */
  margin: 0 0 6px 3px !important;
`;

const JobId = styled.span`
  cursor: pointer;
  margin-left: 6px;
  display: flex;
`;

const JobLabel = ({
  jobId,
  active,
  flags = [],
  channel,
  potentialUnread,
  onClose,
  onActive,
  isTestJob
}) => {
  const unread = potentialUnread && !active;

  return (
    <Base active={active} unread={unread} isTestJob={isTestJob}>
      <JobId onClick={onActive}>
        {jobId}
        {unread ? '*' : ''}
        {!isTestJob && <> 🚨</>}
        {/* {channel === 'whatsapp' ? (
          <StyledIcon name="whatsapp" />
        ) : (
          <StyledIcon name="chat outline" />
        )} */}
      </JobId>
      {flags.length > 0 ? (
        <Badge color="red" ml="3px" />
      ) : (
        <StyledIcon name="times" link onClick={onClose} />
      )}
    </Base>
  );
};

export default JobLabel;
