import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Segment, Header, Dropdown, Label } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';

import { HoverLabel } from 'components/v1';
import { assistBoxStyles } from './styles';

const getOptions = R.pipe(
  R.pluck('tags'),
  R.flatten,
  R.uniq,
  R.map(t => ({ key: t, text: t, value: t }))
);

const filterButtons = (buttons, selected) =>
  R.isEmpty(selected)
    ? buttons
    : R.filter(
        R.pipe(R.prop('tags'), R.intersection(selected), R.isEmpty, R.not)
      )(buttons);

class AssistBox extends Component {
  state = { selected: [] };

  render() {
    const { buttons, onClick } = this.props;
    const { selected } = this.state;

    const options = getOptions(buttons);
    const filteredButtons = filterButtons(buttons, selected);

    return (
      <div className="assist-box" {...assistBoxStyles}>
        <Segment size="small" secondary attached="top" className="assist-title">
          <Header as="span">Assists</Header>
          <Dropdown
            size="small"
            fluid
            search
            multiple
            selection
            scrolling
            options={options}
            placeholder="Select filter tags..."
            value={selected}
            onChange={(_, data) => this.setState({ selected: data.value })}
          />
        </Segment>
        <Segment size="small" attached className="assist-body">
          <Scrollbars autoHide style={{ height: '9rem' }}>
            <Label.Group size="small">
              {filteredButtons.map(btn => (
                <HoverLabel key={btn.id} button={btn} onClick={onClick} />
              ))}
            </Label.Group>
          </Scrollbars>
        </Segment>
      </div>
    );
  }
}

AssistBox.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired
};

export default AssistBox;
