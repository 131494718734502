import { filter } from 'components/v1/TaskHub/utils';
import * as R from 'ramda';

export const wds = [0.25, 0.23, 0.27, 0.15, 0.1];

export const statusOptions = ['pending', 'done', 'ignored', 'escalated'].map(
  x => ({
    key: x,
    value: x,
    text: x
  })
);

export const withTasks = (unsortedTasks, filters) => vendors => {
  const tasks = R.sortWith([R.descend(R.prop('createdAt'))], unsortedTasks);

  return vendors
    .filter(x => {
      return filters.vendor === 'all'
        ? true
        : filters.vendor === 'active'
        ? x.active
        : !x.active;
    })
    .map(vendor => {
      const vendorTasks = tasks.filter(
        x =>
          x.entities.vendor === vendor._id &&
          (filters.task === 'all' ? true : x.status === filters.task)
      );

      const recent =
        R.pipe(R.head, R.prop('createdAt'))(vendorTasks) ||
        '1970-01-01T00:00:00.000Z';

      return {
        ...vendor,
        tasks: vendorTasks,
        recent
      };
    });
};

export const getSort = s => vendors => {
  if (s === 'recent') {
    return R.sortWith([R.descend(R.prop('recent'))], vendors);
  }

  if (s === 'name') {
    return R.sortWith([R.ascend(R.prop('name'))], vendors);
  }

  if (s === 'city') {
    return R.sortWith([R.ascend(R.path(['profileData', 'city']))], vendors);
  }

  return vendors;
};
