import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { useImmer } from 'use-immer';

import { Loader, Menu, Button, Divider, Input, Form } from 'semantic-ui-react';
import { Popup } from 'components/v2';
import VendorTaskGroup from './VendorTaskGroup';
import * as H from './helpers';

const wds = H.wds;

const Base = styled.div`
  height: 100%;
  overflow-x: none;
  overflow-y: auto;
  padding: 0rem 0.5rem 1rem 0.5rem;
`;

const StyledLoader = styled(Loader)`
  margin-top: 3rem !important;
`;

const ControlsBase = styled.div`
  margin: 1rem 0.5rem;
  display: flex;
  height: 36px;
`;

const StyledButton = styled(Button)`
  &.ui.basic.button {
    background-color: #fff !important;
  }
  margin-left: 0.5rem !important;
`;

const StyledInput = styled(Input)`
  margin: 0 0.5rem !important;
  width: 100%;
`;

const TaskGroupList = styled.div`
  height: calc(100% - 66px);
  overflow-y: scroll;
`;

const HeaderRow = styled.div`
  display: flex;
  padding: 1rem 1.5rem 0rem;
  justify-content: space-evenly;
`;

const Flex = styled.div`
  flex: ${props => props.flex};
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  margin-left: ${props => (props.centered ? '9px' : 'none')};
`;

const ListFilter = ({ filters, setFilters }) => {
  return (
    <Form size="small">
      <Form.Select
        label="Vendor Status"
        value={filters.vendor}
        options={['active', 'inactive', 'all'].map(f => ({
          key: f,
          text: f,
          value: f,
          onClick: () => setFilters({ ...filters, vendor: f })
        }))}
      />
      <Form.Select
        label="Task Status"
        value={filters.task}
        options={['pending', 'escalated', 'done', 'ignored', 'all'].map(f => ({
          key: f,
          text: f,
          value: f,
          onClick: () => setFilters({ ...filters, task: f })
        }))}
      />
    </Form>
  );
};

const VendorList = ({
  loading,
  activeVendorId,
  vendors = [],
  tasks = [],
  onTaskUpdate
}) => {
  const [state, updateState] = useImmer({
    filters: {
      task: 'pending',
      vendor: 'active'
    },
    sort: 'recent',
    search: '',
    globalExpanded: 'collapsed',
    localExpanded: {},
    active: null
  });

  const history = useHistory();

  const update = (field, value) => {
    updateState(draft => {
      draft[field] = value;
    });
  };

  const toggleGlobalExpanded = () => {
    updateState(draft => {
      draft.globalExpanded =
        draft.globalExpanded === 'expanded' ? 'collapsed' : 'expanded';
      draft.localExpanded = {};
    });
  };

  const toggleLocalExpanded = vendorId => {
    updateState(draft => {
      const current = draft.localExpanded[vendorId] || draft.globalExpanded;
      draft.localExpanded[vendorId] =
        current === 'expanded' ? 'collapsed' : 'expanded';
    });
  };

  const onTaskGroupClick = vendorId => {
    update('active', vendorId);
    history.push(`/app/vendors/#${vendorId}`);
    updateState(draft => {
      draft.localExpanded[vendorId] = 'expanded';
    });
  };

  const processed = R.pipe(
    H.withTasks(tasks, state.filters),
    H.getSort(state.sort)
  )(vendors);

  return (
    <Base>
      <ControlsBase>
        <Popup
          flowing
          trigger={<StyledButton basic icon="filter" />}
          content={
            <ListFilter
              filters={state.filters}
              setFilters={x => update('filters', x)}
            />
          }
        />
        <Popup
          trigger={<StyledButton basic icon="sort amount down" />}
          content={
            <Menu secondary vertical size="small">
              {['recent', 'name', 'city'].map(f => (
                <Menu.Item
                  key={f}
                  name={f}
                  active={state.sort === f}
                  onClick={() => update('sort', f)}
                />
              ))}
            </Menu>
          }
        />
        <StyledButton
          basic
          icon={state.globalExpanded === 'expanded' ? 'compress' : 'expand'}
          onClick={() => toggleGlobalExpanded()}
        />
        <StyledInput
          fluid
          disabled
          placeholder="Search"
          icon="search"
          iconPosition="left"
          value={state.search}
          onChange={(_, { value }) => update('search', value)}
        />
      </ControlsBase>

      <Divider fitted />

      <TaskGroupList>
        <HeaderRow>
          <Flex flex={wds[0]}>
            <Title>Name</Title>
          </Flex>
          <Flex flex={wds[1]}>
            <Title>City</Title>
          </Flex>
          <Flex flex={wds[2]}>
            <Title>Number</Title>
          </Flex>
          <Flex flex={wds[3]}>
            <Title>Tasks</Title>
          </Flex>
          <Flex flex={wds[4]}>
            <Title></Title>
          </Flex>
        </HeaderRow>

        {loading ? (
          <StyledLoader
            active
            inline="centered"
            content="Fetching Vendors..."
          />
        ) : (
          processed.map(x => {
            return (
              <VendorTaskGroup
                key={x._id}
                selected={activeVendorId === x._id}
                setActive={() => onTaskGroupClick(x._id)}
                toggleLocalExpanded={() => toggleLocalExpanded(x._id)}
                expanded={
                  (state.localExpanded[x._id] || state.globalExpanded) ===
                  'expanded'
                }
                onTaskUpdate={onTaskUpdate}
                {...x}
              />
            );
          })
        )}
      </TaskGroupList>
    </Base>
  );
};

export default VendorList;
