import { createLogger, format, transports } from 'winston';
import WinstonCloudwatch from 'winston-cloudwatch';

import rollbar from './rollbar';
import getStreamName from './getStreamName';

const customConsoleFormat = format.combine(
  format.printf(info => {
    const { module, level, message } = info;

    const symbol = ['error', 'warn'].includes(level) ? '⬢' : '⬡';
    const label = level === 'error' ? 'error' : module;
    const text = `${message.req || message} ${
      message.error ? message.error.message : ''
    }`;

    if (level === 'error') {
      rollbar.error(text);
    }

    return `${symbol} ${label}: ${text}`;
  })
);

const cloudwatchConfig = {
  awsRegion: 'us-west-2',
  logGroupName: process.env.REACT_APP_CLOUDWATCH_LOGGROUP,
  logStreamName: getStreamName(),
  awsAccessKeyId: process.env.REACT_APP_CLOUDWATCH_KEY,
  awsSecretKey: process.env.REACT_APP_CLOUDWATCH_SECRET,
  uploadRate: 10000,
  jsonMessage: true
};

const logger = createLogger({
  transports: [
    new transports.Console({
      format: customConsoleFormat
    }),
    new WinstonCloudwatch(cloudwatchConfig)
  ]
});

const childLoggerMaker = args => logger.child(args);

window.addEventListener('error', function ({ error }) {
  const log = childLoggerMaker({ module: 'uncaughtErrors' });
  log.error({ error });
});

export default childLoggerMaker;

// import logger from 'webpack-log';

// export default ({ module }) => {
//   return logger({ name: module });
// };
