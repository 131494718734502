import { css } from 'glamor';

export default css({
  marginTop: '0 !important',
  '& .comment': {
    '& .content': {
      '& .author': {
        textTransform: 'capitalize'
      },
      '& .text': {
        marginTop: 0,
        whiteSpace: 'pre-line'
      },
      '& .metadata': {
        textAlign: 'right'
      },
      '& .actions': {
        marginTop: '0.5em'
      }
    },
    '& .ui.red.corner.label': {
      fontSize: '0.45rem',
      marginTop: '0.7rem'
    },
    '&:first-child': {
      '& .ui.red.corner.label': {
        marginTop: '0.2rem'
      }
    }
  }
});
