import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';

import { formatTime } from 'utils';
import { Popup, Badge } from '../';
import AttributeLabels from './AttributeLabels';

const MessageContainer = styled.div`
  display: flex;
  padding: 0.25rem 1rem;

  ${props =>
    props.author !== 'customer' &&
    props.author !== 'meta' &&
    props.author !== 'vendor' &&
    css`
      justify-content: flex-end;
    `}

  :hover .info-button {
    visibility: visible;
  }
`;

const userCss = {
  operator: css`
    border-bottom-right-radius: 0;
    background: #21ba45;
  `,
  customer: css`
    border-bottom-left-radius: 0;
    color: #212b36;
    background: #ebf5fa;
  `,
  meta: css`
    border-bottom-left-radius: 0;
    color: #212b36;
    background: #ebf5fa;
  `,
  system: css`
    border-bottom-right-radius: 0;
    background: #21ba45;
  `,
  vendor: css`
    border-bottom-left-radius: 0;
    color: #212b36;
    background: #ebf5fa;
  `
};

const BodyContainer = styled.div`
  min-width: 75px;
  max-width: 300px;
  clear: both;
  padding: 0.6rem 0.6rem 0.5rem 0.6rem;
  border-radius: 1rem;
  white-space: pre-wrap;
  word-break: break-word;
  color: #fff;
  ${props => userCss[props.author] || userCss.system};

  & a.link {
    color: #fff;
    text-decoration: underline;
  }
`;

const Meta = styled.div`
  margin-top: 0.2rem;
  font-size: 0.7rem;
  text-align: right;
  color: ${props =>
    props.author !== 'customer' &&
    props.author !== 'vendor' &&
    props.author !== 'meta'
      ? '#fff'
      : '#9e9e9e'};
`;

const RichBody = ({ body }) => {
  const __html = body
    .replace(/_(.*?)_/g, '<i>$1</i>')
    .replace(/\*(.*?)\*/g, '<b>$1</b>')
    .replace(/~(.*?)~/g, '<s>$1</s>')
    // .replace(
    //   /\[([\w\s\&]+)\]\(((https?):\/\/[^\s$.?#].[^\s]*)\)/g,
    //   '<a class="link" target="_blank" href="$2">$1</a>'
    // )
    .replace(/(\+44(\d+))/g, '<a class="link" href="tel:$1">$2</a>')
    .replace(
      /((https?):\/\/[^\s$.?#].[^\s]*)/g,
      '<a class="link" target="_blank" href="$1">$1</a>'
    )
    .trim();

  return <span dangerouslySetInnerHTML={{ __html }} />;
};

const InfoButton = styled(Button)`
  visibility: hidden;
  position: relative;
  height: fit-content;
  left: -14px;
  top: -14px;
  color: #212b36 !important;
  background: #fff !important;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05) !important;
`;

const Author = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  font-size: 0.8rem;
  margin-bottom: -4px;
`;

const Options = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const Option = styled.div`
  padding: 2px 8px;
  border: 1px dotted white;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
`;

const ImageAttachment = styled.img`
  margin-top: 8px;
  max-width: 100%;
  border-radius: 8px;
`;

const ChatMessage = message => {
  const {
    body,
    timestamp,
    createdAt,
    author,
    attributes = {},
    flag,
    handleSetActiveFlag
  } = message;

  let options = (attributes || {}).options;
  if (typeof options === 'string') {
    options = options.split('|');
  }

  const link = (attributes || {}).link;

  return (
    <MessageContainer author={author} flag={flag} className={author}>
      <BodyContainer author={author}>
        <Author>{author}:</Author>
        <RichBody body={body} />
        {link && <ImageAttachment src={link} />}
        {options && (
          <Options>
            {attributes.optionTitle || 'Select Option'}:{' '}
            {options.map((x, i) => {
              let value;
              if (typeof x === 'string') {
                value = x;
              } else {
                value = x.title;
              }

              return <Option key={i}>{value}</Option>;
            })}
          </Options>
        )}
        <Meta author={author}>{formatTime(createdAt || timestamp)}</Meta>
      </BodyContainer>

      {author === 'customer' || author === 'meta' ? (
        flag ? (
          <Badge
            corner="top"
            color="red"
            icon="warning"
            onClick={handleSetActiveFlag}
          />
        ) : attributes ? (
          <Popup
            hoverable
            flowing
            hideOnScroll
            position="right center"
            size="small"
            trigger={
              <InfoButton
                className="info-button"
                size="mini"
                circular
                icon="ellipsis vertical"
              />
            }
          >
            <AttributeLabels data={attributes.treeMetadata || attributes} />
          </Popup>
        ) : null
      ) : null}
    </MessageContainer>
  );
};

export default ChatMessage;
