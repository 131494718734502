import * as R from 'ramda';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useImmerReducer } from 'use-immer';
import logger from 'utils/logger';

import { client } from 'api';
import imgSrc from 'assets/images/white_van.png';
import { Card, DefaultBase, EmptyState, Navbar, Text } from 'components/v2';
import { AppContext } from 'store/context';
import { DBReducer } from 'utils';
import { MENU_HEIGHT } from 'utils/constants';
import {
  Troubleshoot,
  VendorChat,
  VendorList,
  VendorProfile
} from './components';

const log = logger({ module: 'vendors' });

const getChannelNames = vendorId => [
  `/chat/sms:${vendorId}`,
  `/chat/email:${vendorId}`,
  `/chat/slack:${vendorId}`
];

const Body = styled.div`
  height: calc(100% - ${MENU_HEIGHT});
  display: flex;

  & .ui.secondary.pointing.menu {
    margin-bottom: 0;
  }
`;

const EmptyBase = styled.div`
  width: 60%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
`;

const Vendors = () => {
  const { handleEvents } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState([]);
  const [tasks, dispatchTasks] = useImmerReducer(DBReducer, []);
  const [vendors, dispatchVendors] = useImmerReducer(DBReducer, []);
  const [messages, dispatchMessages] = useImmerReducer(DBReducer, []);

  const tasksService = client.service('/tasks');
  const vendorsService = client.service('/vendors');
  const messagesService = client.service('/db/messages');

  const location = useLocation();
  const activeVendorId = location.hash ? location.hash.replace('#', '') : null;

  const activeVendor = vendors.find(x => x._id === activeVendorId);

  const onMessageSend =
    vendorId =>
    ({ body, channel }) => {
      return client.service('/vendors/fmsChat/messages').create({
        body,
        channel,
        vendorId,
        author: 'operator'
      });
    };

  const onVendorUpdate = vendorId => data => {
    return vendorsService.patch(vendorId, data);
  };

  const onVendorCreate = () => {
    return vendorsService.create({
      name: 'Placeholder',
      profileData: {
        city: 'Placeholder'
      }
    });
  };

  const onTaskUpdate = ({ _id, ...patch }) => {
    tasksService.patch(_id, patch);
  };

  const setInitializedWrapper = () => {
    setInitialized(prev => [...prev, activeVendorId]);
    handleEvents({
      action: 'join',
      channels: getChannelNames(activeVendorId)
    });

    log.info(`✅ message updates [${activeVendorId}]`);
  };

  const initialQuery = async () => {
    setLoading(true);
    const [{ data: vendors }, { tasks }] = await Promise.all([
      vendorsService.find({
        query: {
          $sort: {
            name: 1
          },
          $limit: 100
        }
      }),
      tasksService.find({
        query: {
          status: {
            $nin: ['done', 'ignored']
          },
          'entities.vendor': {
            $exists: true
          },
          $sort: {
            createdAt: -1
          }
        }
      })
    ]);

    dispatchVendors({ type: 'created', data: vendors });
    dispatchTasks({ type: 'created', data: tasks });

    setLoading(false);
  };

  const subscribe = useCallback(() => {
    vendorsService.on('created', res => {
      const { _id } = res;
      dispatchVendors({ type: 'created', _id, data: res });
    });

    vendorsService.on('patched', res => {
      const { _id } = res;
      dispatchVendors({ type: 'patched', _id, data: res });
    });

    vendorsService.on('removed', res => {
      const { _id } = res;
      dispatchVendors({ type: 'removed', _id });
    });
    log.info('✅ vendor updates');

    // join relevant channel
    handleEvents({
      action: 'join',
      channels: ['/tasks/vendor']
    });
    tasksService.on('created', res => {
      const { _id } = res;
      const vendorId = R.path(['entities', 'vendor'])(res);

      if (vendorId) {
        dispatchTasks({ type: 'created', _id, data: res });
      }
    });
    tasksService.on('patched', res => {
      const { _id } = res;
      dispatchTasks({ type: 'patched', _id, data: res });
    });
    tasksService.on('removed', res => {
      const { _id } = res;
      dispatchTasks({ type: 'removed', _id });
    });
    log.info('✅ tasks updates');

    messagesService.on('created', res => {
      const { _id } = res;
      dispatchMessages({ type: 'created', _id, data: res });
    });
    messagesService.on('patched', res => {
      const { _id } = res;
      dispatchMessages({ type: 'patched', _id, data: res });
    });
    messagesService.on('removed', res => {
      const { _id } = res;
      dispatchMessages({ type: 'removed', _id });
    });
    log.info('✅ messages updates');
  }, []);

  const unsubscribe = useCallback(() => {
    vendorsService.off('created');
    vendorsService.off('patched');
    vendorsService.off('removed');
    log.info('⛔ vendor updates');

    // leave relevant channels
    handleEvents({
      action: 'leave',
      channels: ['/tasks/vendor']
    });
    tasksService.off('created');
    tasksService.off('patched');
    tasksService.off('removed');
    log.info('⛔ tasks updates');

    // leave relevant channels
    handleEvents({
      action: 'leave',
      channels: R.pipe(R.map(getChannelNames), R.flatten)(initialized)
    });
    messagesService.off('created');
    messagesService.off('patched');
    messagesService.off('removed');
    log.info('⛔ messages updates');
  }, []);

  // initial query and main listeners
  useEffect(() => {
    initialQuery();
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe]);

  return (
    <DefaultBase>
      <Navbar
        children={
          <Text fontSize="14px">
            FMS <strong>/ Vendors</strong>
          </Text>
        }
        rightChildren={<Troubleshoot onVendorCreate={onVendorCreate} />}
      ></Navbar>
      <Body>
        <Card width="40%" height="100%" basic>
          <VendorList
            loading={loading}
            activeVendorId={activeVendorId}
            vendors={vendors}
            tasks={tasks}
            onTaskUpdate={onTaskUpdate}
          />
        </Card>

        {activeVendor ? (
          <>
            <Card width="30%" height="100%">
              <VendorChat
                vendorId={activeVendorId}
                vendor={activeVendor}
                initialized={initialized.includes(activeVendorId)}
                setAsInitialized={setInitializedWrapper}
                messages={messages}
                dispatchMessages={dispatchMessages}
                onMessageSend={onMessageSend(activeVendorId)}
              />
            </Card>
            <Card width="30%" height="100%" secondary>
              <VendorProfile
                key={activeVendorId}
                vendor={activeVendor}
                onUpdate={onVendorUpdate(activeVendorId)}
              />
            </Card>
          </>
        ) : (
          <EmptyBase>
            <EmptyState
              title="Available Vendors"
              subtitle="Select Vendor to open Chat and Profile"
              imgSrc={imgSrc}
              imgSize="400px"
            />
          </EmptyBase>
        )}
      </Body>
    </DefaultBase>
  );
};

export default Vendors;
