import * as R from 'ramda';
import { useContext, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { Button, Divider, Form, Label, Tab } from 'semantic-ui-react';
import { AppContext } from 'store/context';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import FormSection from './FormSection';
import JobFormComponent from './JobFormComponent';
import * as H from './helpers';

const nullOption = { value: null, text: '' };

const Base = styled.div`
  height: 100%;
`;

const DataBase = styled.div`
  padding: 0.3rem 0rem;
`;

const Body = styled.div`
  height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 0.5rem 1rem;

  & > div form.ui.small.form .fields .field {
    width: 50% !important;
  }
`;

const Footer = styled.div`
  height: 50px;
  border-top: 1px solid #e9e9ea;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
`;

const Data = ({ label, value, onClick }) => (
  <DataBase>
    <Label
      basic
      as={onClick ? 'a' : null}
      // color={onClick ? 'green' : null}
      content={label}
      detail={value}
      icon={onClick && 'copy outline'}
      onClick={onClick}
    />
  </DataBase>
);

const JobFom = ({
  job = {},
  onUpdate,
  copyToInput,
  globalExpanded = 'collapsed'
}) => {
  const [state, updateState] = useImmer({
    patch: {},
    loading: false,
    globalExpanded,
    localExpanded: {}
  });

  const update = (field, value) => {
    updateState(draft => {
      draft[field] = value;
    });
  };

  const { vendors } = useContext(AppContext);

  const [openSnackbar] = useSnackbar({
    style: {
      minWidth: '200px'
    }
  });

  const { patch, loading } = state;

  const toggleGlobalExpanded = () => {
    updateState(draft => {
      draft.globalExpanded =
        draft.globalExpanded === 'expanded' ? 'collapsed' : 'expanded';
      draft.localExpanded = {};
    });
  };

  const toggleLocalExpanded = sectionId => {
    updateState(draft => {
      const current = draft.localExpanded[sectionId] || draft.globalExpanded;
      draft.localExpanded[sectionId] =
        current === 'expanded' ? 'collapsed' : 'expanded';
    });
  };

  const getSectionProps = sectionId => {
    return {
      toggleLocalExpanded: () => toggleLocalExpanded(sectionId),
      expanded:
        (state.localExpanded[sectionId] || state.globalExpanded) === 'expanded'
    };
  };

  let noUnsavedChanges = true;
  Object.keys(patch).forEach(key => {
    if (patch[key] !== job[key]) {
      noUnsavedChanges = false;
    }
  });

  const vendorFieldsAdditionalConfig = {
    disabled:
      !job.pickupTimeFrom ||
      !job.pickupTimeTo ||
      !(typeof job.commissionOverride === 'number'),
    options: [
      nullOption,
      ...vendors.map(v => ({
        text: `${v.profileData.city} - ${v.name}`,
        value: v._id
      }))
    ]
  };

  const getProps = field => {
    const onChange = value => update('patch', { ...patch, [field]: value });

    const value =
      patch[field] !== undefined
        ? patch[field]
        : job[field] !== undefined
        ? job[field]
        : undefined;

    const unsaved =
      patch[field] !== undefined &&
      patch[field] !== null &&
      patch[field] !== job[field];

    return {
      field,
      value,
      unsaved,
      onChange
      // onBlur
    };
  };

  const handleSave = async () => {
    const keys = Object.keys(patch);
    const out = {};
    keys.forEach(key => {
      const notEmpty = patch[key] !== undefined;
      const notUnchanged = patch[key] !== job[key];

      if (notEmpty && notUnchanged) {
        out[key] = patch[key];
      }
    });

    update('loading', true);

    if (R.isEmpty(out)) {
      openSnackbar('No Change', 2000);
    } else {
      try {
        await onUpdate(out);
        openSnackbar('Saved', 2000);
      } catch (error) {
        openSnackbar('Error in saving the Job!', 3000);
      }
    }

    update('patch', {});
    update('loading', false);
  };

  const msgHelper = msg =>
    copyToInput ? () => copyToInput(H.messages[msg](job)) : null;

  const hourlyFormatHelper = (x = '') => (job.hourly ? `£${x}/hr` : `£${x}`);

  useEffect(() => {
    update('patch', {});
  }, [job.jobId]);

  return (
    <Base>
      <Body>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: `#${job.jobId}${job.isTestJob ? ' (Test)' : ''}`,
              render: () => (
                <>
                  <FormSection
                    title="Postcodes / Address"
                    icon="map marker alternate"
                    {...getSectionProps('postcodeAddress')}
                  >
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('pickup')} />
                      <JobFormComponent {...getProps('delivery')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('travelTime')} />
                      <JobFormComponent {...getProps('distance')} />
                    </Form.Group>
                    <JobFormComponent {...getProps('pickupAddress')} />
                    <JobFormComponent {...getProps('deliveryAddress')} />
                    <JobFormComponent {...getProps('via')} />
                    <JobFormComponent {...getProps('viaAddress')} />
                  </FormSection>

                  <FormSection
                    title="Inventory / Special Needs"
                    icon="zip"
                    {...getSectionProps('inventory')}
                  >
                    <JobFormComponent {...getProps('inventory')} />
                    <JobFormComponent {...getProps('typeSpecialNeeds')} />
                  </FormSection>

                  <FormSection
                    title="Date / Time"
                    icon="calendar alternate"
                    {...getSectionProps('dateTime')}
                  >
                    <JobFormComponent {...getProps('fulfilmentDate')} />
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('pickupTimeFrom')} />
                      <JobFormComponent {...getProps('pickupTimeTo')} />
                    </Form.Group>
                  </FormSection>

                  <FormSection
                    title="Floors / Lifts"
                    icon="building"
                    {...getSectionProps('floorsLifts')}
                  >
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('pickupFloors')} />
                      <JobFormComponent {...getProps('pickupLift')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('deliveryFloors')} />
                      <JobFormComponent {...getProps('deliveryLift')} />
                    </Form.Group>
                  </FormSection>

                  <FormSection
                    title="Men"
                    icon="users"
                    {...getSectionProps('men')}
                  >
                    <JobFormComponent {...getProps('quoteOption')} />
                  </FormSection>

                  <FormSection
                    title="Price"
                    icon="money"
                    {...getSectionProps('price')}
                  >
                    <JobFormComponent {...getProps('priceFragment')} />
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('flexiPriceOption')} />
                      <JobFormComponent {...getProps('hourly')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('priceAcceptance')} />
                      <JobFormComponent {...getProps('manualAdjustment')} />
                    </Form.Group>
                  </FormSection>

                  <FormSection
                    title="Booking Status"
                    icon="tag"
                    {...getSectionProps('bookingStatus')}
                  >
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('bookingIntention')} />
                      <JobFormComponent {...getProps('bookingStatus')} />
                    </Form.Group>
                  </FormSection>

                  <FormSection
                    title="Contact Details"
                    icon="address card"
                    {...getSectionProps('contact')}
                  >
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('email')} />
                      <JobFormComponent {...getProps('number')} />
                    </Form.Group>
                    <JobFormComponent {...getProps('name')} />
                  </FormSection>

                  <FormSection
                    title="Vendor Assignment"
                    icon="shipping"
                    {...getSectionProps('vendor')}
                  >
                    <JobFormComponent {...getProps('driverNotes')} />
                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('vendorShare')}
                        additionalConfig={{ pre: hourlyFormatHelper }}
                      />
                      <JobFormComponent
                        {...getProps('ourCommission')}
                        additionalConfig={{ pre: hourlyFormatHelper }}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('vendorShareOnAcceptance')}
                        additionalConfig={{ pre: hourlyFormatHelper }}
                      />
                      <JobFormComponent {...getProps('commissionOverride')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('assignedDriverId')}
                        additionalConfig={vendorFieldsAdditionalConfig}
                      />
                      <JobFormComponent
                        {...getProps('driverId')}
                        additionalConfig={vendorFieldsAdditionalConfig}
                      />
                    </Form.Group>
                    <JobFormComponent {...getProps('jobSummaryOnAcceptance')} />
                  </FormSection>

                  <FormSection
                    title="Payment / Settlement"
                    icon="exchange"
                    {...getSectionProps('paymentSettlement')}
                  >
                    <JobFormComponent {...getProps('advanceAmount')} />
                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('advanceManualAdjustment')}
                      />
                      <JobFormComponent {...getProps('advanceReceived')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('nonCommissionableAmount')}
                      />
                      <JobFormComponent
                        {...getProps('balancePaymentAcceptOnline')}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('balanceReceived')} />
                      <JobFormComponent {...getProps('balancePaymentMode')} />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('totalJobValue')} />
                      <JobFormComponent {...getProps('noOfHours')} />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('totalOurCommission')} />
                      <JobFormComponent {...getProps('totalVendorShare')} />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('vendorSettlementAmount')}
                      />
                      <JobFormComponent
                        {...getProps('vendorSettlementStatus')}
                      />
                    </Form.Group>

                    <JobFormComponent {...getProps('vendorSettlementNotes')} />
                  </FormSection>

                  <FormSection
                    title="Internal"
                    icon="settings"
                    {...getSectionProps('internal')}
                  >
                    <JobFormComponent {...getProps('internalNotes')} />
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('fulfilmentStatus')} />
                      <JobFormComponent {...getProps('isTestJob')} />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('feedback')} />
                      <JobFormComponent {...getProps('stage')} />
                    </Form.Group>
                    <JobFormComponent {...getProps('feedbackMessage')} />
                  </FormSection>

                  <FormSection
                    title="Move Type"
                    icon="zip"
                    {...getSectionProps('moveType')}
                  >
                    <JobFormComponent {...getProps('moveType')} />
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('propertyTypeAtPickup')} />
                      <JobFormComponent
                        {...getProps('propertyTypeAtDelivery')}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent
                        {...getProps('propertyTypeHouseAtPickup')}
                      />
                      <JobFormComponent
                        {...getProps('propertyTypeHouseAtDelivery')}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('numberOfBedrooms')} />
                      <JobFormComponent {...getProps('officeArea')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('shopBrand')} />
                      <JobFormComponent {...getProps('inventoryList')} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <JobFormComponent {...getProps('packingService')} />
                      <JobFormComponent {...getProps('dismantlingService')} />
                    </Form.Group>
                  </FormSection>
                </>
              )
            },
            {
              menuItem: 'Other Info',
              render: () => (
                <>
                  <Data
                    label="Hub"
                    value={job.hubLink}
                    onClick={msgHelper('hubLink')}
                  />
                  <Data
                    label="Bank Details"
                    onClick={msgHelper('bankDetails')}
                  />

                  <Divider />

                  <Data
                    label="Job Time"
                    value={H.fmtTime(job.jobTimeMin)}
                    onClick={msgHelper('duration')}
                  />
                  <Data
                    label="Muscle Time"
                    value={H.fmtTime(job.muscleTimeMin)}
                  />
                  <Data label="Travel Time" value={H.fmtTime(job.travelTime)} />
                  <Data
                    label="Distance"
                    value={H.fmtDist(job.distance)}
                    onClick={msgHelper('mileage')}
                  />

                  <Divider />

                  <Data
                    label="Hourly"
                    value={job.hourly ? 'Present' : 'Absent'}
                  />
                  <Data
                    label="1 Man"
                    value={`Flexi: ${H.fmtPrice(
                      job.flexiPriceOne
                    )} / Specific: ${H.fmtPrice(job.specificPriceOne)}`}
                    onClick={msgHelper('priceOne')}
                  />
                  <Data
                    label="2 Men"
                    value={`Flexi: ${H.fmtPrice(
                      job.flexiPriceTwo
                    )} / Specific: ${H.fmtPrice(job.specificPriceTwo)}`}
                    onClick={msgHelper('priceTwo')}
                  />

                  <Divider />

                  <Data label="Pickup City" value={job.pickupCity} />
                  <Data label="Delivery City" value={job.deliveryCity} />
                  <Data label="Distance Type" value={job.typeDistance} />
                  <Data
                    label="Congestion"
                    value={job.typeCongestion ? 'Present' : 'Absent'}
                  />
                </>
              )
            }
          ]}
        />
      </Body>
      <Footer>
        <Button
          icon={state.globalExpanded === 'expanded' ? 'compress' : 'expand'}
          onClick={() => toggleGlobalExpanded()}
        />
        <Button
          disabled={noUnsavedChanges}
          loading={loading}
          color={noUnsavedChanges ? null : 'green'}
          content="Save"
          icon="save"
          labelPosition="right"
          onClick={handleSave}
        />
      </Footer>
    </Base>
  );
};

export default JobFom;
