import React from 'react';
import styled, { css } from 'styled-components';
import { layout, space, border, flexbox } from 'styled-system';

import { MENU_HEIGHT } from 'utils/constants';

const Container = styled.div`
  ${layout}
  ${space}
  ${border}
  ${flexbox}

  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  position: relative;
  z-index: ${props => props.z};
  cursor: ${props => props.cursor};

  ${props =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
      text-align: center;
    `}

  ${props =>
    props.basic &&
    css`
      background: transparent;
      box-shadow: none;
    `}

  ${props =>
    props.secondary &&
    css`
      background: #f9fafb;
    `}
`;

const Card = ({
  width = '480px',
  height = MENU_HEIGHT,
  borderRadius = '0px',
  z = 0,
  secondary,
  basic,
  flex,
  children,
  ...otherProps
}) => (
  <Container
    width={width}
    height={height}
    borderRadius={borderRadius}
    secondary={secondary}
    basic={basic}
    z={z}
    flex={flex}
    {...otherProps}
  >
    {children}
  </Container>
);

export default Card;
