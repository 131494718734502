import { css } from 'glamor';
export default css({
  '& .ui.button.filter-button': {
    backgroundColor: '#fff !important',
    margin: '0 0.25rem'
  },
  '& .ui.input.search-input': {
    marginRight: '0.25rem',
    marginTop: 1,
    height: 36,
    maxWidth: '14rem'
  },
  '& .menu.filter-menu': {
    '& .item': {
      textTransform: 'capitalize'
    }
  }
});
