import React from 'react';
import styled from 'styled-components';

import { EmptyState } from 'components/v2';
import { TH_WIDTH } from 'utils/constants';
import imgSrc from 'assets/images/outdoor_billboard.jpg';

const TableBase = styled.table`
  border-collapse: collapse;
  width: 100%;

  & th {
    font-size: 12px;
    color: #21ba45;
    font-weight: 900;
    margin: 0.6rem;
    text-transform: uppercase;
    width: ${TH_WIDTH};
    text-align: left;
  }

  & tr {
    height: 3rem;
    /* cursor: pointer; */
  }

  & tr.error {
    color: #db2828;
    font-weight: 700;
  }

  & :not(thead) tr:nth-child(odd) {
    background-color: #f4f6f8;
  }

  & td {
    border: none;
    text-transform: capitalize;
  }

  & td:first-child,
  th:first-child {
    width: calc(${TH_WIDTH} + 2rem);
    padding-left: 2rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const Table = ({
  fieldsData,
  tableData,
  rowIdentifier,
  onClick = console.log
}) => {
  if (!tableData.length) {
    return (
      <EmptyState
        title="Create New Route"
        subtitle="Create lowest emission green route"
        imgSrc={imgSrc}
        imgSize="300px"
      />
    );
  }
  return (
    <TableBase>
      <thead>
        <tr>
          {fieldsData.map(x => (
            <th key={x.label}>{x.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map(r => (
          <tr
            key={r[rowIdentifier]}
            className={r.error ? 'error' : ''}
            onClick={() => onClick(r[rowIdentifier])}
          >
            {fieldsData.map(x => (
              <td key={x.label}>
                {(x.hook ? x.hook(r[x.target]) : r[x.target]) || '-'}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableBase>
  );
};

export default Table;
