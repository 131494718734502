import { createContext } from 'react';

export const AppContext = createContext({
  user: {},
  navigation: {},
  handleLogout: () => {},
  vendors: [],
  network: {
    connectionstatus: '',
    description: '',
    eventStatus: ''
  },
  eventChannels: [],
  handleEvents: () => {}
});
