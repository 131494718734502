import React from 'react';
import styled from 'styled-components';

import { ACTION_HEADER_HEIGHT } from 'utils/constants';

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${props => props.height};
`;

const Img = styled.img`
  width: ${props => props.size};
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #212b36;
  font-weight: normal;
  margin: 16px 10px 10px;
`;

const Subtitle = styled.h3`
  font-size: 14px;
  text-align: center;
  color: #637381;
  font-weight: normal;
  margin: 0;
  max-width: 250px;
  margin-bottom: 1.2rem;
`;

const EmptyState = ({
  title,
  subtitle,
  img,
  imgSrc,
  imgSize = '250px',
  action,
  height = `calc(100% - ${ACTION_HEADER_HEIGHT})`
}) => {
  const src = imgSrc ? imgSrc : require(`assets/images/${img}.svg`).default;
  return (
    <Base height={height}>
      <Img src={src} size={imgSize} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {action}
    </Base>
  );
};

export default EmptyState;
