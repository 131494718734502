import React from 'react';
import styled from 'styled-components';

import { CHAT_INPUT_HEIGHT } from 'utils/constants';

const Container = styled.div`
  position: absolute;
  bottom: ${CHAT_INPUT_HEIGHT};
  margin-left: 0.4rem;
  font-size: 0.7rem;
  background: #ffffff94;
  width: inherit;
  z-index: 999;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
`;

const DotsBase = styled.span`
  position: relative;
  margin-left: 0.5rem;
  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }
    30% {
      transform: translateY(-5px);
    }
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 3px;
  background: #4f4f4f;
  animation: wave 1s linear infinite;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const TypingIndicator = () => (
  <Container>
    <b>Customer</b> is typing
    <DotsBase>
      <Dot />
      <Dot />
      <Dot />
    </DotsBase>
  </Container>
);

export default TypingIndicator;
