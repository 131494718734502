import React from 'react';
import styled from 'styled-components';
import logger from 'utils/logger';

import { Icon } from 'semantic-ui-react';
import { CHAT_INPUT_HEIGHT } from 'utils/constants';

const InputContainer = styled.div`
  display: flex;
  margin: 1rem;
  height: calc(${CHAT_INPUT_HEIGHT} - 2rem);
  border: 1px solid #e0e1e2;
  border-radius: 4px;
`;

const InputField = styled.textarea`
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  padding: 1rem 0rem 1rem 1rem;
  outline: none;
  resize: none;
  color: rgba(0, 0, 0, 0.8);
`;

const SendButton = styled.button`
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  background: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin: 1px;
`;

const hLog = logger({ module: 'hotkey' });

const ChatInput = ({ onSend, onClick, inputRef, value, setValue }) => {
  const onChange = e => {
    setValue(e.target.value);
  };

  const submit = () => {
    if (value.trim()) {
      onSend(value.trim());
      setValue('');
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submit();
      inputRef.current.blur();
      setValue('');
    } else if (e.key === 'Escape') {
      hLog.info('escape');
      inputRef.current.blur();
    }
  };

  return (
    <InputContainer>
      <InputField
        placeholder="Please enter your message..."
        value={value || ''}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClick={onClick}
        ref={inputRef}
      />
      <SendButton onClick={submit}>
        <Icon name="paper plane" />
      </SendButton>
    </InputContainer>
  );
};

export default ChatInput;
