import React from 'react';
import styled from 'styled-components';
import logger from 'utils/logger';

import { Icon } from 'semantic-ui-react';
import { CHAT_INPUT_HEIGHT } from 'utils/constants';

const InputContainer = styled.div`
  display: flex;
  padding: 1rem;
  height: ${CHAT_INPUT_HEIGHT};
`;

const InputField = styled.textarea`
  border: none;
  background: #f5f5f5;
  border-radius: 1rem 0rem 0rem 1rem;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  outline: none;
  resize: none;
`;

const SendButton = styled.button`
  width: 60px;
  cursor: pointer;
  border-radius: 0rem 1rem 1rem 0rem;
  background: #f5f5f5;
  border: none;
  outline: none;
`;

const hLog = logger({ module: 'hotkey' });

const Input = ({ onSend, onClick, inputRef, value, setValue }) => {
  const onChange = e => {
    setValue(e.target.value);
  };

  const submit = () => {
    if (value.trim()) {
      onSend(value.trim());
      setValue('');
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey) {
      hLog.info('shift+enter');
      submit();
      inputRef.current.blur();
      setValue('');
    } else if (e.key === 'Escape') {
      hLog.info('escape');
      inputRef.current.blur();
    }
  };

  return (
    <InputContainer>
      <InputField
        placeholder="Please enter your message..."
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClick={onClick}
        ref={inputRef}
      />
      <SendButton onClick={submit}>
        <Icon color="blue" name="send" />
      </SendButton>
    </InputContainer>
  );
};

export default Input;
