import { css } from 'glamor';

export default css({
  height: '100%',
  '& .header.ui.segment': {
    borderRadius: 0,
    height: '50px',
    marginTop: '-1px'
  },
  '& .title.ui.segment': {
    marginTop: '-1px'
  },
  '& .messages.ui.segment': {
    overflowX: 'hidden',
    paddingBottom: 0
  }
});
