import { css } from 'glamor';

export default css({
  '& .contact': {
    marginLeft: '0.3rem'
  },
  '& .ui.dropdown': {
    '& .menu': {
      maxHeight: '16rem',
      minWidth: '15rem !important'
    }
  }
});
