import React from 'react';
import styled from 'styled-components';

import { MENU_HEIGHT, SIDEBAR_WIDTH } from 'utils/constants';

const Base = styled.div`
  width: ${SIDEBAR_WIDTH};
  height: calc(100vh - ${MENU_HEIGHT});
  padding: 2rem;
`;

const MenuHeader = styled.div`
  font-size: 12px;
  color: #21ba45;
  font-weight: 900;
  margin: 0.6rem;
  text-transform: uppercase;
`;

const MenuItemBase = styled.div`
  padding: 0.6rem;
  margin: 0.3rem 0;
  cursor: pointer;
  border-radius: 4px;
  background: ${props =>
    props.active ? 'rgba(63, 63, 68, 0.08)' : 'transparent'};
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;

  &:hover {
    background: rgba(63, 63, 68, 0.08);
  }
`;

const Label = styled.span`
  background: ${props => (props.active ? '#f4f6F8' : '#e5e5e5')};
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding: 1px 7px;
  float: right;
`;

const MenuItem = ({ content, label, active, onClick }) => {
  return (
    <MenuItemBase active={active} onClick={onClick}>
      {content}
      {label !== undefined && <Label active={active} children={label} />}
    </MenuItemBase>
  );
};

const Spacer = styled.div`
  height: 2rem;
`;

const Sidebar = ({ jobs = {}, routes = {}, onUpdate, active }) => {
  const getProps = id => ({
    onClick: () => onUpdate(id),
    active: id === active
  });

  return (
    <Base>
      <MenuHeader>Jobs</MenuHeader>
      <MenuItem
        content="Today"
        label={jobs.today || 0}
        {...getProps('jobs/today')}
      />
      <MenuItem
        content="Tomorrow"
        label={jobs.tomorrow || 0}
        {...getProps('jobs/tomorrow')}
      />
      <MenuItem
        content="Next Week"
        label={jobs.nextWeek || 0}
        {...getProps('jobs/nextWeek')}
      />
      <Spacer />
      <MenuHeader>Routes</MenuHeader>
      <MenuItem
        content="Today"
        label={routes.today || 0}
        {...getProps('routes/today')}
      />
      <MenuItem
        content="Tomorrow"
        label={routes.tomorrow || 0}
        {...getProps('routes/tomorrow')}
      />
      <Spacer />
      <MenuHeader>Controls</MenuHeader>
      <MenuItem
        content="Route Creation"
        {...getProps('controls/routeCreation')}
      />
    </Base>
  );
};

export default Sidebar;
