import React, { useState, useEffect, useCallback } from 'react';
import { useImmerReducer } from 'use-immer';
import styled from 'styled-components';
import { utc as moment } from 'moment';
import * as R from 'ramda';
import logger from 'utils/logger';

import { Loader } from 'semantic-ui-react';
import { Navbar, DefaultBase, Text, Table, EmptyState } from 'components/v2';
import { Sidebar, Creation, RouteView } from './components';

import client from 'api/client';
import { MENU_HEIGHT, SIDEBAR_WIDTH } from 'utils/constants';
import { jobFields, DBReducer } from './helpers';
import imgSrc from 'assets/images/outdoor_billboard.jpg';

const log = logger({ module: 'routes' });

const dateISO = (d = 0) =>
  moment({ h: 0, m: 0, s: 0 }).add(d, 'd').toISOString();
const dateFMT = (d = 0) => moment().add(d, 'd').format('YYYY-MM-DD');

const todayISO = dateISO();
const tomorrowISO = dateISO(1);
const dayAfterISO = dateISO(2);

const todayFMT = dateFMT();
const nextWeekFMT = dateFMT(7);

const filterGroupWpt = date =>
  R.pipe(
    R.filter(x => x.date === date),
    R.groupBy(R.prop('driver'))
  );

const Body = styled.div`
  display: flex;
  height: calc(100% - ${MENU_HEIGHT});
`;

const Stage = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  width: calc(100% - ${SIDEBAR_WIDTH});
  height: 100%;
  padding: ${props => (props.padded ? '1rem 2rem' : '')};
  overflow-y: auto;
`;

const StyledLoader = styled(Loader)`
  margin-top: 3rem !important;
`;

const Routes = () => {
  const [activeView, setActiveView] = useState('routes/tomorrow');
  const [loading, setLoading] = useState(true);
  const [jobs, dispatchJobs] = useImmerReducer(DBReducer, []);
  const [waypoints, dispatchWaypoints] = useImmerReducer(DBReducer, []);
  const [drivers, dispatchDrivers] = useImmerReducer(DBReducer, []);

  const jobsService = client.service('/jobs');
  const wptService = client.service('/routes/waypoints');
  const driverService = client.service('/vendors');

  const initialQuery = useCallback(async () => {
    const jobPromise = jobsService
      .find({
        query: {
          fulfilmentDateStart: todayFMT,
          fulfilmentDateEnd: nextWeekFMT
        }
      })
      .then(res => res.data);

    const wptPromise = wptService
      .find({
        query: {
          date: {
            $gte: todayISO,
            $lt: dayAfterISO
          },
          $sort: {
            sequence: 1
          }
        }
      })
      .then(res => res.data);

    // TODO fetch from app context
    const driverPromise = driverService
      .find({
        query: {
          active: true,
          $limit: 100
        }
      })
      .then(res => res.data);

    const res = await Promise.all([jobPromise, wptPromise, driverPromise]);

    dispatchJobs({ type: 'created', data: res[0] });
    dispatchWaypoints({ type: 'created', data: res[1] });
    dispatchDrivers({ type: 'created', data: res[2] });

    setLoading(false);
  }, []);

  const onNavUpdate = id => {
    setActiveView(id);
  };

  const subscribe = useCallback(() => {
    jobsService.on('created', res => {
      dispatchJobs({ type: 'created', _id: res._id, data: res });
    });
    jobsService.on('patched', res => {
      dispatchJobs({ type: 'patched', _id: res.job._id, data: res.job });
    });
    log.info('✅ jobs updates');

    wptService.on('created', res => {
      dispatchWaypoints({ type: 'created', _id: res._id, data: res });
    });
    wptService.on('patched', res => {
      dispatchWaypoints({ type: 'patched', _id: res._id, data: res });
    });
    wptService.on('removed', res => {
      dispatchWaypoints({ type: 'removed', _id: res._id });
    });
    log.info('✅ waypoints updates');
  }, []);

  const unsubscribe = useCallback(() => {
    jobsService.off('created');
    jobsService.off('patched');
    log.info('⛔ jobs updates');

    wptService.off('created');
    wptService.off('patched');
    wptService.off('removed');
    log.info('⛔ waypoints updates');
  }, []);

  useEffect(() => {
    initialQuery();
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe]);

  const todayJobs = jobs.filter(x => x.daysToFulfilment === 0);
  const tomorrowJobs = jobs.filter(x => x.daysToFulfilment === 1);
  const nextWeekJobs = jobs.filter(x => x.daysToFulfilment > 1);

  const todayWaypoints = filterGroupWpt(todayISO)(waypoints);
  const tomorrowWaypoints = filterGroupWpt(tomorrowISO)(waypoints);

  return (
    <DefaultBase>
      <Navbar>
        <Text fontSize="14px">
          FMS <strong>/ Routes</strong>
        </Text>
      </Navbar>
      <Body>
        <Sidebar
          active={activeView}
          jobs={{
            today: todayJobs.length,
            tomorrow: tomorrowJobs.length,
            nextWeek: nextWeekJobs.length
          }}
          routes={{
            today: Object.keys(todayWaypoints).length,
            tomorrow: Object.keys(tomorrowWaypoints).length
          }}
          onUpdate={onNavUpdate}
        />
        <Stage padded={!activeView.includes('routes')}>
          {loading ? (
            <StyledLoader active inline="centered" content="Fetching data..." />
          ) : activeView === 'jobs/today' ? (
            <Table
              fieldsData={jobFields}
              tableData={todayJobs}
              rowIdentifier="jobId"
            />
          ) : activeView === 'jobs/tomorrow' ? (
            <Table
              fieldsData={jobFields}
              tableData={tomorrowJobs}
              rowIdentifier="jobId"
            />
          ) : activeView === 'jobs/nextWeek' ? (
            <Table
              fieldsData={jobFields}
              tableData={nextWeekJobs}
              rowIdentifier="jobId"
            />
          ) : activeView === 'routes/today' ? (
            <RouteView
              drivers={drivers}
              waypoints={todayWaypoints}
              jobs={todayJobs}
            />
          ) : activeView === 'routes/tomorrow' ? (
            <RouteView
              drivers={drivers}
              waypoints={tomorrowWaypoints}
              jobs={tomorrowJobs}
            />
          ) : activeView === 'controls/routeCreation' ? (
            <Creation />
          ) : (
            <EmptyState
              title="Create New Route"
              subtitle="Create lowest emission green route"
              imgSrc={imgSrc}
              imgSize="300px"
            />
          )}
        </Stage>
      </Body>
    </DefaultBase>
  );
};

export default Routes;
