import * as R from 'ramda';
import moment from 'moment';
import { colorMap } from 'utils';

const menFormatter = x => (x === 'two_without_help' ? 2 : 1);
const upperCase = x => (x || '').toUpperCase();

export const jobFields = [
  { label: 'Job Id', target: 'jobId' },
  { label: 'Pickup', target: 'pickup', hook: upperCase },
  { label: 'Delivery', target: 'delivery', hook: upperCase },
  { label: 'Time From', target: 'timeWindowStart' },
  { label: 'Time To', target: 'timeWindowEnd' },
  { label: 'Price', target: 'priceFragment' },
  { label: 'Men', target: 'quoteOption', hook: menFormatter }
];

export const DBReducer = (db, action) => {
  const { type, _id, data } = action;
  switch (type) {
    case 'created':
      if (Array.isArray(data)) {
        db.push(...data);
      } else {
        db.push(data);
      }
      return db;
    case 'patched':
      const pIdx = db.findIndex(x => x._id === _id);
      if (pIdx >= 0) {
        db[pIdx] = data;
      } else {
        db.push(data);
      }
      return db;
    case 'removed':
      const rIdx = db.findIndex(x => x._id === _id);
      if (rIdx >= 0) {
        db.splice(rIdx);
      }
      return db;
    case 'overwrite':
      return data;
    default:
      return;
  }
};

const getTimeObj = ({ final = {}, projected = {} }, type) =>
  moment(final[type] || projected[type], 'HH:mm');

const getCoords = arr => arr.map(num => Number(num.toFixed(7)));

export const normalizeRoutes = ({ waypoints, drivers, jobs }) => {
  const driverIds = Object.keys(waypoints);
  return driverIds.map((_id, n) => {
    const driver = drivers.find(x => x._id === _id);
    const wpts = waypoints[_id];

    const distance = R.pipe(R.pluck('distance'), R.sum, Math.round)(wpts);
    const arrival = getTimeObj(wpts[0].timings, 'arrival');
    const finish = getTimeObj(wpts[wpts.length - 1].timings, 'finish');
    const duration = moment.duration(finish.diff(arrival)).humanize();

    const wptsWithJob = wpts.map(wpt => {
      const job = jobs.find(j => j.jobId === wpt.jobId);
      const isPickup = wpt.type === 'pickup';

      return {
        _id: wpt._id,
        jobId: wpt.jobId,
        status: wpt.status || 'pending',
        type: wpt.type,
        sequence: wpt.sequence,
        coordinates: getCoords(
          isPickup ? job.pickupCoordinates : job.deliveryCoordinates
        ),
        arrival: getTimeObj(wpt.timings, 'arrival').format('hh:mm A'),
        finish: getTimeObj(wpt.timings, 'finish').format('hh:mm A'),
        postcode: isPickup ? job.pickup : job.delivery
        // address: isPickup ? job.pickupAddress : job.deliveryAddress,
        // floors: isPickup ? job.pickupFloors : job.deliveryFloors,
        // lift: isPickup ? job.pickupLift : job.deliveryLift,
        // inventory: job.inventory
      };
    });

    return {
      _id,
      driver,
      color: colorMap[n],
      waypoints: wptsWithJob,
      distance,
      arrival: arrival.format('hh:mm A'),
      finish: finish.format('hh:mm A'),
      duration
    };
  });
};
