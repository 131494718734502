import * as JsSearch from 'js-search';
import outdent from 'outdent';

export const list = [
  {
    label: 'all_inclusive',
    message:
      'The quote is a flat price for moving your given item list. No hidden charges at all 🙂'
  },
  {
    label: 'asking_quote',
    message:
      'I will share the quote with you in just a moment, I need to know a few details from you to calculate it'
  },
  {
    label: 'call_customer',
    message:
      'I think it would be more convenient to discuss this on a call so that I can give you a quote. I will just give you a call in a few minutes.'
  },
  {
    label: 'cancellation_charges',
    message:
      'You can notify us upto a day in advance. There are no cancellation charges 🙂'
  },
  {
    label: 'confirm_job_by_when',
    message:
      'We request you to confirm at the earliest possible, and 24 hours before the job 🙂'
  },
  {
    label: 'driver_details',
    message: 'I will share the details of the driver one day before your move'
  },
  {
    label: 'extra_inventory_charges',
    message:
      'You can tell us later about exact inventory. If there are few small items, we will carry them free of charge. Beyond that, there will be a nominal charge per item. In any case, it will not be an issue 🙂'
  },
  {
    label: 'how_should_i_pay',
    message:
      'You can pay the driver in cash on the day of your move, or you can also make an online payment by credit card or paypal.  You have the complete flexibility to choose. You will be provided with a receipt. Its inclusive of  VAT. The booking is free, no deposit required right now 🙂'
  },
  {
    label: 'insurance_included',
    message:
      'Yes, all our moves are insured! The crews are trained and licensed, and our vans are equipped with blankets and straps to ensure highest safety. Do not worry, your items will be moved with utmost care 🙂'
  },
  {
    label: 'no_of_van_trips',
    message:
      'We have vans of various sizes available. We will send a large enough van to fit all your items - do not worry, you can leave it upto us 🙂 You will not be charged anything extra if it takes more than one trip to do your job.'
  },
  {
    label: 'packaging',
    message:
      'Unfortunately, we do not do packaging at the moment. However, I can send you some links whereby you can purchase packaging material and boxes at low prices 🙂'
  },
  {
    label: 'travel_in_van',
    message: 'You are welcome to travel in the van with the driver 🙂'
  },
  {
    label: 'tvm_phone_share_only',
    message:
      'Sure, you can reach us at 020-3289-7299. However, if you can provide your contact details well get in touch with you directly and it will be much easier to coordinate'
  },
  {
    label: 'urgent_extra',
    message: 'No, there will be no extra charge based on the date of the job 🙂'
  },
  {
    label: 'feedback',
    message: outdent`
      Hello,

      We hope your move went smoothly. On a scale of 1-10 how likely are you to recommend our services to your friends and family.

      Kind regards,
      The Van & Man
    `
  }
];

const jsSearch = new JsSearch.Search('label');
jsSearch.addIndex('label');
jsSearch.addIndex('message');
jsSearch.addDocuments(list);

export default {
  list,
  search: x => jsSearch.search(x)
};
