import * as R from 'ramda';
import React, { Component } from 'react';
import styled from 'styled-components';
import logger from 'utils/logger';

import { client } from 'api';
import { Card, ChannelSelection, Chat, JobForm } from 'components/v2';
import { DecisionBox } from 'containers/Booking/components';
import { Icon, Modal } from 'semantic-ui-react';

const log = logger({ module: 'tasks' });

const Base = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  filter: grayscale(${props => (props.isTestJob ? '100%' : '0%')});
`;

const HeaderBase = styled.div`
  display: flex;
  width: 100%;
  background: #f9fafb;
  border-bottom: 1px solid #e9e9ea;
  align-items: center;
  padding-right: 0.5rem;

  & > i.icon {
    color: rgba(0, 0, 0, 0.8);
    height: 100%;
    padding: 1rem;
    cursor: pointer;
  }
`;

export default class JobPane extends Component {
  state = {
    channel: 'whatsapp',
    body: '',
    loading: false,
    activeFlagId: '',
    activeFlag: null
  };

  inputRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.jobId !== prevProps.jobId) {
      this.initializeJob();
    }
  }

  componentDidMount() {
    this.initializeJob();
  }

  initializeJob = async () => {
    if (!this.props.initializedData) {
      const res = await client
        .service('/customers/sessions')
        .get(this.props.job.sessionId);

      this.props.setInitialized({
        channels: res.channels
      });

      const channelIds = R.pipe(
        R.pick(['email', 'sms', 'webchat', 'whatsapp']),
        R.values
      )(res.channels);

      if (channelIds.length) {
        const messages = await client
          .service('/db/messages')
          .find({
            query: {
              channelId: {
                $in: channelIds
              },
              $sort: {
                createdAt: -1
              },
              $limit: 250
            }
          })
          .then(R.pipe(R.prop('data'), R.reverse));

        this.props.dispatchMessages({ type: 'created', data: messages });
      }
      log.info(`✅ initialized job [${this.props.jobId}]`);
    }
  };

  copyToInput = body => {
    this.setState({ body });
    this.inputRef.current.focus();
  };

  setTreeStatus = status => {
    this.props.onJobUpdate({
      monitorMode: status
    });
  };

  handleTriggerTree = async ({
    from = 'externalTrigger',
    trigger = 'dashboard',
    newJd
  }) => {
    await client.service('/customers/chat/messages').create({
      from,
      trigger,
      sessionId: this.props.job.sessionId,
      attributes: {
        toSend: {
          newJd
        }
      }
    });
    return;
  };

  handleSetActiveFlag = activeFlagId => {
    this.setState({
      activeFlagId,
      activeFlag: R.pipe(
        R.find(m => m._id === activeFlagId),
        R.path(['attributes', 'treeMetadata', 'flags'])
      )(this.props.messages || [])
    });
  };

  handleDeleteFlag = async () => {
    await client.service('/db/messages').patch(this.state.activeFlagId, {
      flagged: false
    });

    this.setState({
      activeFlag: null,
      activeFlagId: ''
    });
  };

  handleJobUpdate = async data => {
    const job = this.props.job;
    const keys = Object.keys(data);
    const key = keys[0];
    const dataExists = job[key] !== undefined && job[key] !== null;
    const statelessEdit =
      key === 'internalNotes' || key === 'monitorMode' || key === 'activeTab';
    const canTrigger = !dataExists && !statelessEdit;

    await client.service('/jobs').patch(this.props.job.jobId, data);

    // send data via tree trigger so typeD can run on it, etc
    if (canTrigger) {
      await this.handleTriggerTree({ newJd: data });
    }
    return;
  };

  handleContextUpdate = async chatContext => {
    await client.service('/customers/updateSessions').create({
      sessionId: this.props.job.sessionId,
      chatContext
    });
    return;
  };

  render() {
    let channelId, messages;
    const allowedChannels = [];
    const monitorMode = R.path(['monitorMode'], this.props.job);
    // const allowedChannels = ['webchat'];

    if (this.props.initializedData) {
      channelId = this.props.initializedData.channels[this.state.channel];
      messages = this.props.messages.filter(x => x.channelId === channelId);
      if (this.props.initializedData.channels.vendor) {
        allowedChannels.push(this.props.initializedData.channels.vendor);
      }
    }

    if (this.props.job.bookingModeOfCommunication === 'whatsapp') {
      allowedChannels.push('whatsapp');
    }

    // if (this.props.job.number) {
    //   allowedChannels.push('sms');
    // }

    // if (this.props.job.email) {
    //   allowedChannels.push('email');
    // }

    return (
      <Base isTestJob={this.props.job.isTestJob}>
        {this.state.activeFlag && (
          <Modal
            open={!!this.state.activeFlag}
            onClose={() => {
              this.setState({ activeFlag: null, activeFlagId: '' });
            }}
            size="small"
          >
            <div style={{ background: '#f4f6f8' }}>
              <DecisionBox
                data={this.state.activeFlag}
                onSend={(body, attrs) =>
                  this.props.onMessageSend(this.props.job.sessionId)({
                    body,
                    channel: this.state.channel,
                    ...attrs
                  })
                }
                onUpdate={this.handleJobUpdate}
                handleContextUpdate={this.handleContextUpdate}
                handleDeleteFlag={this.handleDeleteFlag}
                withFaq={false}
              />
            </div>
          </Modal>
        )}
        <Card width="50%" height="100%">
          <HeaderBase>
            <Icon name="close" onClick={this.props.onTabClose} />
            <ChannelSelection
              active={this.state.channel}
              onUpdate={channel => this.setState({ channel })}
              copyToInput={this.copyToInput}
              allowed={allowedChannels}
              label={`#${this.props.job.jobId} ${this.props.job.name || ''}`}
              withFaq
            />
            <Icon
              name="shield"
              color={monitorMode ? 'red' : null}
              onClick={() => this.setTreeStatus(monitorMode ? false : true)}
            />
            <Icon name="lightning" onClick={this.handleTriggerTree} />
          </HeaderBase>
          <Chat
            messages={messages}
            body={this.state.body}
            setBody={body => this.setState({ body })}
            onSend={body =>
              this.props.onMessageSend(this.props.job.sessionId)({
                body,
                channel: this.state.channel
              })
            }
            inputRef={this.inputRef}
            adjustHeight="50px"
            activeFlag={this.state.activeFlag}
            handleSetActiveFlag={this.handleSetActiveFlag}
          />
        </Card>
        <Card width="50%" height="100%" secondary>
          <JobForm
            key={this.props.job.jobId}
            job={this.props.job}
            onUpdate={this.props.onJobUpdate}
            copyToInput={this.copyToInput}
          />
        </Card>
      </Base>
    );
  }
}
