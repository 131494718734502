import { css } from 'glamor';

export const accordionStyles = css({
  '& .entity-title.title': {
    display: 'flex',
    alignItems: 'center',
    height: '3.3rem',
    '& .entity-id': {
      textTransform: 'capitalize'
    },
    '& .ui.labels': {
      marginLeft: '0.2rem',
      '& .label': {
        margin: 0,
        marginLeft: '0.4rem',
        textTransform: 'capitalize'
      }
    }
  },
  '& .inner-content.content': {
    padding: '0 !important',
    border: 'none'
  }
});

export const tableStyles = css({
  '& .ui.label>.icon': {
    margin: 0
  }
});
