import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { TextArea, Button } from 'semantic-ui-react';

import { inputStyles } from './styles';
import AssistBox from './AssistBox';

class AssistInput extends Component {
  state = { expanded: false, text: '', assistId: '' };

  toggleExpansion = () => {
    const expanded = !this.state.expanded;
    this.props.onExpand(expanded);
    this.setState({ expanded, text: '', assistId: null });
  };

  updateAssist = assist =>
    this.setState({ assistId: assist.id, text: assist.content });

  onSend = () => {
    this.setState({ text: '', assistId: '' });
    this.props.onSend(this.state);
  };

  render() {
    const { expanded, text } = this.state;
    const { buttons = [], sending } = this.props;

    return (
      <div {...inputStyles} className={expanded ? 'expanded' : ''}>
        {expanded && (
          <AssistBox buttons={buttons} onClick={this.updateAssist} />
        )}
        <div className="input-box">
          {!R.isEmpty(buttons) && (
            <Button
              className="toggle"
              basic
              icon="add"
              onClick={this.toggleExpansion}
            />
          )}
          <TextArea
            className="input"
            autoHeight
            value={text}
            onInput={e => this.setState({ text: e.target.value })}
            placeholder={
              expanded
                ? 'Select an assist button...'
                : 'Write your message here...'
            }
          />
          <Button
            className="send"
            icon={expanded ? 'send outline' : 'send'}
            color={expanded ? 'blue' : 'green'}
            disabled={!text}
            onClick={() => this.onSend()}
            loading={sending}
          />
        </div>
      </div>
    );
  }
}

AssistInput.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  onSend: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  sending: PropTypes.bool
};

export default AssistInput;
