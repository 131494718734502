import * as JsSearch from 'js-search';
import * as R from 'ramda';

import { jobLabels } from 'utils';

export const getFilter = f => {
  const all = x => x;
  const pending = x => x.status === 'pending';
  const escalated = x => x.status === 'escalated';
  const done = x => x.status === 'done';
  const ignored = x => x.status === 'ignored';

  const map = {
    pending,
    escalated,
    done,
    ignored,
    all
  };

  return R.filter(map[f] || map.pending);
};

export const getGrouping = () => {
  return R.groupBy(R.path(['entities', 'job']));
};

export const withJobs = jobs => taskGroups => {
  const jobIds = Object.keys(taskGroups);

  const normalize = jobId => {
    const tasks = taskGroups[jobId];
    const job = jobs.find(x => x.jobId === Number(jobId));
    const recent = R.pipe(R.head, R.prop('createdAt'))(tasks);
    const d2ff = job ? job.daysToFulfilment : null;
    const isTestJob = job ? job.isTestJob : null;

    const labels = {
      tasks: {
        content: tasks.length
      },
      ...jobLabels(job)
    };

    return {
      jobId,
      labels,
      tasks,
      d2ff,
      recent,
      isTestJob
    };
  };

  return jobIds.map(normalize);
};

export const getSort = s => groups => {
  if (s === 'fulfilmentDate') {
    return R.sortWith([R.ascend(R.prop('d2ff'))], groups);
  }

  if (s === 'createdAt') {
    return R.sortWith([R.descend(R.prop('recent'))], groups);
  }

  return groups;
};

const jsSearch = new JsSearch.Search('type');
jsSearch.addIndex('type');
jsSearch.addIndex('status');
jsSearch.addIndex(['attributes', 'content']);
jsSearch.addIndex(['attributes', 'flag', 'type']);
jsSearch.addIndex(['attributes', 'flag', 'message']);
jsSearch.addIndex(['entities', 'job']);

export const getSearch = s => data => {
  if (s) {
    jsSearch.addDocuments(data);
    const result = jsSearch.search(s);
    return result;
  }
  return data;
};

export const statusOptions = ['done', 'pending', 'ignored', 'escalated'].map(
  x => ({
    key: x,
    value: x,
    text: x
  })
);

export const wds = [0.21, 0.22, 0.22, 0.15, 0.2];

export const iconNameMap = {
  general: 'warning circle',
  typeA: 'comment',
  typeB: 'comment',
  entity: 'suitcase',
  intent: 'hashtag'
};

export const formatArtifacts = str =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace('gl_', '')
    .replace(/_/g, ' ');
