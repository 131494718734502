import React from 'react';
import { Navbar, EmptyState, DefaultBase, Text } from 'components/v2';

import vanImg from 'assets/images/van.png';

const Home = () => {
  return (
    <DefaultBase>
      <Navbar>
        <Text fontSize="14px">
          <strong>Home</strong>
        </Text>
      </Navbar>
      <EmptyState
        title="Greenvan Dashboard"
        subtitle="Click on the Menu Icon on top left to navigate the dashboard"
        imgSrc={vanImg}
        imgSize="450px"
      />
    </DefaultBase>
  );
};

export default Home;
