import React from 'react';
import styled, { css } from 'styled-components';
import { flexbox } from 'styled-system';

import { Label, List, Dropdown, Divider, Icon } from 'semantic-ui-react';
import { formatTime } from 'utils';
import { wds, statusOptions } from './helpers';

const TaskGroupBase = styled.div`
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  min-height: 44px;

  ${props =>
    props.active &&
    css`
      border-top: 3px solid #21ba45;
    `};
`;

const TaskGroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
`;

const Flex = styled.div`
  display: flex;
  ${flexbox}
`;

const BoldText = styled.div`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
`;

const IconContainer = styled.span`
  & i {
    margin-right: ${props => props.marginRight} !important;
  }
`;

const StyledListItem = styled(List.Item)`
  display: flex !important;
  &:not(:first-of-type) {
    padding-top: 1rem !important;
  }

  & .header {
    text-transform: capitalize;
  }
`;

const BottomRightContent = styled(List.Content)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 0.5rem;
`;

const Meta = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: normal;
  margin-left: 0.5rem;
`;

const TaskRow = ({ task, onTaskUpdate }) => {
  const { status, createdAt } = task;
  const { content } = task.attributes;

  return (
    <StyledListItem>
      <Icon name="warning circle" color="grey" />
      <List.Content>
        <List.Header>
          New Message
          <Meta>{formatTime(createdAt)}</Meta>
        </List.Header>
        <List.Description>{content}</List.Description>
      </List.Content>
      <BottomRightContent>
        <Dropdown
          selection
          compact
          size="small"
          options={statusOptions}
          value={status}
          onChange={(_, e) => onTaskUpdate(e.value)}
        />
      </BottomRightContent>
    </StyledListItem>
  );
};

const VendorTaskGroup = ({
  name,
  number,
  profileData,
  tasks = [],
  expanded,
  setActive,
  toggleLocalExpanded,
  selected,
  onTaskUpdate
}) => {
  return (
    <TaskGroupBase active={selected}>
      <TaskGroupHeader>
        <Flex flex={wds[0]}>
          <BoldText>{name}</BoldText>
        </Flex>
        <Flex flex={wds[1]}>
          <Label size="small">{profileData.city}</Label>
        </Flex>
        <Flex flex={wds[2]}>
          <Label size="small">{number || 'Unavailable'}</Label>
        </Flex>
        <Flex flex={wds[3]}>
          <Label size="small">{tasks.length}</Label>
        </Flex>
        <Flex flex={wds[4]} justifyContent="flex-end">
          <IconContainer marginRight="1rem">
            <Icon color="grey" link name="external" onClick={setActive} />
          </IconContainer>
          <Icon
            link
            color="grey"
            name={expanded ? 'chevron down' : 'chevron right'}
            onClick={toggleLocalExpanded}
          />
        </Flex>
      </TaskGroupHeader>

      {tasks.length > 0 && expanded && (
        <div>
          <Divider />
          <List divided relaxed>
            {tasks.map(task => (
              <TaskRow
                key={task._id}
                task={task}
                onTaskUpdate={status => onTaskUpdate({ _id: task._id, status })}
              />
            ))}
          </List>
        </div>
      )}
    </TaskGroupBase>
  );
};

export default VendorTaskGroup;
