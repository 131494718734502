import * as R from 'ramda';
import React, { Component } from 'react';
import styled from 'styled-components';
import logger from 'utils/logger';

import { client } from 'api';
import { ChannelSelection, Chat } from 'components/v2';

const log = logger({ module: 'vendors' });

const Base = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default class VendorChat extends Component {
  state = {
    channel: 'slack',
    body: ''
  };

  inputRef = React.createRef();

  componentDidMount() {
    this.initializeChat();
  }

  componentDidUpdate(prevProps) {
    if (this.props.vendorId !== prevProps.vendorId) {
      this.initializeChat();
    }
  }

  async initializeChat() {
    if (!this.props.initialized) {
      const channelIds = [
        `sms:${this.props.vendorId}`,
        `email:${this.props.vendorId}`,
        `slack:${this.props.vendorId}`
      ];

      const messages = await client
        .service('/db/messages')
        .find({
          query: {
            channelId: {
              $in: channelIds
            },
            $sort: {
              createdAt: -1
            },
            $limit: 250
          }
        })
        .then(R.pipe(R.prop('data'), R.reverse));

      this.props.dispatchMessages({ type: 'created', data: messages });
      this.props.setAsInitialized();

      log.info(`✅ initialized chat [${this.props.vendorId}]`);
    }
  }

  render() {
    const allowedChannels = ['slack'];
    const messages = this.props.messages.filter(x =>
      x.channelId.includes(this.props.vendorId)
    );

    return (
      <Base>
        <ChannelSelection
          active={this.state.channel}
          onUpdate={channel => this.setState({ channel })}
          allowed={allowedChannels}
          label={`- ${this.props.vendor.name}`}
        />
        <Chat
          body={this.state.body}
          setBody={body => this.setState({ body })}
          messages={messages}
          onSend={body =>
            this.props.onMessageSend({ body, channel: this.state.channel })
          }
          inputRef={this.inputRef}
          adjustHeight="50px"
        />
      </Base>
    );
  }
}
