import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
import sizeMe from 'react-sizeme';
import PropTypes from 'prop-types';

import { CommHubHeader, MessageGroup, AssistInput } from 'components/v1';
import styles from './styles';

const getHeight = ({ expanded, size }) => {
  const total = size.height || 570;
  const header = 50;
  const input = 75;
  const extra = 14; // 1 rem comes from react-custom-scrollbars?
  const assistBox = 185;
  const taken = header + input + extra;
  return expanded ? total - taken - assistBox : total - taken;
};

class CommHub extends Component {
  state = { expanded: false };
  onExpand = expanded => this.setState({ expanded });
  componentDidUpdate = () => {
    // TODO better fired on new message added
    this.refs.scrollbars.scrollToBottom();
  };
  render() {
    const {
      hub,
      channelConfig,
      messages,
      assistButtons,
      size,
      onSend,
      updateChannel,
      sending
    } = this.props;

    const { expanded } = this.state;
    const bodyHeight = getHeight({ expanded, size });

    return (
      <div {...styles}>
        <Segment className="header" attached="top">
          <CommHubHeader
            hub={hub}
            channelConfig={channelConfig}
            updateChannel={updateChannel}
          />
        </Segment>
        <Segment className="messages" attached>
          <Scrollbars autoHide style={{ height: bodyHeight }} ref="scrollbars">
            <MessageGroup messages={messages} />
          </Scrollbars>
        </Segment>
        <AssistInput
          buttons={assistButtons}
          onSend={onSend}
          onExpand={this.onExpand}
          sending={sending}
        />
      </div>
    );
  }
}

CommHub.propTypes = {
  hub: PropTypes.object,
  channelConfig: PropTypes.arrayOf(PropTypes.object),
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  assistButtons: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.shape({ height: PropTypes.number.isRequired }),
  onSend: PropTypes.func,
  updateChannel: PropTypes.func,
  sending: PropTypes.bool
};

export default sizeMe({ monitorHeight: true })(CommHub);
