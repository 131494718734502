import React, { Component } from 'react';
import styled from 'styled-components';
import GoogleMap from 'google-map-react';
import { Popup } from 'semantic-ui-react';

import { colors } from 'utils';
import { LONDON } from 'utils/constants';
import { ultraLight } from 'utils/mapStyles';

const Marker = styled.div`
  background-color: ${props => props.color};
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.8rem;
  text-align: center;
  padding: 2px;
  margin: -6px;
`;

const MarkerContent = ({ id, status, jobId, postcode, type }) => {
  const trigger = <span>{id}</span>;
  const content = `${jobId} - ${type} / ${postcode.toUpperCase()}`;

  return <Popup trigger={trigger} content={content} inverted basic />;
};

class Map extends Component {
  hackSyncState = { polylines: [] };

  plotRoute = route => {
    const finished = route.waypoints.filter(x => x.status === 'finished');
    const notFinished = route.waypoints.filter(x => x.status !== 'finished');

    if (finished.length) {
      notFinished.unshift(finished[finished.length - 1]);
    }

    const opts = {
      geodesic: true,
      strokeOpacity: 1,
      strokeWeight: 4
    };

    const poly1 = new this.google.maps.Polyline({
      path: finished.map(wpt => ({
        lat: wpt.coordinates[0],
        lng: wpt.coordinates[1]
      })),
      strokeColor: colors[route.color],
      ...opts
    });

    const poly2 = new this.google.maps.Polyline({
      path: notFinished.map(wpt => ({
        lat: wpt.coordinates[0],
        lng: wpt.coordinates[1]
      })),
      strokeColor: this.props.uniform ? colors[route.color] : colors.grey,
      ...opts
    });

    poly1.setMap(this.google.map);
    poly2.setMap(this.google.map);
    this.hackSyncState.polylines = [
      ...this.hackSyncState.polylines,
      poly1,
      poly2
    ];
  };

  plotRoutes = () => {
    this.hackSyncState.polylines.map(pl => pl.setMap(null));
    this.hackSyncState.polylines = [];
    this.props.routes.map(this.plotRoute);
  };

  initialize = google => {
    this.google = google;
    this.plotRoutes();
  };

  componentDidUpdate() {
    if (this.google) {
      this.plotRoutes();
    }
  }

  render() {
    const {
      routes = [],
      center = LONDON,
      zoom = 10,
      draggable = true,
      uniform
    } = this.props;

    const waypoints = [];
    routes.forEach(route => {
      route.waypoints.forEach(wpt => {
        waypoints.push({
          key: wpt._id,
          lat: wpt.coordinates[0],
          lng: wpt.coordinates[1],
          id: wpt.sequence,
          color:
            colors[
              uniform || wpt.status === 'finished'
                ? route.color
                : wpt.status !== 'pending'
                ? 'yellow'
                : 'secondary'
            ],
          status: wpt.status,
          jobId: wpt.jobId,
          type: wpt.type,
          postcode: wpt.postcode
        });
      });
    });

    return (
      <GoogleMap
        bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS }}
        center={center}
        zoom={zoom}
        options={{ styles: ultraLight, disableDefaultUI: true }}
        draggable={draggable}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={this.initialize}
      >
        {waypoints.map(wpt => (
          <Marker {...wpt}>
            <MarkerContent {...wpt} />
          </Marker>
        ))}
      </GoogleMap>
    );
  }
}

export default React.memo(Map);
