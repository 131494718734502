import { useEffect, useReducer } from 'react';
import { Button, Checkbox, Form, Grid, Icon, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { JobFormComponent, Popup, SearchBox } from 'components/v2';
import { ACTION_HEADER_HEIGHT } from 'utils/constants';

const iconNameMap = {
  general: 'warning circle',
  typeA: 'comment',
  typeB: 'comment',
  typeD: 'comment',
  typeE: 'comment',
  customerMute: 'mute',
  entity: 'suitcase',
  intent: 'hashtag'
};

const wd = [1, 2, 5, 6, 2];

const formatArtifacts = (str = '') =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace('gl_', '')
    .replace(/_/g, ' ');

const Container = styled.div`
  padding: 0rem 1rem 1rem 1rem;
  overflow-y: scroll;
  height: calc(100% - ${ACTION_HEADER_HEIGHT});
  & > .ui.grid {
    margin: 0 !important;
  }
`;

const StyledIcon = styled(Icon)`
  margin-top: -5px !important;
  margin-left: 1rem !important;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 2px !important;
`;

const HeaderLabel = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  margin-left: ${props => (props.centered ? '9px' : 'none')};
`;

const Row = styled(Grid.Row)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  padding: 1rem;
  margin-bottom: 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  border-radius: 4px;
`;

const Column = styled(Grid.Column)`
  padding-right: 0rem !important;
`;

const RowLabel = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RowValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExpansionContainer = styled.div`
  padding: 1rem 1rem 0rem 1rem;
  width: 100%;
`;

const Expansion = ({ type, label, value, onChange }) => {
  return (
    <ExpansionContainer>
      <Form>
        {type === 'general' ? (
          <Message content={value} />
        ) : type === 'entity' ? (
          <JobFormComponent
            field={label}
            value={value}
            onChange={onChange}
            noLabel
          />
        ) : (
          <Form.TextArea
            value={value}
            onChange={(_, { value }) => onChange(value)}
          />
        )}
      </Form>
    </ExpansionContainer>
  );
};

const DecisionRow = ({ data, index, dispatch }) => {
  let label, value, formattedValue;

  value = data.value;
  if (data.type === 'entity') {
    const formatted = JobFormComponent.formatter({
      label: data.label,
      value: data.value
    });
    label = formatted.label;
    formattedValue = formatted.value;
  } else {
    label = formatArtifacts(data.label);
  }

  // unhandled jd fields
  if (!label) return null;

  return (
    <Row>
      <Column width={wd[0]}>
        <StyledCheckbox
          checked={data.checked}
          onClick={() =>
            dispatch({ index, type: 'toggleOne', field: 'checked' })
          }
        />
      </Column>
      <Column width={wd[1]}>
        <StyledIcon name={iconNameMap[data.type]} color="grey" />
      </Column>
      <Column width={wd[2]}>
        <RowLabel>{label}</RowLabel>
      </Column>
      <Column width={wd[3]}>
        <Popup
          on="hover"
          trigger={<RowValue>{formattedValue}</RowValue>}
          content={formattedValue}
        />
      </Column>
      <Column width={wd[4]}>
        <StyledIcon
          link
          name="ellipsis vertical"
          color="grey"
          onClick={() =>
            dispatch({ type: 'toggleOne', index, field: 'expanded' })
          }
        />
      </Column>
      {data.expanded && (
        <Expansion
          {...data}
          value={value}
          onChange={value => dispatch({ type: 'updateValue', index, value })}
        />
      )}
    </Row>
  );
};

const reducer = (items, action) => {
  let newItems;
  switch (action.type) {
    case 'toggleOne':
      newItems = [...items];
      newItems[action.index][action.field] =
        !newItems[action.index][action.field];
      return newItems;
    case 'setAll':
      newItems = [...items.map(x => ({ ...x, [action.field]: action.value }))];
      return newItems;
    case 'updateValue':
      newItems = [...items];
      newItems[action.index].value = action.value;
      return newItems;
    case 'reset':
      return action.items;
    default:
      return items;
  }
};

const normalizeFlags = data =>
  data.map(x => ({
    ...x,
    value: x.message || x.value,
    checked: false,
    expanded: true
  }));

const DecisionBox = ({
  data = [],
  copyToInput,
  onSend,
  onUpdate,
  handleContextUpdate,
  handleDeleteFlag,
  withFaq = true
}) => {
  const [items, dispatch] = useReducer(reducer, normalizeFlags(data));

  const checkedNos = items.filter(x => x.checked).length;
  const globalChecked = checkedNos > 0;
  const globalIndeterminateChecked = globalChecked && checkedNos < data.length;

  const toggleGlobalChecked = () => {
    if (globalChecked && !globalIndeterminateChecked) {
      dispatch({ type: 'setAll', field: 'checked', value: false });
    } else {
      dispatch({ type: 'setAll', field: 'checked', value: true });
    }
  };

  const expandedNos = items.filter(x => x.expanded).length;
  const allExpanded = expandedNos === data.length;

  const toggleGlobalExpanded = () => {
    if (allExpanded) {
      dispatch({ type: 'setAll', field: 'expanded', value: false });
    } else {
      dispatch({ type: 'setAll', field: 'expanded', value: true });
    }
  };

  const handleContinue = async () => {
    const selected = items.filter(x => x.checked);
    const jd = {};
    const messages = [];

    // first push typeD
    selected.forEach(x => {
      if (x.type === 'typeD') {
        jd[x.label] = x.value;
        messages.push(x);
      }
    });

    // then rest
    selected.forEach(x => {
      if (x.type === 'entity') {
        jd[x.label] = x.value;
      } else if (
        x.type === 'typeA' ||
        x.type === 'typeB' ||
        x.type === 'typeE' ||
        x.type === 'customerMute'
      ) {
        messages.push(x);
      }
    });

    if (Object.keys(jd).length) {
      onUpdate(jd);
    }

    if (messages.length) {
      messages.map(({ value, ...opts }) =>
        onSend(value, {
          type: opts.messageType,
          attributes: {
            link: opts.link,
            options: opts.options
          }
        })
      );
      handleContextUpdate(messages);
    }

    handleDeleteFlag();
  };

  useEffect(() => {
    dispatch({
      type: 'reset',
      items: normalizeFlags(data)
    });
  }, [data]);

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={wd[0]}>
            <StyledCheckbox
              checked={globalChecked}
              indeterminate={globalIndeterminateChecked}
              onClick={toggleGlobalChecked}
            />
          </Grid.Column>
          <Grid.Column width={wd[1]}>
            <HeaderLabel centered>Type</HeaderLabel>
          </Grid.Column>
          <Grid.Column width={wd[2]}>
            <HeaderLabel>Heading</HeaderLabel>
          </Grid.Column>
          <Grid.Column width={wd[3]}>
            <HeaderLabel>Content</HeaderLabel>
          </Grid.Column>
          <Grid.Column width={wd[4]}>
            <StyledIcon
              link
              name={allExpanded ? 'compress' : 'expand'}
              color="grey"
              onClick={toggleGlobalExpanded}
            />
          </Grid.Column>
        </Grid.Row>
        {data.map((_, i) =>
          items[i] ? (
            <DecisionRow
              key={i}
              index={i}
              data={items[i]}
              dispatch={dispatch}
            />
          ) : null
        )}
      </Grid>

      {withFaq && <SearchBox onSelect={copyToInput} />}
      <Button
        content={checkedNos ? `Submit (${checkedNos})` : 'Ignore All'}
        color="green"
        floated="right"
        onClick={handleContinue}
      />
    </Container>
  );
};

export default DecisionBox;
