import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Popup } from 'semantic-ui-react';

import { formatTime, colors } from 'utils';
import AttributeLabels from './AttributeLabels';

const MessageContainer = styled.div`
  display: flex;
  & > img {
    margin-top: 0.5rem;
    margin-right: 0.1rem;
  }
  ${props =>
    props.from !== 'customer' &&
    css`
      justify-content: flex-end;
    `}
`;

const userCss = {
  operator: css`
    border-bottom-right-radius: 0;
    color: #fff;
    background-color: ${colors.green};
  `,
  customer: css`
    border-bottom-left-radius: 0;
    color: #000;
  `,
  system: css`
    border-bottom-right-radius: 0;
    color: #fff;
    background-color: ${colors.blue};
  `
};

const BodyContainer = styled.div`
  min-width: 75px;
  clear: both;
  margin: 0.2rem 0;
  text-align: ${props => props.from !== 'customer' && 'right'};
  padding: 0.6rem 0.6rem 0.5rem 0.6rem;
  border-radius: 1rem;
  background: #ecf3f9;
  white-space: pre-wrap;
  ${props => userCss[props.from] || userCss.operator}
`;

const Meta = styled.div`
  margin-top: 0.2rem;
  font-size: 0.7rem;
  text-align: right;
  color: ${props => (props.from !== 'customer' ? '#fff' : '#9e9e9e')};
`;

const RichBody = ({ body }) => {
  const __html = body
    .replace(/_(.*?)_/g, '<i>$1</i>')
    .replace(/\*(.*?)\*/g, '<b>$1</b>')
    .replace(/~(.*?)~/g, '<s>$1</s>')
    .trim();

  return <span dangerouslySetInnerHTML={{ __html }} />;
};

const Message = ({ id, body, timestamp, from, attributes, copyToInput }) => {
  const recoMarker = '*Recommended Message:*';
  const split = body.split(recoMarker);
  const isReco = split.length > 1;

  return (
    <MessageContainer from={from} key={id}>
      <BodyContainer from={from}>
        <Popup
          on="click"
          hoverable
          flowing
          hideOnScroll
          position="right center"
          size="small"
          trigger={
            <Button
              basic
              size="tiny"
              floated="right"
              icon="ellipsis vertical"
            />
          }
        >
          {isReco && (
            <Button
              icon="copy"
              size="tiny"
              content="Copy to Input"
              labelPosition="left"
              onClick={() => copyToInput(split[1].trim())}
            />
          )}
          {from === 'customer' && <AttributeLabels data={attributes} />}
        </Popup>
        <RichBody body={body} />
        <Meta from={from}>{formatTime(timestamp)}</Meta>
      </BodyContainer>
    </MessageContainer>
  );
};

export default Message;
