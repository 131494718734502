import React from 'react';
import styled from 'styled-components';

import { Icon, Loader } from 'semantic-ui-react';
import { colors } from 'utils';

const JobLabelBase = styled.div`
  background-color: ${props => colors[props.color]};
  color: #fff;
  font-size: 0.86rem;
  font-weight: bold;
  padding: 0.23rem 0.6rem;
  border-radius: 0.28571429rem;
`;

const StyledLoader = styled(Loader)`
  margin-top: -2.5px !important;
  margin-right: 3.7px !important;
`;

const colorMap = {
  default: 'blue',
  unread: 'red',
  active: 'green'
};

const JobLabel = ({ jobId, loading, unread, active }) => (
  <JobLabelBase
    color={colorMap[unread ? 'unread' : active ? 'active' : 'default']}
  >
    {loading ? (
      <StyledLoader active inverted size="mini" inline />
    ) : (
      <Icon inverted name="suitcase" />
    )}
    {jobId}
  </JobLabelBase>
);

export default JobLabel;
