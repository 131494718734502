import React from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';

const CloseModal = ({
  jobId,
  open,
  handleCancel,
  handleContinue,
  action = 'close'
}) => (
  <Modal open={open} basic size="small">
    <Header
      icon="warning sign"
      content={`Are you sure you want to ${action} ${jobId}?`}
    />
    <Modal.Actions>
      <Button inverted color="green" onClick={handleContinue}>
        <Icon name="check" /> Continue
      </Button>
      <Button inverted color="red" onClick={handleCancel}>
        <Icon name="remove" /> Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

export default CloseModal;
