import styled from 'styled-components';
import { space, color, typography } from 'styled-system';

export const DefaultBase = styled.div`
  background-color: #f4f6f8;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const Text = styled.div`
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);

  ${space}
  ${color}
  ${typography}
`;
