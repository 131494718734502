import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { autoRehydrate } from 'redux-persist';
import { bindWithDispatch } from 'feathers-redux';

import rootReducer, { rawServices } from 'store/reducers';

const middleware = applyMiddleware(
  promise(),
  thunk,
  createLogger({ collapsed: true })
);

const store = createStore(
  rootReducer,
  compose(
    middleware,
    autoRehydrate(),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

export const services = bindWithDispatch(store.dispatch, rawServices);

export default store;
