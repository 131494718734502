import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { useImmer } from 'use-immer';
import { useHistory } from 'react-router-dom';

import { Button, Divider, Input, Menu, Loader } from 'semantic-ui-react';
import { Popup } from 'components/v2';
import TaskGroup from './TaskGroup';

import * as H from './helpers';

const wds = H.wds;

const Base = styled.div`
  width: 40%;
  height: 100%;
`;

const TaskGroupList = styled.div`
  height: calc(100% - 66px);
  overflow-y: scroll;
`;

const HeaderRow = styled.div`
  display: flex;
  padding: 1rem 1.5rem 0rem;
  justify-content: space-evenly;
`;

const Flex = styled.div`
  flex: ${props => props.flex};
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  margin-left: ${props => (props.centered ? '9px' : 'none')};
`;

const ControlsBase = styled.div`
  margin: 1rem 0.5rem;
  display: flex;
  height: 36px;
`;

const StyledButton = styled(Button)`
  &.ui.basic.button {
    background-color: #fff !important;
  }
  margin-left: 0.5rem !important;
`;

const StyledInput = styled(Input)`
  margin: 0 0.5rem !important;
  width: 100%;
`;

const StyledLoader = styled(Loader)`
  margin-top: 3rem !important;
`;

const TaskPane = ({ tasks = [], jobs = [], loading, onTaskUpdate }) => {
  const [state, updateState] = useImmer({
    filter: 'pending',
    sort: 'createdAt',
    search: '',
    globalExpanded: 'collapsed',
    localExpanded: {},
    active: null
  });

  const history = useHistory();

  const update = (field, value) => {
    updateState(draft => {
      draft[field] = value;
    });
  };

  const toggleGlobalExpanded = () => {
    updateState(draft => {
      draft.globalExpanded =
        draft.globalExpanded === 'expanded' ? 'collapsed' : 'expanded';
      draft.localExpanded = {};
    });
  };

  const toggleLocalExpanded = jobId => {
    updateState(draft => {
      const current = draft.localExpanded[jobId] || draft.globalExpanded;
      draft.localExpanded[jobId] =
        current === 'expanded' ? 'collapsed' : 'expanded';
    });
  };

  const onTaskGroupClick = jobId => {
    update('active', jobId);
    history.push(`/app/tasks/#${jobId}`);
    updateState(draft => {
      draft.localExpanded[jobId] = 'expanded';
    });
  };

  const processed = R.pipe(
    H.getFilter(state.filter),
    H.getSearch(state.search),
    H.getGrouping(),
    H.withJobs(jobs),
    H.getSort(state.sort)
  )(tasks);

  return (
    <Base>
      <ControlsBase>
        <Popup
          trigger={<StyledButton basic icon="filter" />}
          content={
            <Menu secondary vertical size="small">
              {['pending', 'escalated', 'done', 'ignored', 'all'].map(f => (
                <Menu.Item
                  key={f}
                  name={f}
                  active={state.filter === f}
                  onClick={() => update('filter', f)}
                />
              ))}
            </Menu>
          }
        />
        <Popup
          trigger={<StyledButton basic icon="sort amount down" />}
          content={
            <Menu secondary vertical size="small">
              {['createdAt', 'fulfilmentDate'].map(f => (
                <Menu.Item
                  key={f}
                  name={f}
                  active={state.sort === f}
                  onClick={() => update('sort', f)}
                />
              ))}
            </Menu>
          }
        />
        <StyledButton
          basic
          icon={state.globalExpanded === 'expanded' ? 'compress' : 'expand'}
          onClick={() => toggleGlobalExpanded()}
        />
        <StyledInput
          fluid
          placeholder="Search"
          icon="search"
          iconPosition="left"
          value={state.search}
          onChange={(_, { value }) => update('search', value)}
        />
      </ControlsBase>

      <Divider fitted />
      <TaskGroupList>
        <HeaderRow>
          <Flex flex={wds[0]}>
            <Title>Job ID</Title>
          </Flex>
          <Flex flex={wds[1]}>
            <Title>Status</Title>
          </Flex>
          <Flex flex={wds[2]}>
            <Title>Fulfilment</Title>
          </Flex>
          <Flex flex={wds[3]}>
            <Title>Tasks</Title>
          </Flex>
          <Flex flex={wds[4]}>
            <Title></Title>
          </Flex>
        </HeaderRow>

        {loading ? (
          <StyledLoader active inline="centered" content="Fetching tasks..." />
        ) : (
          processed.map(x => (
            <TaskGroup
              key={x.jobId}
              active={state.active === x.jobId}
              {...x}
              setActive={() => onTaskGroupClick(x.jobId)}
              toggleLocalExpanded={() => toggleLocalExpanded(x.jobId)}
              onTaskUpdate={onTaskUpdate(x.jobId)}
              expanded={
                (state.localExpanded[x.jobId] || state.globalExpanded) ===
                'expanded'
              }
            />
          ))
        )}
      </TaskGroupList>
    </Base>
  );
};

export default TaskPane;
