import React from 'react';
import styled from 'styled-components';
import { Dimmer, Loader } from 'semantic-ui-react';

import { Card, Chat, JobForm } from 'components/v2';
import { ActionCenter } from './index';
import { MENU_HEIGHT } from 'utils/constants';

const Base = styled.div`
  display: ${props => (props.active ? 'flex' : 'none')};
  height: calc(100vh - ${MENU_HEIGHT});
  width: 100vw;
  filter: grayscale(${props => (props.isTestJob ? '100%' : '0%')});
`;

const JobTab = ({
  active,
  messages = [],
  body,
  setBody,
  copyToInput,
  onSend,
  inputRef,
  job,
  onUpdate,
  handleTriggerTree,
  handleContextUpdate,
  updating,
  operatorType,
  handleOsl,
  flags,
  activeFlag,
  handleSetActiveFlag,
  handleDeleteFlag
}) => {
  return (
    <Base active={active} isTestJob={job.isTestJob}>
      <Dimmer active={updating} inverted>
        <Loader inverted>Update in progress...</Loader>
      </Dimmer>
      <Card width="28%" height="100%">
        <Chat
          messages={messages}
          body={body}
          setBody={setBody}
          onSend={onSend}
          inputRef={inputRef}
          flags={flags}
          handleSetActiveFlag={handleSetActiveFlag}
        />
      </Card>
      <Card width="44%" height="100%" basic>
        <ActionCenter
          job={job}
          onSend={onSend}
          onUpdate={onUpdate}
          handleTriggerTree={handleTriggerTree}
          handleContextUpdate={handleContextUpdate}
          operatorType={operatorType}
          handleOsl={handleOsl}
          copyToInput={copyToInput}
          activeFlag={activeFlag}
          handleDeleteFlag={handleDeleteFlag}
        />
      </Card>
      <Card width="28%" height="100%" secondary>
        <JobForm
          job={job}
          onUpdate={onUpdate}
          copyToInput={copyToInput}
          globalExpanded="expanded"
        />
      </Card>
    </Base>
  );
};

export default JobTab;
