import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Modal, Icon, Input, Header } from 'semantic-ui-react';

import faqs from 'utils/faqs';

const capitalize = str =>
  str
    .split('_')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

const RowBase = styled.div`
  max-width: 100%;
  padding: 1rem;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
`;

const StyledModalHeader = styled(Modal.Header)`
  border-bottom: none !important;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05) !important;
  position: relative !important;
`;

const Row = ({ label, message, onClick }) => (
  <RowBase onClick={() => onClick(message)} pointer>
    <Header size="small" content={capitalize(label)} subheader={message} />
  </RowBase>
);

const SearchBox = ({
  onSelect,
  list = faqs.list,
  search = faqs.search,
  basic
}) => {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const results = query ? search(query) : list;

  const onClick = message => {
    setOpen(false);
    onSelect(message);
  };

  useEffect(() => {
    open && inputRef.current.focus();
  }, [open]);

  return (
    <Modal
      open={open}
      trigger={
        basic ? (
          <Icon name="search" link onClick={() => setOpen(true)} />
        ) : (
          <Button onClick={() => setOpen(true)}>
            <Icon name="search" />
            Search FAQs
          </Button>
        )
      }
      onClose={() => setOpen(false)}
      size="small"
    >
      <StyledModalHeader>
        <Input
          transparent
          fluid
          size="large"
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          value={query}
          onChange={(_, { value }) => setQuery(value)}
          ref={inputRef}
        />
      </StyledModalHeader>
      <Modal.Content scrolling>
        <Modal.Description>
          {results.length ? (
            results.map(({ label, message }) => (
              <Row
                key={label}
                label={label}
                message={message}
                onClick={onClick}
              />
            ))
          ) : (
            <RowBase>
              <Header
                size="small"
                content="No Results Found 🙁"
                subheader="Please try a different search query"
              />
            </RowBase>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default SearchBox;
