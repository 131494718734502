import React from 'react';
import styled from 'styled-components';

import { Form, Icon } from 'semantic-ui-react';

const SectionBase = styled.div`
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  min-height: 44px;
  position: relative;
  margin-bottom: 1rem;
`;

const Right = styled.span`
  position: absolute;
  right: 1rem;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
`;

const Expanded = styled.div`
  padding: 1rem;
  border-top: 1px solid #dededf;
`;

const StyledIcon = styled(Icon)`
  margin-top: -2px !important;
  margin-right: 1rem !important;
`;

const FormSection = ({
  title,
  expanded,
  toggleLocalExpanded,
  children,
  icon
}) => {
  return (
    <SectionBase>
      <SectionHeader onClick={toggleLocalExpanded}>
        <StyledIcon name={icon} />
        <strong>{title}</strong>
        <Right>
          <Icon link name={expanded ? 'chevron down' : 'chevron right'} />
        </Right>
      </SectionHeader>

      {expanded && (
        <Expanded>
          <Form size="small">{children}</Form>
        </Expanded>
      )}
    </SectionBase>
  );
};

export default FormSection;
