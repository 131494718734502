import React from 'react';

import { Navbar, EmptyState, DefaultBase } from 'components/v2';

const FourOFour = () => {
  return (
    <DefaultBase>
      <Navbar />
      <EmptyState
        title="Uh Oh! 404"
        subtitle="Could not find the page you were looking for"
        img="404"
      />
    </DefaultBase>
  );
};

export default FourOFour;
