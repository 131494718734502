import React, { Component } from 'react';
import * as R from 'ramda';

import {
  Form,
  Segment,
  Accordion,
  Radio,
  Label,
  Message
} from 'semantic-ui-react';

import { formatters, compMap } from './utils';
import styles from './styles';

export default class JsonForm extends Component {
  state = {
    value: this.props.value || {},
    schema: this.props.schema || {},
    updatedKeys: [],
    globalExpanded: this.props.globalExpanded || 'collapsed',
    localExpanded: {}
  };

  globalExpandToggle = () => {
    const globalExpanded =
      this.state.globalExpanded === 'expanded' ? 'collapsed' : 'expanded';
    this.setState({ globalExpanded, localExpanded: {} });
  };

  getLocalExpanded = id => {
    return this.state.localExpanded[id] || this.state.globalExpanded;
  };

  localExpandToggle = id => {
    const currentLocal = this.getLocalExpanded(id);
    const newLocal = currentLocal === 'expanded' ? 'collapsed' : 'expanded';
    this.setState(prev => ({
      ...prev,
      localExpanded: {
        ...prev.localExpanded,
        [id]: newLocal
      }
    }));
  };

  // erroredKeys = [];
  // getErroredKeys = () => R.uniq(this.erroredKeys);

  UNSAFE_componentWillReceiveProps({ value, schema }) {
    this.setState({ value, schema });
  }

  onChange = (key, type) => (_, { value, required, multiple }) => {
    let patch;

    if (type === 'number') {
      patch = Number(value);
    } else if (type === 'boolean') {
      patch = !R.prop(key, this.state.value);
    } else {
      patch = value;
    }

    this.setState({
      value: {
        ...this.state.value,
        [key]: patch
      },
      updatedKeys: R.uniq([...this.state.updatedKeys, key])
    });
  };

  makeFields = schema => {
    return schema.map(c => {
      const comp = compMap[c.type];
      let props = {
        ...(comp ? comp.defaultProps : {}),
        ...c.props
      };

      let defaultValue;
      let errorFn = val => !val;

      if (R.path(['props', 'multiple'], c)) {
        defaultValue = [];
        errorFn = val => !val.length;
      } else {
        defaultValue = '';
      }

      if (R.isNil(props.value)) {
        const stateValue = this.state.value[c.key];
        props.value = R.isNil(stateValue) ? defaultValue : stateValue;
      }

      const error = props.required && errorFn(props.value);
      props.error = error;

      if (comp && comp.normalizer) props = comp.normalizer(props);

      const label = c.overwrittenLabel ? c.overwrittenLabel : c.key;

      switch (c.type) {
        case 'group':
          const firstChild = c.children[0];
          const widths = R.path(['props', 'width'], firstChild)
            ? null
            : 'equal';
          return (
            <Form.Group key={c.key} widths={widths}>
              {this.makeFields(c.children)}
            </Form.Group>
          );
        case 'segment':
          if (R.equals(R.prop('collapsible', props), false)) {
            return (
              <Segment className="section non-collapsible" key={c.key}>
                <Label className="section-label" color={props.color}>
                  {label}
                </Label>
                {this.makeFields(c.children)}
              </Segment>
            );
          } else {
            return (
              <Segment className="section" key={c.key}>
                <Accordion
                  key={c.key}
                  activeIndex={
                    this.getLocalExpanded(c.key) === 'expanded' ? 0 : -1
                  }
                  onTitleClick={() => this.localExpandToggle(c.key)}
                  panels={[
                    {
                      key: c.key,
                      title: {
                        content: (
                          <Label className="section-label" color={props.color}>
                            {label}
                          </Label>
                        )
                      },
                      content: {
                        content: this.makeFields(c.children)
                      }
                    }
                  ]}
                />
              </Segment>
            );
          }
        case 'accordion':
          return (
            <Accordion
              as={Form.Field}
              key={c.key}
              activeIndex={this.getLocalExpanded(c.key) === 'expanded' ? 0 : -1}
              onTitleClick={() => this.localExpandToggle(c.key)}
              panels={[
                {
                  key: c.key,
                  title: c.key,
                  content: {
                    content: this.makeFields(c.children)
                  }
                }
              ]}
              {...props}
            />
          );
        case 'readonly':
          const format = R.path(['props', 'format'], c);
          const content = props.content
            ? props.content
            : this.state.value[c.key];
          return (
            <Form.Field key={c.key} {...R.omit(['content'], props)}>
              <label>{label}</label>
              <Message
                {...props}
                className="readonly"
                content={format ? formatters[format](content) : content}
              />
            </Form.Field>
          );
        case 'boolean':
          return (
            <Form.Field key={c.key} {...props}>
              <label>{label}</label>
              <Radio
                slider
                checked={this.state.value[c.key]}
                onChange={this.onChange(c.key, 'boolean')}
              />
            </Form.Field>
          );
        default:
          return (
            <Form.Field
              key={c.key}
              control={comp.control}
              label={label}
              placeholder={c.placeholder}
              onChange={this.onChange(c.key, c.type)}
              {...props}
            />
          );
      }
    });
  };

  render() {
    return this.props.schema ? (
      <Form {...styles}>{this.makeFields(this.props.schema)}</Form>
    ) : (
      <p>No Schema Provided!</p>
    );
  }
}
