import React from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import styled from 'styled-components';

import { EmptyState } from 'components/v2';
import { Loader } from 'semantic-ui-react';
import { CHAT_INPUT_HEIGHT, TAB_HEIGHT } from 'utils/constants';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const heightPreAdjustment = `100vh - ${CHAT_INPUT_HEIGHT} - ${TAB_HEIGHT} - 1rem`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(${heightPreAdjustment} - ${props => props.adjustHeight});
`;

const StyledScrollableFeed = styled(ScrollableFeed)`
  height: 100% !important;
`;

const StyledLoader = styled(Loader)`
  margin-top: 3rem !important;
`;

export default class Chat extends React.Component {
  render() {
    const {
      loading,
      messages = [],
      onSend,
      body,
      setBody,
      setActive,
      inputRef,
      flags = [],
      handleSetActiveFlag,
      adjustHeight = '0px'
    } = this.props;
    return (
      <Base>
        <Body adjustHeight={adjustHeight}>
          <StyledScrollableFeed>
            {loading ? (
              <StyledLoader
                active
                inline="centered"
                content="Fetching messages..."
              />
            ) : messages.length ? (
              messages.map(msg => (
                <ChatMessage
                  key={msg._id}
                  {...msg}
                  flag={msg.flagged}
                  handleSetActiveFlag={() => handleSetActiveFlag(msg._id)}
                />
              ))
            ) : (
              <EmptyState
                imgSize="150px"
                height="100%"
                img="no_messages"
                title="No Messages"
                subtitle="No messages have been exchanged on this communication channel yet"
              />
            )}
          </StyledScrollableFeed>
        </Body>
        <ChatInput
          onSend={onSend}
          value={body}
          setValue={setBody}
          inputRef={inputRef}
          onClick={setActive}
        />
      </Base>
    );
  }
}
